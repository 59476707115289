import { AuctionEquity } from '@/utils/helpers/rest';
import { parseISO } from 'date-fns';

export interface BorrowIntent {
  equity: AuctionEquity;
  quantity?: number;
}

export interface BorrowerLocate {
  id: number;
  equity: AuctionEquity | null;
  quantity: number;
  approvedQuantity: number;
  createdAt: Date;
}

export function normalizeBorrowerLocate(l: BorrowerLocate): void {
  // input from server will have createdAt as a string, we convert it to Date
  const lWithStrDate: { createdAt: string | Date } = l;
  if (typeof lWithStrDate.createdAt === 'string') {
    l.createdAt = parseISO(lWithStrDate.createdAt);
  }
}

export function createBlankBorrowerLocate(): BorrowerLocate {
  return {
    id: 0,
    equity: null,
    quantity: 0,
    approvedQuantity: 0,
    createdAt: new Date(),
  };
}

export function createBorrowerLocate(equity: AuctionEquity, quantity: number): BorrowerLocate {
  return {
    id: 0,
    equity: equity,
    quantity: quantity,
    approvedQuantity: 0,
    createdAt: new Date(),
  };
}

export interface BorrowerLocatePayload {
  equityId: string;
  quantity: number;
}

export interface LocateIntent {
  equity: AuctionEquity;
  quantity?: number;
}

export function createLocateIntent(equity: AuctionEquity, quantity: number): LocateIntent {
  return {
    equity: equity,
    quantity: quantity,
  };
}
