<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Return Loan</span>
          <v-spacer />
          <span class="text--primary headline-2">{{ equityDescription }}</span>
        </v-card-title>

        <v-card-text :class="{ 'show-summary': showSummary }">
          <!-- Input fields -->
          <v-container v-if="!showSummary" class="form-input pl-12">
            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> counterparty</v-col>
              <v-col class="py-0">
                {{ loan.counterpartyDisplay }}
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> current rate</v-col>
              <v-col class="py-0">
                <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize">
                {{ $t('Open Quantity') }}
              </v-col>
              <v-col class="py-0"><pretty-number :value="loan.openQuantity" /></v-col>
            </v-row>

            <v-row v-if="hasPendingRecall" align="center" class="red--text pt-6">
              <v-col class="col-4 py-1 text-capitalize"> recalled quantity</v-col>
              <v-col class="py-0">
                <pretty-number :value="loan.recalledQuantity" />
              </v-col>
            </v-row>

            <v-row v-if="loan.pendingReturnQuantity > 0" align="center" class="orange--text">
              <v-col class="col-4 py-1 text-capitalize"> pending return</v-col>
              <v-col class="py-0">
                <pretty-number :value="loan.pendingReturnQuantity" />
              </v-col>
            </v-row>
            <v-row align="center" class="pt-6">
              <v-col class="col-4 py-1 text-capitalize"> return quantity</v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="quantity"
                  autofocus
                  :error-messages="errorMsgs['quantity']"
                  label="quantity"
                  :max="loan.openQuantityToReturn"
                  :min="0"
                  placeholder="0"
                  :step="100"
                  type="integer"
                  @blur="$v.quantity.$touch()"
                  @input="$v.quantity.$touch()"
                >
                  <template #append>
                    <v-btn class="set-max pa-0" outlined x-small @click="setQuantityToMax()">
                      Max
                    </v-btn>
                  </template>
                </numeric-input>
              </v-col>
            </v-row>
            <v-alert
              v-if="isAfterCutOffTime(loan) && loan.counterparty.cutOffTime"
              class="mt-8"
              outlined
              type="warning"
            >
              Return Cutoff Time: {{ formatAmPmTime(loan.counterparty.cutOffTime) }} EST. Any
              returns initiated after this time will require the lender to approve the return before
              they get officially recorded to the trade and/or settlement instructions sent.
            </v-alert>
          </v-container>

          <!-- Form summary -->
          <v-container
            v-if="showSummary"
            v-shortkey="['enter']"
            class="form-summary"
            @shortkey="submitForm()"
          >
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <div v-if="quantity === loan.openQuantity">
                  You are about to return all <b>{{ quantity }}</b> shares of this loan.
                </div>
                <div
                  v-else-if="
                    hasPendingRecall &&
                    loan.recalledQuantity > quantity + loan.pendingReturnQuantity
                  "
                >
                  You are about to return <b>{{ quantity }}</b> shares of this loan.
                  <br />
                  This will <b>not</b> fulfill the current recall.
                </div>
                <div v-else>
                  You are about to return <b>{{ quantity }}</b> of the
                  {{ loan.openQuantity }} shares in this loan.
                </div>
              </v-col>
            </v-row>

            <div class="py-8 pl-10">
              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Counterparty</v-col>
                <v-col class="py-0">
                  {{ loan.counterpartyDisplay }}
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Current Rate</v-col>
                <v-col class="py-0">
                  <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize">
                  {{ $t('Open Quantity') }}
                </v-col>
                <v-col class="py-0">
                  <pretty-number :value="loan.openQuantity" />
                </v-col>
              </v-row>

              <v-row v-if="hasPendingRecall" align="center" class="red--text">
                <v-col class="col-4 py-1 text-capitalize"> recalled quantity</v-col>
                <v-col class="py-0">
                  <pretty-number :value="loan.recalledQuantity" />
                </v-col>
              </v-row>
              <v-row v-if="loan.pendingReturnQuantity > 0" align="center" class="orange--text">
                <v-col class="col-4 py-1 text-capitalize"> pending return</v-col>
                <v-col class="py-0">
                  <pretty-number :value="loan.pendingReturnQuantity" />
                </v-col>
              </v-row>

              <v-row align="center" class="primary--text">
                <v-col class="col-4 py-1 text-capitalize"> to return</v-col>
                <v-col class="py-0">
                  <pretty-number :value="quantity" />
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError || showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError, success: showSuccess }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">Your return has been sent.</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div v-if="!showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">
              {{ backButtonText }}
            </v-btn>
            <v-btn color="primary" type="submit" @click="goToSummary()"> Return</v-btn>
          </div>
          <div v-if="showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn
              color="secondary"
              data-test="back-btn"
              :disabled="formStatus !== 'idle'"
              @click="goBack()"
            >
              {{ backButtonText }}
            </v-btn>

            <v-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('dialogs.confirmButton') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { PropType } from 'vue';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { validationMixin } from 'vuelidate';
import { integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import wait from '@/modules/common/services/wait';
import { ApiError } from '@/utils/errors';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { LenderOpenLoan } from '@/utils/api/lender';
import { formatAmPmTime } from '@/utils/helpers/time';
import { OpenLoan } from '@/utils/api/loans';

interface FormErrors {
  apiErrors: string[];
  quantity: string[];
}

@Component({
  props: {
    loan: Object as PropType<LenderOpenLoan | BorrowerOpenLoan | null>,
  },
  computed: {
    ...mapGetters(['isAfterCutOffTime']),
  },
  mixins: [validationMixin],
  validations: function (this: ReturnDialog) {
    return {
      quantity: {
        integer,
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.loan.openQuantity),
        maxValueWithPending: maxValue(this.loan.openQuantityToReturn),
      },
    };
  },
})
export default class ReturnDialog extends mixins(validationMixin) {
  // props
  protected readonly loan!: BorrowerOpenLoan;

  // store state
  protected isAfterCutOffTime!: (loan: OpenLoan) => boolean;

  protected quantity: number | null = null;
  protected showSummary = false;
  protected formStatus: DialogFormStatus = 'idle';
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected formatAmPmTime = formatAmPmTime;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      quantity: [],
    };

    // quantity errors
    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
      if (!this.$v.quantity.maxValue)
        errors.quantity.push('cannot return more than the open quantity');
      if (!this.$v.quantity.maxValueWithPending)
        errors.quantity.push('cannot return this amount due to a pending return');
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get equityDescription(): string {
    return `${this.loan.equity.name} / ${this.loan.equity.ticker} [${this.loan.equity.cusip}]`;
  }

  protected get hasPendingRecall(): boolean {
    return this.loan.recalledQuantity > 0;
  }

  /**
   * get the text to display in the back button
   */
  protected get backButtonText(): string {
    if (this.showSummary) {
      return this.$t('dialogs.goBackButton') as string;
    } else {
      return this.$t('dialogs.cancelButton') as string;
    }
  }

  protected created(): void {
    // put any recalled amount in by default, taking into account pending returns
    if (this.loan.recalledQuantity > 0) {
      this.quantity = this.loan.recalledQuantity - this.loan.pendingReturnQuantity;
    }
  }

  protected setQuantityToMax(): void {
    this.quantity = this.loan.openQuantityToReturn;
  }

  protected goToSummary(): void {
    // validate form, and go to summary if valid
    this.showSummary = this.validateForm();
  }

  protected goBack(): void {
    this.showSummary = false;
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }
    this.formStatus = 'submitting';

    try {
      await this.$api.openLoans.postReturn(this.loan, this.quantity as number);

      // success, alert user and then close form
      this.showSuccess = true;
      this.formStatus = 'closing';

      await wait(1200);
      this.closeDialog();
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [errorMessage];
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  min-height: 15rem;
  max-height: 20rem;
  transition:
    min-height 250ms linear,
    max-height 250ms linear;

  &.show-summary {
    min-height: 16rem;
    max-height: 22rem;
  }
}

.form-input {
  .v-btn.set-max {
    height: 1.5rem;
  }
}

// General Transitions and Animations
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
