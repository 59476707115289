<template>
  <div class="wrapper d-flex flex-column fill-height">
    <dashboard-panel no-collapse title="Term Loans">
      <loan-details-dialog
        v-if="detailLoan"
        ref="loan-details-dialog"
        :loan-id="detailLoan.id"
        @close-modal="detailLoan = null"
      />
      <open-loans-table
        data-test="term-loans-loans-table"
        :omit-headers="['lenderDisplay', 'borrowerDisplay', 'displayId', 'dtccRefId']"
        :page.sync="page"
        :page-size.sync="pageSize"
        :query-data="queryData"
        :show-dropdown-actions="false"
        :show-select="false"
        :sort.sync="sort"
        @ready="onReady"
        @update:options="$emit('update:options', $event)"
        @view-loan="detailLoan = $event"
      />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/common/types/api';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { OpenLoan } from '@/utils/api/loans';
import { mapState } from 'vuex';
import { SocketEvents } from '@/store/store';
import { Watch } from 'vue-property-decorator';
import { LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';

function stubFn() {
  return;
}

@Component({
  components: {
    DashboardPanel,
    OpenLoansTable,
    LoanDetailsDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
  },
})
export default class TermLoansLoansView extends Vue {
  protected socketEvents!: SocketEvents;

  protected selectedContract: Api.TermLoans.ContractDetails | null = null;
  protected detailLoan: OpenLoan | null = null;
  protected page = 1;
  protected pageSize = 1000;
  protected sort: SortModelItem = { colId: 'createdAt', sort: 'desc' };
  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;

  @Watch('socketEvents.termLoans')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected onReady(config: { refresh: (config: { purge: boolean }) => void }): void {
    this.tableRefresh = config.refresh;
  }

  protected async queryData(): Promise<LoadSuccessParams | undefined> {
    try {
      const res = await this.$api.openLoans.fetchOpenLoans({
        filters: {
          cusip: this.$route.params.cusip,
          termContractDisplayId: this.$route.params.termContractDisplayId,
        },
        pagination: {
          page: this.page,
          limit: this.pageSize,
        },
        sort: `${this.sort.sort === 'desc' ? '-' : ''}${this.sort.colId}`,
      });
      if (!res) return;

      return { rowData: res.items, rowCount: res.items.length };
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected async handleContractClick(contract: Api.TermLoans.ContractDetails): Promise<void> {
    this.selectedContract = await this.$api.termLoans.fetchDetails(contract.displayId);
  }

  protected handleAggClick(params: { contractDisplayId: string; cusip: string }): void {
    void this.$router.push({ name: 'loans.open', params });
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}
</style>
