<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (filters, tabs) -->
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="gap-1 d-flex align-center justify-end" cols="12">
          <!-- Filters -->
          <v-select
            class="companies"
            clearable
            :item-text="counterpartyItemText"
            item-value="companyId"
            :items="companies"
            placeholder="All companies"
            :value="marketplaceOrdersCompanyIdFilter"
            @change="
              marketplaceOrdersCompanyIdFilter = $event;
              fetchAdminOrders();
            "
          />
          <simple-equity-search
            v-model="selectedEquity"
            class="simple-equity-search"
            clearable
            label="Security"
          />
          <v-select v-model="selectedSide" class="side" clearable :items="sideItems" label="Side" />
          <v-select
            v-model="selectedRouting"
            class="side"
            clearable
            :items="routingItems"
            label="Active/Inactive"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-0"
                  data-test="show-all-orders"
                  :false-value="false"
                  hide-details
                  label="show archived"
                  :true-value="true"
                  :value="showAllMarketplaceOrders"
                  @change="onChangeFilter"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Orders that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-4" no-gutters>
        <!-- Tabs -->
        <v-tabs ref="tabs" v-model="selectedTabIndex" background-color="transparent">
          <v-tab v-for="tabName in tabNames" :key="tabName" :value="tabName">
            {{ tabName }} ({{ groupedOrders[tabName].length }})
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <marketplace-orders-table
      :actions="['view']"
      data-test="marketplace-orders-table"
      :items="formattedOrders"
      :show-select="false"
      @view-order="detailOrderRef = $event"
    />

    <marketplace-order-details-dialog
      v-if="detailOrderRef"
      :as-broker="true"
      :order-ref="detailOrderRef"
      @close-modal="detailOrderRef = null"
    />
  </v-card>
</template>

<script lang="ts">
import { Api, Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import MarketplaceOrderDetailsDialog from '@/modules/marketplace/components/MarketplaceOrderDetailsDialog.vue';
import MarketplaceOrdersTable from '@/modules/marketplace/components/MarketplaceOrdersTable.vue';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { LoginState, SocketEvents } from '@/store/store';
import { throttle } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

type TabName = 'all' | 'open' | 'partial' | 'filled' | 'closed';
type TabbedOrders = Record<TabName, Api.Marketplace.AdminOrdersResponse>;

const POLLING_INTERVAL = 1;

@Component({
  components: {
    SimpleEquitySearch,
    MarketplaceOrdersTable,
    MarketplaceOrderDetailsDialog,
  },
  computed: {
    ...mapState(['loginState', 'socketEvents']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class BrokerMarketplaceOrdersList extends Vue {
  // store state
  protected loginState!: LoginState;
  protected hasTraderUserRole!: boolean;
  protected socketEvents!: SocketEvents;

  // store methods
  protected updateMarketplaceShowAll!: (showAll: boolean) => void;
  protected refreshAdminOmsOrders!: () => Promise<void>;
  protected updateMarketplaceOrdersCompanyIdFilter!: (companyId: string | null) => void;

  protected marketplaceOrders: Api.Marketplace.AdminOrdersResponse = [];
  protected showAllMarketplaceOrders = false;
  protected marketplaceOrdersCompanyIdFilter: string | null = null;
  protected detailOrderRef: string | null = null;
  protected selectedEquity: Equity | null = null;
  protected sideItems = [
    { text: 'Lender', value: 'LENDER' },
    { text: 'Borrower', value: 'BORROWER' },
  ];
  protected selectedSide: 'LENDER' | 'BORROWER' | null = null;
  protected routingItems: Array<{ text: string; value: Api.Marketplace.Order['routingStatus'] }> = [
    { text: 'Active', value: 'ROUTED' },
    { text: 'Pending', value: 'PENDING' },
    { text: 'Inactive', value: 'UNROUTED' },
  ];
  protected selectedRouting: Api.Marketplace.Order['routingStatus'] | null = null;

  protected search = '';
  protected selectedTabIndex = 1; // open
  protected tabNames: TabName[] = ['all', 'open', 'partial', 'filled', 'closed'];
  protected pollInterval: ReturnType<typeof setInterval> | null = null;
  protected throttledFetchAdminOrders = throttle(this.fetchAdminOrders, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get groupedOrders(): TabbedOrders {
    let filteredOrders = this.marketplaceOrders;

    if (this.selectedEquity) {
      filteredOrders = filteredOrders.filter((i) => i.equity.id === this.selectedEquity?.id);
    }

    if (this.selectedSide) {
      filteredOrders = filteredOrders.filter((i) => i.side === this.selectedSide);
    }

    if (this.selectedRouting !== null) {
      filteredOrders = filteredOrders.filter((i) => i.routingStatus === this.selectedRouting);
    }

    const grouped = filteredOrders.reduce<TabbedOrders>(
      (acc, order) => {
        acc.all.push(order);
        if (order.status === 'OPEN') {
          acc.open.push(order);
        }
        if (order.status === 'OPEN' && order.filled > 0) {
          acc.partial.push(order);
        }
        if (order.status === 'FILLED') {
          acc.filled.push(order);
        }
        if (order.status === 'CANCELED' || order.status === 'EXPIRED') {
          acc.closed.push(order);
        }
        return acc;
      },
      {
        all: [],
        open: [],
        partial: [],
        filled: [],
        closed: [],
      }
    );

    return grouped;
  }

  protected get selectedTabName(): TabName {
    return this.tabNames[this.selectedTabIndex];
  }

  protected get formattedOrders(): Api.Marketplace.AdminOrdersResponse {
    return this.groupedOrders[this.selectedTabName];
  }

  protected get companies(): CompanyInfo[] {
    return this.formattedOrders
      .map((order) => order.company)
      .filter((counterparty): counterparty is CompanyInfo => counterparty !== null);
  }

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(): void {
    void this.throttledFetchAdminOrders();
  }

  protected async mounted(): Promise<void> {
    await this.fetchAdminOrders();
    // @TODO: replace with socket events once backend is implemented
    this.pollInterval = setInterval(() => this.fetchAdminOrders(), POLLING_INTERVAL * 1000);
  }

  protected async fetchAdminOrders(): Promise<void> {
    this.marketplaceOrders = await this.$api.marketplace.fetchAdminOrdersList({
      showAll: this.showAllMarketplaceOrders,
      companyId: this.marketplaceOrdersCompanyIdFilter,
    });
  }

  // vue-class-component implements destroyed, but not unmounted
  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  protected onChangeFilter(showAll: boolean): void {
    this.showAllMarketplaceOrders = showAll;
    void this.fetchAdminOrders();
  }

  protected counterpartyItemText(counterparty: CompanyInfo): string {
    return `${counterparty.companyName} (${counterparty.displayBoxId})`;
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.companies,
.simple-equity-search,
.side {
  max-width: 20rem;
}
</style>
