import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { alignLeft, alignRight, comparator, mergeClasses } from './utils';
import { Api } from '@/modules/common/types/api';
import { formatCompanyBoxId } from '@/modules/user-accounts/helpers/user-accounts';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import * as cols from './common';
import { RoutingStatus } from '@/modules/marketplace/types/marketplace';
export { side, ticker, cusip } from './common';

export type OrdersWithEqualFieldsMap = Map<
  Api.Marketplace.Order,
  { routingStatus: boolean; counterparties: boolean; minQuantity: boolean; rate: boolean }
>;

interface Config {
  ordersWithEqualFields: OrdersWithEqualFieldsMap;
}

export function status(config: Config): ColDef<Api.Marketplace.Order, RoutingStatus> {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Status',
    valueFormatter: (params) =>
      params.value === 'ROUTED' ? 'Active' : params.value === 'PENDING' ? 'Pending' : 'Inactive',
    ...mergeClasses([
      alignLeft(),
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.routingStatus ? 'error--text' : undefined,
      },
    ]),
  };
}

export function counterparty(config: Config): ColDef<Api.Marketplace.Order> {
  return {
    field: 'counterparties',
    colId: 'counterparties',
    headerName: 'Counterparty',
    valueFormatter: (params) =>
      params.value.length ? params.value.map(formatCompanyBoxId).join(', ') : 'Any',
    ...mergeClasses([
      alignLeft(),
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.counterparties ? 'error--text' : undefined,
      },
    ]),
  };
}

export function executed(): ColDef<Api.Marketplace.Order> {
  return {
    field: 'filled',
    colId: 'filled',
    headerName: 'Executed',
    valueFormatter: (params: ValueFormatterParams) =>
      params.value === 0 ? '–' : formatPrettyNumber(params.value),
    comparator: comparator.number,
    ...alignRight(),
  };
}

export function quantity(): ColDef<Api.Marketplace.Order> {
  return cols.quantity({
    field: 'quantity',
    headerName: 'Quantity',
  });
}

export function minQuantity(config: Config): ColDef<Api.Marketplace.Order> {
  return {
    field: 'minQuantity',
    colId: 'minQuantity',
    headerName: 'Min. Quantity',
    valueFormatter: (params: ValueFormatterParams) =>
      params.value === 0 || params.value === 1 ? '–' : formatPrettyNumber(params.value),
    ...mergeClasses([
      alignRight(),
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.minQuantity ? 'error--text' : undefined,
      },
    ]),
  } as ColDef<Api.Marketplace.Order>;
}

export function rate(config: Config): ColDef<Api.Marketplace.Order> {
  const colRate = cols.rate();
  return {
    ...colRate,
    ...mergeClasses([
      colRate,
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.rate ? 'error--text' : undefined,
      },
    ]),
  } as ColDef<Api.Marketplace.Order>;
}
