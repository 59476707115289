// this is a demo feature not intended to go to PROD
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { ColDef } from 'ag-grid-enterprise';
import { alignLeft, alignRight, component } from './utils';
import { rateAdapter } from '@/modules/common/components/ag-table/columns/common';
import TruncateText from '@/modules/common/components/TruncateText.vue';
import { h } from 'vue';
import { FinraTransaction, Venue } from '@/utils/api/finra-transactions';
import { VBtn, VIcon } from 'vuetify/lib';
export {
  checkbox,
  ticker,
  cusip,
  rateAdapter,
} from '@/modules/common/components/ag-table/columns/common';

const truncateTextAdapter = component<{ value: string; maxWidth?: string }>(
  (props) => () => h(TruncateText, { props })
);

export const transactAt = (): ColDef =>
  cols.timestamp({ field: 'transactAt', headerName: 'Transact Time' });

export const reportedAt = (): ColDef =>
  cols.timestamp({ field: 'reportedAt', headerName: 'Report Time' });

export const reportType = (): ColDef => ({
  field: 'reportType',
  colId: 'reportType',
  headerName: 'Report Type',
  ...alignLeft(),
});

export const displayId = (): ColDef => ({
  field: 'displayId',
  colId: 'displayId',
  headerName: 'Reporting ID',
  cellRendererSelector: (params) =>
    params.node?.rowPinned
      ? undefined
      : truncateTextAdapter({
          value: params.value,
          maxWidth: '8rem',
        }),
  ...alignLeft(),
});

export const issuer = (): ColDef => ({
  field: 'issuer',
  colId: 'issuer',
  headerName: 'Issuer',
  cellRendererSelector: (params) =>
    params.node?.rowPinned
      ? undefined
      : truncateTextAdapter({
          value: params.value,
          maxWidth: '10.25rem',
        }),
  ...alignLeft(),
});

export const lei = (): ColDef => ({
  field: 'lei',
  colId: 'lei',
  headerName: 'Lei',
  cellRendererSelector: (params) =>
    params.node?.rowPinned
      ? undefined
      : truncateTextAdapter({
          value: params.value,
          maxWidth: '8.5rem',
        }),
  ...alignLeft(),
});

export const quantity = (): ColDef => ({
  ...cols.quantity({
    field: 'quantity',
    headerName: 'Quantity',
  }),
});

export function rate(): ColDef {
  return {
    ...cols.rate(),
    headerName: 'Rate / Fee',
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? undefined
        : rateAdapter({
            rate: params.data.rate,
            rateModifier: params.data.rateModifier,
            precision: undefined,
          }),
  };
}

export const marketValue = (): ColDef => ({
  ...cols.price({
    field: 'marketValue',
    headerName: 'Market Value',
  }),
});

export const collateralType = (): ColDef => ({
  field: 'collateralType',
  colId: 'collateralType',
  headerName: 'Collateral Type',
  cellRendererSelector: (params) =>
    params.node?.rowPinned
      ? undefined
      : truncateTextAdapter({
          value: params.value,
          maxWidth: '11.50rem',
        }),
  ...alignLeft(),
});

export const collateralValue = (): ColDef => ({
  ...cols.price({
    field: 'collateralValue',
    headerName: 'Collateral Value',
  }),
});

export function collateralPct(): ColDef {
  return {
    ...cols.rate(),
    headerName: '% Collateralized',
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? undefined
        : rateAdapter({
            rate: params.value,
            rateModifier: undefined,
            precision: undefined,
          }),
  };
}

export function venue(): ColDef {
  return {
    field: 'venue',
    colId: 'venue',
    headerName: 'Venue',
    ...alignLeft(),
  };
}

export const dueDate = (): ColDef => cols.date({ field: 'endsAt', headerName: 'Due Date' });

export function counterparty(): ColDef {
  return {
    field: 'counterpartyName',
    colId: 'counterpartyName',
    headerName: 'Counterparty',
    ...alignLeft(),
  };
}

export function counterpartyType(): ColDef {
  return {
    field: 'counterpartyType',
    colId: 'counterpartyType',
    headerName: 'Counterparty Type',
    ...alignLeft(),
  };
}

export function ftd(): ColDef {
  return {
    field: 'ftd',
    colId: 'ftd',
    headerName: 'FTD',
    ...alignLeft(),
  };
}

// just the icons, no click events, demo purposes only
const actionsAdapter = component<{
  item: FinraTransaction;
}>(
  (props) => () =>
    h('div', { class: 'actions d-flex justify-end', style: 'gap: 0.5rem' }, [
      props.item.venue === Venue.FinraVenueProvable &&
        h(
          VBtn,
          {
            class: 'icon-action',
            attrs: {
              'data-test': 'view-loan',
              icon: true,
              'x-small': true,
            },
          },
          [h(VIcon, { attrs: { size: '24' } }, 'mdi-eye')]
        ),
      h(
        VBtn,
        {
          class: 'icon-action',
          attrs: {
            'data-test': 'edit-transaction',
            icon: true,
            'x-small': true,
          },
        },
        [h(VIcon, { attrs: { size: '24' } }, 'mdi-pencil')]
      ),
    ])
);

export function actions(): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? undefined
        : actionsAdapter({
            item: params.data as FinraTransaction,
          }),
    pinned: 'right',
    lockVisible: true,
    suppressColumnsToolPanel: true,
    ...alignRight(),
  };
}
