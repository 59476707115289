<template>
  <v-hover v-slot="{ hover }">
    <v-data-table
      v-columns-resizable
      class="elevation-0 pb-8"
      :class="{ 'border-only-on-hover': true, 'on-hover': hover }"
      dense
      disable-pagination
      :expanded="auctionsView"
      fixed-header
      :headers="tableColumns"
      :hide-default-footer="auctionsView.length === 0"
      :item-class="greyoutExecutedAuctions"
      :items="auctionsView"
      :items-per-page="10"
      :no-data-text="$tc(noOpenAuctions, auctionsView.length, { count: auctionsView.length })"
      :show-expand="false"
      :single-expand="false"
      sort-by="endsAt"
    >
      <template #[`header.join`]>
        <span>{{ label }}</span>
      </template>

      <template #[`item.join`]="{ item }">
        <template v-if="item.status === auctionStatus.Open">
          <template v-if="isOwn">
            <v-btn
              color="primary"
              :disabled="!hasTraderUserRole"
              raised
              x-small
              @click="addTicket(item)"
            >
              <v-icon left> mdi-plus</v-icon>
              Add
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              :disabled="!hasTraderUserRole"
              raised
              x-small
              @click="onJoinAuction(item)"
            >
              <v-icon left> mdi-plus</v-icon>
              Join
            </v-btn>
          </template>
        </template>
      </template>

      <template #[`item.equity.name`]="{ item }">
        <div class="is-equity-name">{{ item.equity.ticker }} [{{ item.equity.cusip }}]</div>
      </template>

      <template #[`item.leakedDirection`]="{ item }">
        {{ item.leakedDirection ? $t(item.leakedDirection) : $t('non-public') }}
      </template>

      <template #[`item.quantity`]="{ item }">
        <div v-if="item.leakedQuantity">
          <pretty-number :value="item.leakedQuantity" />
        </div>
        <div v-else>{{ $t('non-public') }}</div>
      </template>

      <template #[`item.rate`]="{ item }">
        <div v-if="item.leakedRate">
          <auctions-rate-output
            :fractional-len="leakedPriceMeta.fractionalLen"
            :rate="item.leakedRate"
          ></auctions-rate-output>
          <template v-if="item.leakedIsStackedOrder"
            ><i>({{ $t('stacked-order') }})</i></template
          >
        </div>
        <div v-else>
          <!-- eslint-disable-next-line vue/no-lone-template -->
          <template>{{ $t('non-public') }}</template>
          <br />
          <template v-if="item.leakedIsStackedOrder"
            ><i>({{ $t('stacked-order') }})</i></template
          >
        </div>
      </template>

      <template #[`item.participants`]="{ item }">
        <template v-if="item.isOwnCompany">
          <v-chip
            v-for="(label, i) in item.participantLabels"
            :key="i"
            class="ml-0 mr-1"
            color="list-label"
            outlined
            small
            style="max-width: 100px"
          >
            {{ label }}
          </v-chip>
        </template>
        <div v-else>
          <v-chip outlined small>{{ $t('non-public') }}</v-chip>
        </div>
      </template>

      <template #[`item.endsAt`]="{ item }">
        <pretty-date-time :when="item.endsAt"></pretty-date-time>
      </template>

      <!-- order row -->
      <template v-if="isOwn" #[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <template v-if="item.status === auctionStatus.Error">
            <v-alert dense text type="error">{{ $t('auction-is-status-err') }}</v-alert>
          </template>
          <template v-else-if="item.executedAt != null || item.status !== auctionStatus.Open">
            <div class="auction-is-closed">
              {{ $t('auction-is-closed') }}
              <pretty-date-time
                v-if="item.executedAt != null"
                :when="item.executedAt"
              ></pretty-date-time>
            </div>
          </template>
          <template v-else>
            <!-- manage my order -->
            <template v-if="item.companyOrderTickets.length > 0">
              <v-row no-gutters>
                <v-col class="pl-4" cols="8">
                  <v-row
                    v-for="(ticket, i) in item.companyOrderTickets"
                    :key="i"
                    class="my-0"
                    no-gutters
                  >
                    <v-col>
                      <!--
                      use :key to enforce repaint when order was changed
                      need different value (last-changed date?)
                      -->
                      <bespoke-order-ticket
                        :key="`${ticket.id}-${ticket.renderedAt.toTimeString()}`"
                        :auction="item"
                        :auction-id="item.id"
                        :equity="item.equity"
                        :is-first-ticket="i === 0"
                        :pubkey="item.pubkey"
                        :ticket="ticket"
                        @add-ticket="addTicket"
                        @delete-ticket="deleteTicket"
                        @edit-ticket="editTicket"
                        @execute-auction="executeAuction"
                      ></bespoke-order-ticket>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="
                    !hideAccelerate && clientConfig.demoMode && clientConfig.executeAuctionsEnabled
                  "
                  align="end"
                  class="py-1"
                  cols="4"
                >
                  <v-btn
                    color="error"
                    :disabled="!hasTraderUserRole"
                    outlined
                    x-small
                    @click="executeAuction(item.id)"
                    >Accelerate execution for demo purposes
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
        </td>
      </template>
    </v-data-table>
  </v-hover>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { PriceAlignment, getOpenAuctionRateAlignment } from '@/utils/helpers/price-alignment';
import i18n from '@/localisation/i18n';
import { BespokeAuction, OrderTicket } from '@/utils/helpers/rest';
import { mapGetters, mapState } from 'vuex';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import AuctionsRateOutput from '@/modules/common/components/format-rate/AuctionsRateOutput.vue';
import { AuctionStatus } from '@/utils/helpers/buffers';
import BespokeOrderTicket from '@/modules/orders/components/BespokeOrderTicket.vue';
import { AppState } from '@/store/store';
import { DataTableHeader } from 'vuetify';

const tableColumns: DataTableHeader[] = [
  {
    text: '',
    value: 'join',
    width: '100px',
    filterable: false,
    sortable: false,
    align: 'center',
  },
  {
    text: i18n.tc('security'),
    value: 'equity.name',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('direction'),
    value: 'leakedDirection',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('quantity'),
    value: 'quantity',
    class: 'text-no-wrap text-truncate',
    cellClass: 'is-numeric',
    align: 'end',
  },
  {
    text: i18n.tc('rate'),
    value: 'rate',
    class: 'text-no-wrap text-truncate',
    cellClass: 'is-numeric',
    align: 'end',
  },
  {
    text: i18n.tc('participants'),
    value: 'participants',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('auction-ends-at'),
    value: 'endsAt',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
];

@Component({
  components: {
    PrettyDateTime,
    AuctionsRateOutput,
    BespokeOrderTicket,
  },
  props: {
    auctions: Array,
    isOwn: Boolean,
    noOpenAuctions: String,
    label: String,
    hideAccelerate: Boolean,
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class AuctionsTable extends Vue {
  // Props
  protected readonly auctions!: BespokeAuction[];
  protected readonly isOwn!: boolean;
  protected readonly label!: string;
  protected readonly hideAccelerate!: boolean;
  protected readonly noOpenAuctions!: string;

  // Store state
  protected readonly clientConfig!: AppState['clientConfig'];
  protected readonly hasTraderUserRole!: boolean;

  protected tableColumns = tableColumns;
  protected auctionStatus = AuctionStatus;
  protected auctionsView: BespokeAuction[] = [];
  protected leakedPriceMeta!: PriceAlignment;

  @Watch('auctions')
  private onChangedAuctionList(): void {
    // get the print format by looking at all order- and crossing prices
    this.leakedPriceMeta = getOpenAuctionRateAlignment(this.auctions);

    // display auction list only after we know how to format prices
    this.auctionsView = this.auctions;
  }

  protected mounted(): void {
    if (!this.isOwn) {
      const cols = this.tableColumns.map((c) => c.value);
      this.tableColumns.splice(cols.indexOf('participants'), 1);
    }
    this.onChangedAuctionList();
  }

  protected greyoutExecutedAuctions(row: BespokeAuction): string {
    if (row.executedAt != null || row.status !== AuctionStatus.Open) {
      return 'inactive-row';
    }
    return 'active-row';
  }

  protected onJoinAuction(auction: BespokeAuction): void {
    this.$emit('joinAuction', auction);
  }

  //
  // Forward events to Auctions.vue
  //
  protected addTicket(auction: BespokeAuction): void {
    this.$emit('addTicket', auction);
  }

  protected editTicket(auction: BespokeAuction, ticket: OrderTicket): void {
    this.$emit('editTicket', auction, ticket);
  }

  protected deleteTicket(auction: BespokeAuction, ticket: OrderTicket): void {
    this.$emit('deleteTicket', auction, ticket);
  }

  protected executeAuction(auctionId: string): void {
    this.$emit('executeAuction', auctionId);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded.v-data-table__expanded__row {
    border-top: solid rgba(255, 255, 255, 0.7) 2px;
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded.v-data-table__expanded__content {
    border-width: 0px;
  }

  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    border-width: 0px;
    box-shadow: unset;
  }

  .v-data-table tr.inactive-row {
    color: #7a7a7a;

    .v-chip {
      color: #7a7a7a !important;
      border-color: #7a7a7a !important;
    }
  }

  .v-data-table > .v-data-table__wrapper tbody {
    tr:hover {
      background-color: unset !important;
    }
  }

  .v-chip .v-chip__content {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  div.auction-is-closed {
    color: #7a7a7a;
    text-align: center;
  }
}
</style>
