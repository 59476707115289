import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { Api } from '@/modules/common/types/api';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';

export class UserAccountsApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.userAccounts = singleton;
    } else {
      v.prototype.$api = {
        userAccounts: singleton,
      };
    }
  }

  /**
   * Find counterparties given a search term
   */
  public async searchCounterparties(
    query: string,
    side: string,
    sponsored = false, // when true, only companies sponsored by the current user
    bilateral = false,
    includeSponsored = false,
    limit = 100
  ): Promise<Api.UserAccounts.CounterpartySearchResponse> {
    const url = this.baseUrl + '/counterparty/search';
    const params = {
      query: query.trim(),
      side: side,
      limit: limit,
      sponsored,
      bilateral,
      includeSponsored,
    };
    const { data } = await this.axios.get<Api.UserAccounts.CounterpartySearchResponse>(url, {
      params,
    });

    return data;
  }

  public async fetchCompanyByDisplayBoxId(displayBoxId: string): Promise<CompanyInfo | null> {
    const { data } = await this.searchCounterparties(displayBoxId, 'ALL');
    return data.length === 1 ? data[0] : null;
  }
}
