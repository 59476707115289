<template>
  <v-data-table
    ref="tableRef"
    v-model="selectedItems"
    dense
    disable-pagination
    fixed-header
    :headers="tableHeaders"
    hide-default-footer
    :item-class="getClass"
    item-key="id"
    :items="openRecalls"
    must-sort
    :show-select="loan.side === 'LENDER'"
    sort-by="recallTime"
    width="100%"
    @input="$emit('selected-count', $event.length)"
  >
    <template #[`item.recallTime`]="{ item }">
      <span class="highlight">{{ formatDate(item.recallTime, 'MMM d h:mm a') }} </span>
    </template>

    <template #[`item.originalQuantity`]="{ item }">
      <pretty-number :value="item.originalQuantity" />
    </template>

    <template #[`item.returnedQuantity`]="{ item }">
      <pretty-number :value="item.returnedQuantity" />
    </template>

    <template #[`item.buyInQuantity`]="{ item }">
      <pretty-number :value="item.buyInQuantity" />
    </template>

    <template #[`item.openQuantity`]="{ item }">
      <pretty-number :value="item.openQuantity" />
    </template>

    <template #[`item.allowedBuyInExecutionDate`]="{ item }">
      <span class="highlight">{{ formatDate(item.allowedBuyInExecutionDate, 'MMM dd') }}</span>
    </template>

    <template #[`item.actions`]="{ item }">
      <confirm-dialog-wrapper
        v-if="!item.legacyRecall && item.status == 'approved'"
        :options="{
          message: 'You are about to cancel this recall.',
          title: 'Recall cancellation',
          rejectText: 'Keep recall',
          acceptText: 'Cancel recall',
        }"
        v-on="$listeners"
      >
        <template #default="{ confirm }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <aurora-btn
                class="icon-action"
                data-test="cancel-recall"
                icon
                :loading="cancellingItems[item.id]"
                timeframe="recallLoans"
                x-small
                v-bind="attrs"
                v-on="on"
                @click="confirm(() => handleCancelRecall(item.id))"
              >
                <v-icon size="24">mdi-close-circle</v-icon>
              </aurora-btn>
            </template>
            <button>Cancel</button>
          </v-tooltip>
        </template>
      </confirm-dialog-wrapper>
      <span v-else-if="!item.legacyRecall">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-progress-circular
              v-bind="attrs"
              color="grey darken-1"
              indeterminate
              size="20"
              width="2"
              v-on="on"
            />
          </template>
          <button>Pending</button>
        </v-tooltip>
      </span>
      <span v-else>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="grey darken-1" size="24" v-on="on">mdi-lock</v-icon>
          </template>
          <button>Locked</button>
        </v-tooltip>
      </span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import Component from 'vue-class-component';
import { formatDate } from '@/utils/helpers/dates';
import { CancelRecallResponse, LoanDetailsLoan, OpenRecall } from '@/utils/api/loans';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

const headers: DataTableHeader[] = [
  { text: 'When', value: 'recallTime' },
  { text: 'Open', value: 'openQuantity', align: 'end' },
  { text: 'Returned', value: 'returnedQuantity', align: 'end' },
  { text: 'Bought-in', value: 'buyInQuantity', align: 'end' },
  { text: 'Original', value: 'originalQuantity', align: 'end' },
  { text: 'Due Date', value: 'allowedBuyInExecutionDate', align: 'end' },
  { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
];

@Component({
  props: {
    loan: {
      type: Object as PropType<LoanDetailsLoan>,
      required: true,
    },
  },
})
export default class LoanRecalls extends Vue {
  protected readonly loan!: LoanDetailsLoan;

  protected selectedItems: OpenRecall[] = [];
  protected cancellingItems: Record<string, boolean> = {};
  protected failedRecallsIds: string[] = [];
  protected showError = false;
  protected success = true;
  protected formatDate = formatDate;
  protected get tableHeaders(): DataTableHeader[] {
    return headers.filter((h) => {
      if (h.value === 'actions') {
        return this.loan.availableActions.updateRecall;
      }

      return true;
    });
  }

  protected get openRecalls(): Array<OpenRecall & { isSelectable: boolean }> {
    return this.loan.openRecalls.map((r) => {
      const isSelectable: boolean = !r.legacyRecall && r.status == 'approved';
      return { ...r, isSelectable: isSelectable };
    });
  }

  public async batchCancelSelected(): Promise<void> {
    this.$emit('start-batch-cancel');
    const results = await Promise.all(this.selectedItems.map(({ id }) => this.cancelRecall(id)));
    this.selectedItems = [];
    this.failedRecallsIds = results.filter((res) => res.error).map((res) => res.id);
    this.$emit('end-batch-cancel', this.failedRecallsIds);

    if (this.failedRecallsIds.length) {
      this.$snackbar.error(
        `${this.failedRecallsIds.length} recall${
          this.failedRecallsIds.length > 1 ? `s` : ``
        } could not be cancelled.`
      );
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your recalls have been cancelled.`,
        timeout: 3000,
      });
    }
  }

  protected async handleCancelRecall(auroraRecallId: string): Promise<void> {
    const res = await this.cancelRecall(auroraRecallId);
    this.selectedItems = [];

    if (res.error) {
      this.$snackbar.error(res.error);
      this.failedRecallsIds.push(auroraRecallId);
    } else {
      this.$snackbar.show({
        color: 'primary',
        message: `Your recall has been cancelled.`,
        timeout: 3000,
      });
    }
  }

  protected async cancelRecall(auroraRecallId: string): Promise<CancelRecallResponse> {
    this.$set(this.cancellingItems, auroraRecallId, true);
    const res: CancelRecallResponse = { id: auroraRecallId };
    try {
      await this.$api.openLoans.cancelRecall(this.loan.displayId, auroraRecallId);
    } catch (e) {
      res.error = new ApiError(i18nServerMessage(e as Error)).message;
    }
    this.$set(this.cancellingItems, auroraRecallId, false);
    return res;
  }

  protected getClass(rowItem: OpenRecall): string | void {
    if (this.failedRecallsIds.includes(rowItem.id)) {
      return 'error--text';
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  cursor: pointer;
}
.actions {
  gap: 1rem;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

// specificy hack to apply error-text to override the highlight color
tr.error--text .highlight {
  color: #ff5252 !important;
}

.highlight,
.summary strong {
  color: white;
  font-weight: bold;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}
</style>
