<template>
  <span class="wrapper d-flex">
    <!-- Batch dialogs -->
    <batch-cancel-pending-dialog
      v-if="openDialog === 'cancelPending'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <mass-modify-loans-dialog
      v-if="openDialog === 'return'"
      action="return"
      :loans="items"
      @close-modal="openDialog = null"
      @success="$emit('success')"
    />

    <batch-cancel-return-dialog
      v-if="openDialog === 'cancelReturn'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <mass-modify-loans-dialog
      v-if="openDialog === 'recall'"
      action="recall"
      :loans="items"
      @close-modal="openDialog = null"
      @success="$emit('success')"
    />

    <batch-cancel-recall-dialog
      v-if="openDialog === 'updateRecall'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <batch-accept-renegotiate-dialog
      v-if="openDialog === 'acceptRenegotiate'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <batch-reject-renegotiate-dialog
      v-if="openDialog === 'rejectRenegotiate'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <batch-cancel-renegotiate-dialog
      v-if="openDialog === 'cancelRenegotiate'"
      :items="items"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <batch-renegotiate-dialog
      v-if="openDialog === 'renegotiate'"
      :items="items"
      :should-counter-offer="shouldCounterOffer"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
    />

    <!-- No actions overlap across the items -->
    <v-btn
      v-if="items.length && !areBatchActionsEnabled"
      data-test="no-actions-in-common"
      disabled
      small
    >
      No actions in common
    </v-btn>

    <!-- Buttons to trigger the dialogs -->
    <aurora-btn
      v-if="actionsInCommon.cancelPending"
      color="darken-2"
      data-test="batch-action-cancelPending"
      :disabled="!hasOpsUserRole"
      small
      timeframe="cancelLoans"
      @click="openDialog = 'cancelPending'"
    >
      Cancel pending loan
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.return"
      color="orange darken-2"
      data-test="batch-action-return"
      :disabled="!hasOpsUserRole"
      small
      timeframe="settleLoans"
      @click="openDialog = 'return'"
    >
      Return
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.cancelReturn"
      color="red darken-2"
      data-test="batch-action-cancelReturn"
      :disabled="!hasOpsUserRole"
      small
      timeframe="settleLoans"
      @click="openDialog = 'cancelReturn'"
    >
      Cancel returns
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.recall"
      color="orange darken-2"
      data-test="batch-action-recall"
      :disabled="!hasOpsUserRole"
      small
      timeframe="recallLoans"
      @click="openDialog = 'recall'"
    >
      Recall
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.updateRecall"
      color="orange darken-2"
      data-test="batch-action-updateRecall"
      :disabled="!hasOpsUserRole"
      small
      timeframe="recallLoans"
      @click="openDialog = 'updateRecall'"
    >
      Cancel recalls
    </aurora-btn>

    <v-btn
      v-if="actionsInCommon.cancelRenegotiate"
      data-test="batch-action-cancelRenegotiate"
      :disabled="!hasTraderUserRole"
      small
      @click="openDialog = 'cancelRenegotiate'"
    >
      Cancel rerates
    </v-btn>

    <aurora-btn
      v-if="actionsInCommon.acceptRenegotiate"
      color="green darken-2"
      data-test="batch-action-acceptRenegotiate"
      :disabled="!hasTraderUserRole"
      small
      timeframe="rateModLoans"
      @click="openDialog = 'acceptRenegotiate'"
    >
      Accept rerates
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.rejectRenegotiate"
      color="red darken-2"
      data-test="batch-action-rejectRenegotiate"
      :disabled="!hasTraderUserRole"
      small
      timeframe="rateModLoans"
      @click="openDialog = 'rejectRenegotiate'"
    >
      Reject rerates
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.rejectRenegotiate"
      color="red darken-2"
      data-test="batch-action-rejectRenegotiate"
      :disabled="!hasTraderUserRole"
      small
      timeframe="rateModLoans"
      @click="
        shouldCounterOffer = true;
        openDialog = 'renegotiate';
      "
    >
      Reject & counter rerates
    </aurora-btn>

    <aurora-btn
      v-if="actionsInCommon.renegotiateFixed || actionsInCommon.renegotiateFloating"
      color="purple"
      data-test="batch-action-renegotiate"
      :disabled="!hasTraderUserRole"
      small
      timeframe="rateModLoans"
      @click="
        shouldCounterOffer = false;
        openDialog = 'renegotiate';
      "
    >
      Rerate
    </aurora-btn>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import BatchRenegotiateDialog from '@/modules/sec-lending/components/BatchRenegotiateDialog.vue';
import BatchCancelRenegotiateDialog from '@/modules/sec-lending/components/BatchCancelRenegotiateDialog.vue';
import BatchCancelPendingDialog from '@/modules/sec-lending/components/BatchCancelPendingDialog.vue';
import BatchReturnDialog from '@/modules/borrower/components/BatchReturnDialog.vue';
import BatchCancelReturnDialog from '@/modules/borrower/components/BatchCancelReturnDialog.vue';
import BatchRecallDialog from '@/modules/lender/components/BatchRecallDialog.vue';
import BatchCancelRecallDialog from '@/modules/lender/components/BatchCancelRecallDialog.vue';
import BatchAcceptRenegotiateDialog from '@/modules/sec-lending/components/BatchAcceptRenegotiateDialog.vue';
import BatchRejectRenegotiateDialog from '@/modules/sec-lending/components/BatchRejectRenegotiateDialog.vue';
import MassModifyLoansDialog from '@/modules/open-loans/components/MassModifyLoansDialog.vue';

type ActionsInCommon = OpenLoanItem['availableActions'];
type DialogName =
  | Omit<keyof ActionsInCommon, 'renegotiateFixed' | 'renegotiateFloating'>
  | 'renegotiate';

@Component({
  components: {
    BatchRenegotiateDialog,
    BatchCancelRenegotiateDialog,
    BatchCancelPendingDialog,
    BatchReturnDialog,
    BatchCancelReturnDialog,
    BatchRecallDialog,
    BatchCancelRecallDialog,
    BatchAcceptRenegotiateDialog,
    BatchRejectRenegotiateDialog,
    MassModifyLoansDialog,
  },
  props: {
    items: Array as PropType<OpenLoanItem[]>,
  },
  computed: {
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class OpenLoansBatchActions extends Vue {
  // props
  protected readonly items!: OpenLoanItem[];

  // store state
  protected hasOpsUserRole!: boolean;
  protected hasTraderUserRole!: boolean;

  protected openDialog: DialogName | null = null;
  protected shouldCounterOffer = false;

  protected get areBatchActionsEnabled(): boolean {
    return Object.keys(this.actionsInCommon).length > 0;
  }

  protected get actionsInCommon(): ActionsInCommon {
    // First we count the total number of each available action
    const counter = {};
    const benchmarks = {};
    const sides = {};
    const settlementTypes = {};
    for (const loan of this.items) {
      benchmarks[loan.rateModifier] = true;

      for (const actionName in loan.availableActions) {
        counter[actionName] = counter[actionName] || 0;
        counter[actionName]++;
      }

      settlementTypes[loan.settlementType] = (settlementTypes[loan.settlementType] || 0) + 1;
      sides[loan.side] = (sides[loan.side] || 0) + 1;
    }

    // currently cannot mix settlement types for batch actions
    if (Object.keys(settlementTypes).length > 1) {
      return {};
    }

    const actions: ActionsInCommon = {};
    for (const actionName in counter) {
      // Set if batch available is common to all items
      if (counter[actionName] === this.items.length) {
        actions[actionName] = true;
      }
    }

    // cannot renegotiate lender- and borrow loans in one batch
    if (Object.keys(sides).length > 1) {
      delete actions.renegotiateFixed;
      delete actions.renegotiateFloating;
      delete actions.acceptRenegotiate;
      delete actions.rejectRenegotiate;
      delete actions.cancelRenegotiate;
    }

    // cannot renegotiate multiple benchmarks in one batch
    if (actions.renegotiateFloating && Object.keys(benchmarks).length > 1) {
      delete actions.renegotiateFloating;
    }

    return actions;
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  gap: 1rem;
}
</style>
