// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanhistoryconsts.proto (package aurora.core.consts.loanhistory, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.loanhistory.LoanHistoryEventType
 */
export enum LoanHistoryEventType {
  /**
   * @generated from enum value: UNSPECIFIED_LOAN_HISTORY_EVENT_TYPE = 0;
   */
  UNSPECIFIED_LOAN_HISTORY_EVENT_TYPE = 0,

  /**
   * Created
   *
   * @generated from enum value: CREATED = 1;
   */
  CREATED = 1,

  /**
   * Pending
   *
   * @generated from enum value: PENDING = 2;
   */
  PENDING = 2,

  /**
   * Cancel pending
   *
   * @generated from enum value: CANCEL_PENDING = 3;
   */
  CANCEL_PENDING = 3,

  /**
   * Deliver Order pending
   *
   * @generated from enum value: DO_PENDING = 4;
   */
  DO_PENDING = 4,

  /**
   * Made
   *
   * @generated from enum value: MADE = 5;
   */
  MADE = 5,

  /**
   * Dropped
   *
   * @generated from enum value: DROPPED = 6;
   */
  DROPPED = 6,

  /**
   * Rejected
   *
   * @generated from enum value: REJECTED = 7;
   */
  REJECTED = 7,

  /**
   * Cancel rejected
   *
   * @generated from enum value: CANCEL_REJECTED = 8;
   */
  CANCEL_REJECTED = 8,

  /**
   * Cancelled
   *
   * @generated from enum value: CANCELLED = 9;
   */
  CANCELLED = 9,

  /**
   * Recalled
   *
   * @generated from enum value: RECALLED = 10;
   */
  RECALLED = 10,

  /**
   * Recall rejected
   *
   * @generated from enum value: RECALL_REJECTED = 11;
   */
  RECALL_REJECTED = 11,

  /**
   * Recall cancelled
   *
   * @generated from enum value: RECALL_CANCELED = 12;
   */
  RECALL_CANCELED = 12,

  /**
   * Rolled
   *
   * @generated from enum value: ROLLED = 13;
   */
  ROLLED = 13,

  /**
   * Roll pending
   *
   * @generated from enum value: ROLL_PENDING = 14;
   */
  ROLL_PENDING = 14,

  /**
   * Roll price differential pending
   *
   * @generated from enum value: ROLL_PD_PENDING = 15;
   */
  ROLL_PD_PENDING = 15,

  /**
   * Roll price differential made
   *
   * @generated from enum value: ROLL_PD_MADE = 16;
   */
  ROLL_PD_MADE = 16,

  /**
   * Returned
   *
   * @generated from enum value: RETURNED = 17;
   */
  RETURNED = 17,

  /**
   * Return pending
   *
   * @generated from enum value: RETURN_PENDING = 18;
   */
  RETURN_PENDING = 18,

  /**
   * Partially returned
   *
   * @generated from enum value: PARTIALLY_RETURNED = 19;
   */
  PARTIALLY_RETURNED = 19,

  /**
   * Return cancelled
   *
   * @generated from enum value: RETURN_CANCELED = 20;
   */
  RETURN_CANCELED = 20,

  /**
   * Return failed
   *
   * @generated from enum value: RETURN_FAILED = 21;
   */
  RETURN_FAILED = 21,

  /**
   * Return rejected
   *
   * @generated from enum value: RETURN_REJECTED = 22;
   */
  RETURN_REJECTED = 22,

  /**
   * Terminated
   *
   * @generated from enum value: TERMINATED = 23;
   */
  TERMINATED = 23,

  /**
   * Buy-in
   *
   * @generated from enum value: BUY_IN = 24;
   */
  BUY_IN = 24,

  /**
   * Buy-in pending
   *
   * @generated from enum value: BUY_IN_PENDING = 25;
   */
  BUY_IN_PENDING = 25,

  /**
   * Buy-in cancelled
   *
   * @generated from enum value: BUY_IN_CANCELLED = 26;
   */
  BUY_IN_CANCELLED = 26,

  /**
   * Buy-in rejected
   *
   * @generated from enum value: BUY_IN_REJECTED = 27;
   */
  BUY_IN_REJECTED = 27,

  /**
   * Buy-in payment order made
   *
   * @generated from enum value: BUY_IN_PO_MADE = 28;
   */
  BUY_IN_PO_MADE = 28,

  /**
   * Buy-in payment order failed
   *
   * @generated from enum value: BUY_IN_PO_FAILED = 29;
   */
  BUY_IN_PO_FAILED = 29,

  /**
   * Renegotiated
   *
   * @generated from enum value: RENEGOTIATED = 30;
   */
  RENEGOTIATED = 30,

  /**
   * Renegotiation cancelled
   *
   * @generated from enum value: RENEGOTIATION_CANCELED = 31;
   */
  RENEGOTIATION_CANCELED = 31,

  /**
   * Renegotiation rejected
   *
   * @generated from enum value: RENEGOTIATION_REJECTED = 32;
   */
  RENEGOTIATION_REJECTED = 32,

  /**
   * Renegotiation expired
   *
   * @generated from enum value: RENEGOTIATION_EXPIRED = 33;
   */
  RENEGOTIATION_EXPIRED = 33,

  /**
   * Undergoing corporate action
   *
   * @generated from enum value: CORPORATE_ACTION = 34;
   */
  CORPORATE_ACTION = 34,

  /**
   * Marked to market
   *
   * @generated from enum value: MARKED_TO_MARKET = 35;
   */
  MARKED_TO_MARKET = 35,

  /**
   * Marked to market payment order failed
   *
   * @generated from enum value: MARK_TO_MARKET_PO_FAILED = 36;
   */
  MARK_TO_MARKET_PO_FAILED = 36,

  /**
   * Marked to market payment order made
   *
   * @generated from enum value: MARK_TO_MARKET_PO_MADE = 37;
   */
  MARK_TO_MARKET_PO_MADE = 37,

  /**
   * Daily interest amount
   *
   * @generated from enum value: DAILY_INTEREST_AMOUNT = 38;
   */
  DAILY_INTEREST_AMOUNT = 38,

  /**
   * Daily interest payment order failed
   *
   * @generated from enum value: DAILY_INTEREST_PO_FAILED = 39;
   */
  DAILY_INTEREST_PO_FAILED = 39,

  /**
   * Daily interest payment order made
   *
   * @generated from enum value: DAILY_INTEREST_PO_MADE = 40;
   */
  DAILY_INTEREST_PO_MADE = 40,

  /**
   * Floating rate recalculated
   *
   * @generated from enum value: FLOATING_RATE_RECALCULATED = 41;
   */
  FLOATING_RATE_RECALCULATED = 41,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanHistoryEventType)
proto3.util.setEnumType(LoanHistoryEventType, "aurora.core.consts.loanhistory.LoanHistoryEventType", [
  { no: 0, name: "UNSPECIFIED_LOAN_HISTORY_EVENT_TYPE" },
  { no: 1, name: "CREATED" },
  { no: 2, name: "PENDING" },
  { no: 3, name: "CANCEL_PENDING" },
  { no: 4, name: "DO_PENDING" },
  { no: 5, name: "MADE" },
  { no: 6, name: "DROPPED" },
  { no: 7, name: "REJECTED" },
  { no: 8, name: "CANCEL_REJECTED" },
  { no: 9, name: "CANCELLED" },
  { no: 10, name: "RECALLED" },
  { no: 11, name: "RECALL_REJECTED" },
  { no: 12, name: "RECALL_CANCELED" },
  { no: 13, name: "ROLLED" },
  { no: 14, name: "ROLL_PENDING" },
  { no: 15, name: "ROLL_PD_PENDING" },
  { no: 16, name: "ROLL_PD_MADE" },
  { no: 17, name: "RETURNED" },
  { no: 18, name: "RETURN_PENDING" },
  { no: 19, name: "PARTIALLY_RETURNED" },
  { no: 20, name: "RETURN_CANCELED" },
  { no: 21, name: "RETURN_FAILED" },
  { no: 22, name: "RETURN_REJECTED" },
  { no: 23, name: "TERMINATED" },
  { no: 24, name: "BUY_IN" },
  { no: 25, name: "BUY_IN_PENDING" },
  { no: 26, name: "BUY_IN_CANCELLED" },
  { no: 27, name: "BUY_IN_REJECTED" },
  { no: 28, name: "BUY_IN_PO_MADE" },
  { no: 29, name: "BUY_IN_PO_FAILED" },
  { no: 30, name: "RENEGOTIATED" },
  { no: 31, name: "RENEGOTIATION_CANCELED" },
  { no: 32, name: "RENEGOTIATION_REJECTED" },
  { no: 33, name: "RENEGOTIATION_EXPIRED" },
  { no: 34, name: "CORPORATE_ACTION" },
  { no: 35, name: "MARKED_TO_MARKET" },
  { no: 36, name: "MARK_TO_MARKET_PO_FAILED" },
  { no: 37, name: "MARK_TO_MARKET_PO_MADE" },
  { no: 38, name: "DAILY_INTEREST_AMOUNT" },
  { no: 39, name: "DAILY_INTEREST_PO_FAILED" },
  { no: 40, name: "DAILY_INTEREST_PO_MADE" },
  { no: 41, name: "FLOATING_RATE_RECALCULATED" },
]);

/**
 * @generated from enum aurora.core.consts.loanhistory.LoanHistoryEventInitiatorKind
 */
export enum LoanHistoryEventInitiatorKind {
  /**
   * @generated from enum value: UNSPECIFIED_EVENT_INITIATOR_KIND = 0;
   */
  UNSPECIFIED_EVENT_INITIATOR_KIND = 0,

  /**
   * Unknown
   *
   * @generated from enum value: UNKNOWN = 1;
   */
  UNKNOWN = 1,

  /**
   * Aurora system
   *
   * @generated from enum value: SYSTEM = 2;
   */
  SYSTEM = 2,

  /**
   * Bot user
   *
   * @generated from enum value: BOT = 3;
   */
  BOT = 3,

  /**
   * Trader user
   *
   * @generated from enum value: TRADER = 4;
   */
  TRADER = 4,

  /**
   * Counterparty
   *
   * @generated from enum value: CONTRA = 5;
   */
  CONTRA = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanHistoryEventInitiatorKind)
proto3.util.setEnumType(LoanHistoryEventInitiatorKind, "aurora.core.consts.loanhistory.LoanHistoryEventInitiatorKind", [
  { no: 0, name: "UNSPECIFIED_EVENT_INITIATOR_KIND" },
  { no: 1, name: "UNKNOWN" },
  { no: 2, name: "SYSTEM" },
  { no: 3, name: "BOT" },
  { no: 4, name: "TRADER" },
  { no: 5, name: "CONTRA" },
]);

/**
 * @generated from enum aurora.core.consts.loanhistory.LoanHistorySortableField
 */
export enum LoanHistorySortableField {
  /**
   * @generated from enum value: UNSPECIFIED_LOAN_HISTORY_SORTABLE_FIELD = 0;
   */
  UNSPECIFIED_LOAN_HISTORY_SORTABLE_FIELD = 0,

  /**
   * Event type
   *
   * @generated from enum value: EVENT_TYPE = 1;
   */
  EVENT_TYPE = 1,

  /**
   * Event timestamp
   *
   * @generated from enum value: EVENT_TIME = 2;
   */
  EVENT_TIME = 2,

  /**
   * Open quantity
   *
   * @generated from enum value: OPEN_QUANTITY = 3;
   */
  OPEN_QUANTITY = 3,

  /**
   * Counterparty
   *
   * @generated from enum value: COUNTERPARTY = 4;
   */
  COUNTERPARTY = 4,

  /**
   * Ticker
   *
   * @generated from enum value: INSTRUMENT_TICKER = 5;
   */
  INSTRUMENT_TICKER = 5,

  /**
   * Cusip
   *
   * @generated from enum value: INSTRUMENT_CUSIP = 6;
   */
  INSTRUMENT_CUSIP = 6,

  /**
   * Rate
   *
   * @generated from enum value: RATE = 7;
   */
  RATE = 7,

  /**
   * Daily interest amount
   *
   * @generated from enum value: RATE_AMOUNT = 8;
   */
  RATE_AMOUNT = 8,

  /**
   * Settlement amount
   *
   * @generated from enum value: SETTLEMENT_AMOUNT = 9;
   */
  SETTLEMENT_AMOUNT = 9,

  /**
   * Settlement type
   *
   * @generated from enum value: SETTLEMENT_TYPE = 10;
   */
  SETTLEMENT_TYPE = 10,

  /**
   * Settlement amount change
   *
   * @generated from enum value: SETTLEMENT_AMOUNT_CHANGE = 11;
   */
  SETTLEMENT_AMOUNT_CHANGE = 11,

  /**
   * Open quantity change
   *
   * @generated from enum value: OPEN_QUANTITY_CHANGE = 12;
   */
  OPEN_QUANTITY_CHANGE = 12,

  /**
   * Event initiator
   *
   * @generated from enum value: INITIATOR = 13;
   */
  INITIATOR = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanHistorySortableField)
proto3.util.setEnumType(LoanHistorySortableField, "aurora.core.consts.loanhistory.LoanHistorySortableField", [
  { no: 0, name: "UNSPECIFIED_LOAN_HISTORY_SORTABLE_FIELD" },
  { no: 1, name: "EVENT_TYPE" },
  { no: 2, name: "EVENT_TIME" },
  { no: 3, name: "OPEN_QUANTITY" },
  { no: 4, name: "COUNTERPARTY" },
  { no: 5, name: "INSTRUMENT_TICKER" },
  { no: 6, name: "INSTRUMENT_CUSIP" },
  { no: 7, name: "RATE" },
  { no: 8, name: "RATE_AMOUNT" },
  { no: 9, name: "SETTLEMENT_AMOUNT" },
  { no: 10, name: "SETTLEMENT_TYPE" },
  { no: 11, name: "SETTLEMENT_AMOUNT_CHANGE" },
  { no: 12, name: "OPEN_QUANTITY_CHANGE" },
  { no: 13, name: "INITIATOR" },
]);

