import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { Api } from '@/modules/common/types/api';

export class BorrowerLoansApiService extends ApiService {
  private featureUrl = '/borrower';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.borrowerLoans = singleton;
    } else {
      v.prototype.$api = {
        borrowerLoans: singleton,
      };
    }
  }

  /**
   * Batch return multiple loans by the borrower
   */
  public async batchReturnBorrowerLoan(
    requests: Api.BorrowerLoans.LoanReturnRequestItem[]
  ): Promise<Api.BorrowerLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/return';
    const { data } = await this.axios.post<Api.BorrowerLoans.BatchActionResponse>(url, requests);
    return data;
  }

  /**
   * Batch cancel-return multiple loans by the borrower
   */
  public async batchCancelReturnBorrowerLoan(
    displayIds: string[]
  ): Promise<Api.BorrowerLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/cancel-return';
    // HTTP delete should not have request bodies (enforced by axios types)
    const { data } = await this.axios.put<Api.BorrowerLoans.BatchActionResponse>(url, displayIds);
    return data;
  }

  public async batchCancelReturnProposals(
    proposalIds: string[]
  ): Promise<Api.BorrowerLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/cancel-return-proposals';
    const { data } = await this.axios.post<Api.BorrowerLoans.BatchActionResponse>(url, proposalIds);
    return data;
  }
}
