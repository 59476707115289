import { Api } from '@/modules/common/types/api';
import {
  OrderEventType,
  OrderStatus,
  OrderType,
  SettlementType,
  TimeInForceOptions,
} from '@/modules/marketplace/types/marketplace';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import Decimal from 'decimal.js';
import { get as getPropValue } from 'lodash';

interface StatusResponse {
  label: OrderStatus | 'partial';
  color: 'gray' | 'warning' | 'green';
}

export function getStatus(order: Api.Marketplace.Order): StatusResponse {
  const result: StatusResponse = {
    color: 'gray',
    label: order.status,
  };

  if (order.status === 'OPEN') {
    if (order.filled > 0) {
      result.color = 'warning';
      result.label = 'partial';
    } else {
      result.color = 'green';
    }
  }

  return result;
}

export function getEventTypeColor(event: OrderEventType): string {
  switch (event) {
    case 'CREATED':
      return 'green';
    case 'MODIFIED':
      return 'gray';
    case 'CANCELED':
      return 'gray';
    case 'EXPIRED':
      return 'gray';
    case 'EXECUTED':
      return 'orange';
    case 'CLOSED':
      return 'gray';
    case 'TERMINATED':
      return 'gray';
  }
}

// @TODO: should we use i18n for the helpers below?

export const commonInForceOptions: TimeInForceOptions = [
  { value: 'DAY', text: 'Day', abbr: 'Day' },
  { value: 'GOOD_TILL_CANCEL', text: 'Good till cancel', abbr: 'GTC' },
  { value: 'FILL_OR_KILL', text: 'Fill or kill', abbr: 'FOK' },
];

export const marketTimeInForceOptions: TimeInForceOptions = [
  { value: 'IMMEDIATE_OR_CANCEL', text: 'Immediate or cancel', abbr: 'IOC' },
  { value: 'FILL_OR_KILL', text: 'Fill or kill', abbr: 'FOK' },
];

export const timeInForceOptions = [...commonInForceOptions, ...marketTimeInForceOptions];

export function timeInForceLabel(timeInForceType: string): string {
  const item = timeInForceOptions.find((i) => i.value === timeInForceType);
  return item ? item.text : '–';
}

export function timeInForceAbbr(timeInForceType: string): string {
  const item = timeInForceOptions.find((i) => i.value === timeInForceType);
  return item ? item.abbr : '–';
}

export const orderTypeOptions: Array<{ value: OrderType; text: string }> = [
  { value: 'LIMIT', text: 'Limit' },
  { value: 'MARKET', text: 'Market' },
];

export function orderTypeLabel(orderType: string): string {
  const item = orderTypeOptions.find((i) => i.value === orderType);
  return item ? item.text : '–';
}

export function formatCounterparties(counterparties: CompanyInfo[] | null | undefined): string {
  if (!counterparties || counterparties.length === 0) {
    return '–';
  } else if (counterparties.length === 1) {
    return counterparties[0].companyName;
  } else {
    return '(Multi...)';
  }
}

export function customSortTopOfBook(
  items: Api.Marketplace.TopOfBookResponseItem[],
  indexes: [string],
  directions: [boolean]
): Api.Marketplace.TopOfBookResponseItem[] {
  // multi-sort is disabled, we only need to look at the first index and direction
  const colName = indexes[0];
  const isDesc = directions[0];

  return items.sort(
    (a: Api.Marketplace.TopOfBookResponseItem, b: Api.Marketplace.TopOfBookResponseItem) => {
      let aValue = getPropValue(a, colName);
      let bValue = getPropValue(b, colName);

      if (colName === 'lend.counterparties' || colName === 'borrow.counterparties') {
        // Consolidate the objects into strings
        aValue = formatCounterparties(aValue);
        bValue = formatCounterparties(bValue);
      }

      // Some columns are optional, so we also check for falsy values
      let beforeOrAfter = 0;
      if (aValue instanceof Decimal && bValue instanceof Decimal) {
        if (aValue.lessThan(bValue) || !aValue) {
          beforeOrAfter = -1;
        }
        if (aValue.greaterThan(bValue) || !bValue) {
          beforeOrAfter = 1;
        }
      } else {
        if (aValue < bValue || !aValue) {
          beforeOrAfter = -1;
        }
        if (aValue > bValue || !bValue) {
          beforeOrAfter = 1;
        }
      }

      // Found a difference, return it
      if (beforeOrAfter !== 0) {
        return beforeOrAfter * (isDesc ? -1 : 1);
      }

      // Previous sorting attempts tied (0), use equity.ticker as a tie-breaker
      return (
        (a.equity.ticker > b.equity.ticker ? 1 : a.equity.ticker < b.equity.ticker ? -1 : 0) *
        (isDesc ? -1 : 1)
      );
    }
  );
}

export const settlementTypeDisplayText: Record<SettlementType, string> = {
  NSCC: 'NSCC',
  BILATERAL: 'Bilateral',
  OCC: 'OCC',
};

export const settlementTypeColor: Record<SettlementType, string> = {
  NSCC: 'blue',
  BILATERAL: 'purple darken-2',
  OCC: 'deep-purple accent-1',
};
