<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <counterparty-search
      v-model="counterparty"
      clearable
      :error-messages="localErrorMsg || errorMessages"
      :exclude-company-id="excludeCompanyId"
      :label="label"
      :side="side"
      @change="onCounterpartyChange($event)"
      @input="onCounterpartyChange($event)"
    />
    <div class="table-container mt-4" :style="{ maxHeight }">
      <div>
        <v-data-table
          v-if="counterparties.length"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          hide-default-footer
          item-key="companyId"
          :items="counterparties"
          width="100%"
        >
          <template #item="{ item }">
            <tr>
              <td>
                {{ formatCompanyName(item) }}
              </td>
              <td class="text-right">
                <v-btn class="icon-action" icon @click="removeCounterparty(item)">
                  <v-icon size="24">mdi-close-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { PropType } from 'vue';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';

@Component({
  props: {
    counterparties: {
      type: Array as PropType<CompanyInfo[]>,
      required: true,
    },
    excludeCompanyId: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Add counterparty...',
    },
    // search through borrower traders, lender traders or both
    side: {
      type: String as PropType<Side>,
      default: 'ALL',
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    maxHeight: {
      type: String,
      required: false,
      default: '40vh',
    },
  },
  components: {
    CounterpartySearch,
  },
})
export default class MultipleCounterpartySelector extends Vue {
  protected readonly counterparties!: CompanyInfo[];
  protected readonly errorMessages!: string[];
  protected readonly label!: string;
  protected readonly side?: Side;
  protected maxHeight?: string;

  protected readonly excludeCompanyId?: string;

  protected counterparty: CompanyInfo | null = null;
  protected localErrorMsg: string | null = null;

  protected onCounterpartyChange(counterparty: CompanyInfo | null): void {
    this.localErrorMsg = null;

    if (counterparty === null) {
      // user cleared the search field
      return;
    }

    this.counterparty = counterparty;

    if (this.counterparties.map((i) => i.companyId).includes(counterparty?.companyId)) {
      this.localErrorMsg = 'counterparty already in list.';
      return;
    }

    this.$emit('update:counterparties', [...this.counterparties, counterparty]);
    // get model and validator ready for the next counterparty
    this.$nextTick(() => {
      this.counterparty = null;
    });
  }

  protected removeCounterparty(counterparty: CompanyInfo): void {
    this.$emit(
      'update:counterparties',
      this.counterparties.filter((e) => e.companyId !== counterparty.companyId)
    );
  }

  protected formatCompanyName(counterparty: CompanyInfo): string {
    return formatCompanyName(counterparty);
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}

::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
