<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <dashboard-panel no-collapse :title="$t('notifications')">
      <div class="flex d-flex flex-column">
        <v-data-table
          ref="tableRef"
          v-model="notificationSelected"
          class="elevation-1 d-flex flex flex-column"
          disable-pagination
          :expanded="notificationExpanded"
          fixed-header
          :headers="tableColumns"
          height="100%"
          hide-default-footer
          :items="notificationView"
          :items-per-page="-1"
          show-expand
          show-select
          single-expand
          sort-by="createdAt"
          sort-desc
          @click:row="expandRow"
          @item-expanded="($event) => expandRow($event.item)"
        >
          <template #top>
            <div class="d-flex flex-row">
              <v-toolbar flat>
                <v-toolbar-title>Your Notifications</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <template v-if="!notifications.length">
                  <v-alert
                    border="left"
                    class="mt-4"
                    color="info"
                    dense
                    icon="mdi-information"
                    text
                  >
                    {{ $t('no-notifications') }}
                  </v-alert>
                </template>

                <template v-if="notifications.length >= maxNotifications">
                  <v-alert
                    border="left"
                    class="mt-4"
                    color="gray"
                    dense
                    icon="mdi-information"
                    text
                  >
                    {{
                      $t('more-notifications', {
                        count: totalNotificationCount,
                        max: maxNotifications,
                      })
                    }}
                  </v-alert>
                </template>

                <template v-if="notificationSelected.length">
                  <v-btn color="error" small @click="removeNotifications">
                    <v-icon left>mdi-trash-can-outline</v-icon>
                    {{
                      $tc('deleteSelectedNotifications', notificationSelected.length, {
                        count: notificationSelected.length,
                      })
                    }}
                  </v-btn>
                  <v-btn color="info" small @click="markNotificationsRead">
                    <v-icon left>mdi-message</v-icon>
                    {{
                      $tc('readSelectedNotifications', notificationSelected.length, {
                        count: notificationSelected.length,
                      })
                    }}
                  </v-btn>
                </template>
              </v-toolbar>
            </div>
          </template>

          <template #[`item.subject`]="{ item }">
            <span :class="{ 'message-unread': !item.readAt }">{{ item.subject }}</span>
          </template>

          <template #[`item.createdAt`]="{ item }">
            <span :class="{ 'message-unread': !item.readAt }">
              <pretty-date-time short :when="item.createdAt"></pretty-date-time>
            </span>
          </template>

          <template #expanded-item="{ headers, item }">
            <td colspan="2"></td>
            <td :colspan="headers.length - 3">
              <v-container class="pl-0 pb-5" fluid>
                <v-row no-gutters>
                  <v-col>
                    <div
                      v-if="item.auctionEvent == null"
                      class="preformatted"
                      v-html="item.message"
                    />
                    <div v-else>
                      <div>
                        {{ $t('notify-auction-symbol') }} {{ item.equitySymbol }} ({{
                          item.equityName
                        }})
                      </div>
                      <div>{{ $t('notify-auction-isincode') }} {{ item.equityIsinCode }}</div>
                      <div v-if="item.auctionExecutedAt == null">
                        <p>
                          <span>{{ $t('notify-auction-started') }}&nbsp;</span>
                          <pretty-date-time
                            :short="false"
                            :when="item.createdAt"
                          ></pretty-date-time>
                          <span>&nbsp;{{ $t('notify-auction-close') }}&nbsp;</span>
                          <pretty-date-time
                            :short="false"
                            :when="item.auctionEndsAt"
                          ></pretty-date-time>
                        </p>
                      </div>
                      <div v-else>
                        <p>
                          <span>{{ $t('notify-auction-started') }}&nbsp;</span>
                          <pretty-date-time
                            :short="false"
                            :when="item.createdAt"
                          ></pretty-date-time>
                          <span>&nbsp;{{ $t('notify-auction-executed') }}&nbsp;</span>
                          <pretty-date-time
                            :short="false"
                            :when="item.auctionExecutedAt"
                          ></pretty-date-time>
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- IF : role === trader THEN jump to 'auctions' -->
                  <v-col v-if="item.auctionEvent != null && hasTraderUserRole">
                    <!-- not executed? jump to 'open-auctions' -->
                    <v-btn
                      v-if="item.auctionExecutedAt == null"
                      color="secondary"
                      to="/desktop/sec-lending"
                      width="250"
                    >
                      <v-icon left>mdi-calendar-star</v-icon>
                      {{ $t('join-auction') }}
                    </v-btn>
                    <!-- executed? jump to 'closed-auctions' -->
                    <v-btn v-else color="secondary" to="/desktop/sec-lending" width="250">
                      <v-icon left>mdi-calendar-clock</v-icon>
                      {{ $t('closed-auctions') }}
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn color="error" width="250" @click="onNotificationDelete(item)">
                      <v-icon left>mdi-trash-can-outline</v-icon>
                      {{ $tc('deleteSelectedNotifications', 1, { count: 1 }) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </td>
            <td colspan="1"></td>
          </template>
        </v-data-table>
      </div>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep, orderBy } from 'lodash';
import { UserNotification } from '@/utils/helpers/rest';
import { Role } from '@/utils/helpers/permissions';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import i18n from '@/localisation/i18n';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import { DataTableHeader } from 'vuetify';
import { AppState } from '@/store/store';

const tableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('notificationSubject'),
    value: 'subject',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
  {
    text: '',
    value: 'createdAt',
    filterable: false,
    align: 'end',
    sort: (a: Date, b: Date) => (a === b ? 0 : a > b ? 1 : -1),
  },
];

@Component({
  data: () => ({
    Role: Role,
  }),
  components: {
    PrettyDateTime,
    DashboardPanel,
  },
  methods: {
    ...mapActions(['fetchMyNotifications', 'markNotificationRead', 'deleteNotifications']),
  },
  computed: {
    ...mapState(['notifications', 'totalNotificationCount']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class Notifications extends Vue {
  protected readonly maxNotifications = 1000;

  protected fetchMyNotifications!: () => Promise<void>;
  protected markNotificationRead!: (ids: string[]) => Promise<void>;
  protected deleteNotifications!: (ids: string[]) => Promise<void>;
  protected hasTraderUserRole!: boolean;

  protected totalNotificationCount!: AppState['totalNotificationCount'];
  protected notifications!: AppState['notifications'];
  protected notificationView: UserNotification[] = [];
  protected notificationExpanded: UserNotification[] = [];
  protected notificationSelected: UserNotification[] = [];

  protected tableColumns = tableHeaders;

  // fetch new notifications payload when the count updates (which happens from socket events)
  @Watch('totalNotificationCount')
  private onTotalNotificationCountChange(_totalNotificationCount: number) {
    void this.fetchMyNotifications();
  }

  // keep the users selection if notifications are updated by the server
  @Watch('notifications')
  private changedNotifications(notifications: UserNotification[]) {
    this.notificationView = orderBy(cloneDeep(notifications), ['id'], ['desc']);
    this.notificationSelected = [];
    // if any of the notificationExpanded is not in notificationView then we reset notificationExpanded
    if (
      !this.notificationExpanded.every((row) => {
        return this.notificationView.some((rowInView) => rowInView.id == row.id);
      })
    ) {
      this.notificationSelected = [];
    }
  }

  protected async mounted(): Promise<void> {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.tableRef as any).multipleSelect = () => {
      /* */
    };

    try {
      await this.fetchMyNotifications();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected markNotificationsRead(): void {
    const ids: string[] = [];
    for (const n of this.notificationSelected) {
      if (!this.isAlreadyRead(n)) {
        ids.push(n.id);
      }
    }
    if (ids.length) {
      void this.markNotificationRead(ids);
    }
  }

  protected removeNotifications(): void {
    const ids: string[] = [];
    for (const n of this.notificationSelected) {
      ids.push(n.id);
    }
    if (ids.length) {
      // ignore server errors; user can't help it
      void this.deleteNotifications(ids);
    }
  }

  protected onNotificationDelete(n: UserNotification): void {
    // ignore server errors; user can't help it
    void this.deleteNotifications([n.id]);
  }

  protected expandRow(row: UserNotification): void {
    // single expand
    void this.markRead(row);
    this.notificationExpanded = [row];
  }

  private async markRead(n: UserNotification): Promise<void> {
    if (!this.isAlreadyRead(n)) {
      // ignore server errors; user can't help it
      await this.markNotificationRead([n.id]);
    }
  }

  private isAlreadyRead(n: UserNotification): boolean {
    return !!n.readAt;
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  table {
    thead th {
      text-transform: capitalize;
    }
  }

  .preformatted {
    // inline html generated from markdown by the backend
    table {
      width: 600px;
      margin: 20px 0;

      thead th:first-child {
        text-align: left;
      }

      tbody td:first-child {
        width: 250px;
      }
    }

    .hidden-feature {
      display: none;
    }
  }

  .message-unread {
    font-weight: bold;
  }
}
</style>
