<template>
  <div>
    <borrow-dialog v-if="showBorrowDialog" @close-modal="showBorrowDialog = false" />
    <dashboard-panel no-collapse :title="panelTitle">
      <snapshot-manager
        :drivers="['url', 'sessionStorage']"
        :get-state-mutators="getStateMutators"
        :snapshot="snapshot"
        storage-key="snapshot-open-loans-list"
      >
        <open-loans-list
          :counterparty.sync="counterparty"
          :detail-loan.sync="detailLoan"
          :detail-loan-initial-tab.sync="detailLoanInitialTab"
          :equity.sync="equity"
          :has-late-returns.sync="hasLateReturns"
          :is-recalled.sync="isRecalled"
          :is-renegotiating.sync="isRenegotiating"
          :omit-headers="[
            'lenderDisplay',
            'borrowerDisplay',
            'displayId',
            'dtccRefId',
            'independentAmount',
            'yesterdayIndependentAmount',
          ]"
          :page.sync="page"
          :page-size.sync="pageSize"
          :show-all.sync="showAll"
          :side.sync="side"
          :sort.sync="sort"
        >
          <template #header>
            <!-- Borrow button (not available as lender) -->
            <aurora-btn
              v-if="canBorrow && !isOrderbookEnabled"
              color="secondary"
              data-test="borrow-button"
              :disabled="!hasTraderUserRole"
              timeframe="createLoans"
              @click.stop="showBorrowDialog = true"
            >
              Borrow
            </aurora-btn>
          </template>
        </open-loans-list>
      </snapshot-manager>
      <loan-details-dialog
        v-if="detailLoan"
        :initial-tab="detailLoanInitialTab"
        :loan-id="detailLoan.id"
        @close-modal="detailLoan = null"
      />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansList from '@/modules/open-loans/components/OpenLoansList.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import BorrowDialog from '@/modules/borrower/components/BorrowDialog.vue';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/localisation/i18n';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { Equity } from '@/modules/common/types/api';
import { OpenLoan } from '@/utils/api/loans';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { SortModelItem } from 'ag-grid-enterprise';

const MAX_ITEMS_PER_PAGE = 1000;

@Component({
  provide() {
    return {
      gridStateKey: 'OpenLoans',
    };
  },
  components: {
    DashboardPanel,
    OpenLoansList,
    SnapshotManager,
    LoanDetailsDialog,
    BorrowDialog,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
  },
})
export default class OpenLoans extends Vue {
  protected isRecalled = false;
  protected isRenegotiating = false;
  protected hasLateReturns = false;
  protected counterparty: null | CompanyInfo = null;
  protected equity: Equity | null = null;
  protected side: 'LENDER' | 'BORROWER' | null = null;
  protected sort: SortModelItem = { colId: 'updatedAt', sort: 'desc' };
  protected page = 1;
  protected pageSize = MAX_ITEMS_PER_PAGE;
  protected showAll = false;
  protected detailLoan: OpenLoan | null = null;
  protected detailLoanInitialTab = 'history';

  // store state
  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canLend!: boolean;
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;

  protected showBorrowDialog = false;

  protected get panelTitle(): string {
    return i18n.t('sidebar.items.openLoans') as string;
  }

  protected get isOrderbookEnabled(): boolean {
    return this.uxConfig.orderbookEnabledOverride !== null
      ? this.uxConfig.orderbookEnabledOverride
      : this.clientConfig.orderbookEnabled;
  }

  protected get snapshot(): Record<string, string> {
    const snapshot: Record<string, string> = {
      showAll: this.showAll ? 'true' : 'false',
      isRecalled: this.isRecalled ? 'true' : 'false',
      isRenegotiating: this.isRenegotiating ? 'true' : 'false',
      hasLateReturns: this.hasLateReturns ? 'true' : 'false',
      sort: `${this.sort.sort === 'desc' ? '-' : ''}${this.sort.colId}`,
      page: this.page ? this.page.toString() : '1',
      pageSize: this.pageSize ? this.pageSize.toString() : MAX_ITEMS_PER_PAGE.toString(),
    };
    if (this.side) {
      snapshot.side = this.side;
    }
    if (this.counterparty) {
      snapshot.counterpartyDisplayBoxId = this.counterparty.displayBoxId as string;
    }
    if (this.equity) {
      snapshot.cusip = this.equity.cusip;
    }

    return snapshot;
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      isRecalled: (value) => {
        this.isRecalled = value === 'true';
      },
      isRenegotiating: (value) => {
        this.isRenegotiating = value === 'true';
      },
      hasLateReturns: (value) => {
        this.hasLateReturns = value === 'true';
      },
      side: (value) => {
        this.side = value === 'LENDER' || value === 'BORROWER' ? value : null;
      },
      showAll: (value) => {
        this.showAll = value === 'true';
      },
      counterpartyDisplayBoxId: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.counterparty = res;
          } else {
            throw new Error('Failed to fetch selected counterparty');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      cusip: async (value) => {
        try {
          const res = await this.$api.manualLoans.fetchEquityByCusip(value);
          if (res) {
            this.equity = res;
          } else {
            throw new Error('Failed to fetch selected equity');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      sort: (value) => {
        this.sort = {
          colId: value.replace(/^-/, ''),
          sort: value.startsWith('-') ? 'desc' : 'asc',
        };
      },
      page: (value) => {
        this.page = parseInt(value, 10);
      },
      pageSize: (value) => {
        this.pageSize = parseInt(value, 10);
      },
    };
  }
}
</script>

<style lang="scss"></style>
