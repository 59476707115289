// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/loans/loans.proto (package aurora.core.apiengine.loansv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { PbDate, PbInstrument, PbRateDetails } from "../../../../common/protos/types_pb.js";
import { Side } from "../../../../consts/commonconsts_pb.js";
import { LoanRecallStatus } from "../../../../consts/loanrecallconsts_pb.js";
import { SettlementType } from "../../../../consts/settlementconsts_pb.js";
import { LoanStatus, RoundingRule } from "../../../../consts/loanconsts_pb.js";
import { LoanBuyInStatus } from "../../../../consts/loanbuyinconsts_pb.js";
import { LoanHistoryEventInitiatorKind, LoanHistoryEventType, LoanHistorySortableField } from "../../../../consts/loanhistoryconsts_pb.js";
import { SortOrder } from "../../../../consts/queryconsts_pb.js";

/**
 * Cancel-Loan Request
 *
 * @generated from message aurora.core.apiengine.loansv1.CancelLoanRequest
 */
export class CancelLoanRequest extends Message<CancelLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from field: string instrument = 3;
   */
  instrument = "";

  /**
   * Current quantity of shares for validation purposes - optional
   *
   * @generated from field: optional int64 quantity = 4;
   */
  quantity?: bigint;

  constructor(data?: PartialMessage<CancelLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelLoanRequest {
    return new CancelLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelLoanRequest {
    return new CancelLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelLoanRequest {
    return new CancelLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelLoanRequest | PlainMessage<CancelLoanRequest> | undefined, b: CancelLoanRequest | PlainMessage<CancelLoanRequest> | undefined): boolean {
    return proto3.util.equals(CancelLoanRequest, a, b);
  }
}

/**
 * Cancel-Loan Response
 *
 * @generated from message aurora.core.apiengine.loansv1.CancelLoanResponse
 */
export class CancelLoanResponse extends Message<CancelLoanResponse> {
  /**
   * Id of this response
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * List with Loan Details
   *
   * @generated from field: aurora.core.apiengine.loansv1.LoanDetails loan_details = 2;
   */
  loanDetails?: LoanDetails;

  constructor(data?: PartialMessage<CancelLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_details", kind: "message", T: LoanDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelLoanResponse {
    return new CancelLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelLoanResponse {
    return new CancelLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelLoanResponse {
    return new CancelLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelLoanResponse | PlainMessage<CancelLoanResponse> | undefined, b: CancelLoanResponse | PlainMessage<CancelLoanResponse> | undefined): boolean {
    return proto3.util.equals(CancelLoanResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.RecallDetails
 */
export class RecallDetails extends Message<RecallDetails> {
  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 3;
   */
  instrument?: PbInstrument;

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 4;
   */
  loanDisplayId = "";

  /**
   * Current open quantity of the loan
   *
   * @generated from field: int64 loan_open_quantity = 5;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Side of loan (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 6;
   */
  side = Side.BORROWER;

  /**
   * Counterparty NSCC Account ID
   *
   * @generated from field: string counterparty_nscc_id = 7;
   */
  counterpartyNsccId = "";

  /**
   * Status of the recall
   *
   * @generated from field: aurora.core.consts.loanrecall.LoanRecallStatus recall_status = 10;
   */
  recallStatus = LoanRecallStatus.UNSPECIFIED;

  /**
   * Id of the recall assigned by Aurora
   *
   * @generated from field: string id = 11;
   */
  id = "";

  /**
   * Number of shares recalled
   *
   * @generated from field: int64 quantity = 12;
   */
  quantity = protoInt64.zero;

  /**
   * Next allowed buy-in execution date
   *
   * @generated from field: aurora.core.common.protos.PbDate allowed_execution_date = 13;
   */
  allowedExecutionDate?: PbDate;

  constructor(data?: PartialMessage<RecallDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.RecallDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "instrument", kind: "message", T: PbInstrument },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 7, name: "counterparty_nscc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "recall_status", kind: "enum", T: proto3.getEnumType(LoanRecallStatus) },
    { no: 11, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "allowed_execution_date", kind: "message", T: PbDate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecallDetails {
    return new RecallDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecallDetails {
    return new RecallDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecallDetails {
    return new RecallDetails().fromJsonString(jsonString, options);
  }

  static equals(a: RecallDetails | PlainMessage<RecallDetails> | undefined, b: RecallDetails | PlainMessage<RecallDetails> | undefined): boolean {
    return proto3.util.equals(RecallDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.RecallLoanRequest
 */
export class RecallLoanRequest extends Message<RecallLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from field: string instrument = 3;
   */
  instrument = "";

  /**
   * Current open quantity of the loan for validation purposes
   *
   * @generated from field: int64 loan_open_quantity = 4;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Number of shares to recall
   *
   * @generated from field: int64 recall_quantity = 10;
   */
  recallQuantity = protoInt64.zero;

  constructor(data?: PartialMessage<RecallLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.RecallLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "recall_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecallLoanRequest {
    return new RecallLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecallLoanRequest {
    return new RecallLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecallLoanRequest {
    return new RecallLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecallLoanRequest | PlainMessage<RecallLoanRequest> | undefined, b: RecallLoanRequest | PlainMessage<RecallLoanRequest> | undefined): boolean {
    return proto3.util.equals(RecallLoanRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.RecallLoanResponse
 */
export class RecallLoanResponse extends Message<RecallLoanResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Details of the loan after recall
   *
   * @generated from field: aurora.core.apiengine.loansv1.RecallDetails recall_details = 2;
   */
  recallDetails?: RecallDetails;

  constructor(data?: PartialMessage<RecallLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.RecallLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recall_details", kind: "message", T: RecallDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecallLoanResponse {
    return new RecallLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecallLoanResponse {
    return new RecallLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecallLoanResponse {
    return new RecallLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RecallLoanResponse | PlainMessage<RecallLoanResponse> | undefined, b: RecallLoanResponse | PlainMessage<RecallLoanResponse> | undefined): boolean {
    return proto3.util.equals(RecallLoanResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.QueryLoansRequest
 */
export class QueryLoansRequest extends Message<QueryLoansRequest> {
  /**
   * Loan status (0=open, 1=closed) - optional
   *
   * @generated from field: optional int32 status_request_type = 1;
   */
  statusRequestType?: number;

  /**
   * Loan identifier (persistent identifier inside Aurora system) - optional
   *
   * @generated from field: optional string loan_id = 2;
   */
  loanId?: string;

  /**
   * Date after loans created (inclusive) - optional
   *
   * @generated from field: optional aurora.core.common.protos.PbDate start_date = 3;
   */
  startDate?: PbDate;

  /**
   * Date until loans created (inclusive) - optional
   *
   * @generated from field: optional aurora.core.common.protos.PbDate end_date = 4;
   */
  endDate?: PbDate;

  /**
   * Side (lender or borrower) - optional
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 5;
   */
  side?: Side;

  /**
   * Counterparty NSCC Account ID - optional
   *
   * @generated from field: optional string counterparty_nscc_id = 6;
   */
  counterpartyNsccId?: string;

  /**
   * Instrument (CUSIP or Ticker) - optional
   *
   * @generated from field: optional string instrument = 7;
   */
  instrument?: string;

  constructor(data?: PartialMessage<QueryLoansRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.QueryLoansRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status_request_type", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "start_date", kind: "message", T: PbDate, opt: true },
    { no: 4, name: "end_date", kind: "message", T: PbDate, opt: true },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 6, name: "counterparty_nscc_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryLoansRequest {
    return new QueryLoansRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryLoansRequest {
    return new QueryLoansRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryLoansRequest {
    return new QueryLoansRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryLoansRequest | PlainMessage<QueryLoansRequest> | undefined, b: QueryLoansRequest | PlainMessage<QueryLoansRequest> | undefined): boolean {
    return proto3.util.equals(QueryLoansRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.QueryLoansResponse
 */
export class QueryLoansResponse extends Message<QueryLoansResponse> {
  /**
   * Current details of the loan(s)
   *
   * @generated from field: repeated aurora.core.apiengine.loansv1.LoanDetails loans = 1;
   */
  loans: LoanDetails[] = [];

  /**
   * Total number of loans
   *
   * @generated from field: int64 total_count = 2;
   */
  totalCount = protoInt64.zero;

  constructor(data?: PartialMessage<QueryLoansResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.QueryLoansResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loans", kind: "message", T: LoanDetails, repeated: true },
    { no: 2, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryLoansResponse {
    return new QueryLoansResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryLoansResponse {
    return new QueryLoansResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryLoansResponse {
    return new QueryLoansResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryLoansResponse | PlainMessage<QueryLoansResponse> | undefined, b: QueryLoansResponse | PlainMessage<QueryLoansResponse> | undefined): boolean {
    return proto3.util.equals(QueryLoansResponse, a, b);
  }
}

/**
 * Current status of the loan according to Aurora
 *
 * @generated from message aurora.core.apiengine.loansv1.LoanDetails
 */
export class LoanDetails extends Message<LoanDetails> {
  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 1;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 2;
   */
  loanDisplayId = "";

  /**
   * DTCC reference id of SFT (if exists)
   *
   * @generated from field: string settlement_id = 3;
   */
  settlementId = "";

  /**
   * Type of settlement the loan is related to
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 4;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 5;
   */
  instrument?: PbInstrument;

  /**
   * Side (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 6;
   */
  side = Side.BORROWER;

  /**
   * Counterparty NSCC Account ID
   *
   * @generated from field: string counterparty_nscc_id = 7;
   */
  counterpartyNsccId = "";

  /**
   * Status of the loan
   *
   * @generated from field: aurora.core.consts.LoanStatus status = 8;
   */
  status = LoanStatus.NEW;

  /**
   * Settlement date of SFT (if exists)
   *
   * @generated from field: aurora.core.common.protos.PbDate settlement_date = 9;
   */
  settlementDate?: PbDate;

  /**
   * Total quantity of the loan
   *
   * @generated from field: int64 quantity = 10;
   */
  quantity = protoInt64.zero;

  /**
   * Rate and benchmark type for the loan
   *
   * @generated from field: aurora.core.common.protos.PbRateDetails rate_details = 11;
   */
  rateDetails?: PbRateDetails;

  /**
   * Independent amount rate of the loan
   *
   * @generated from field: string independent_amount_rate = 12;
   */
  independentAmountRate = "";

  /**
   * Rounding Rule for the loan
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 13;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * Quantity that was recalled
   *
   * @generated from field: int64 recalled_quantity = 14;
   */
  recalledQuantity = protoInt64.zero;

  /**
   * Last close price of instrument
   *
   * @generated from field: string last_close_price = 15;
   */
  lastClosePrice = "";

  /**
   * Contract amount of loan
   *
   * @generated from field: string contract_amount = 16;
   */
  contractAmount = "";

  /**
   * Settlement amount of loan
   *
   * @generated from field: string settlement_amount = 17;
   */
  settlementAmount = "";

  /**
   * Independent amount rate of loan
   *
   * @generated from field: string independent_amount = 18;
   */
  independentAmount = "";

  /**
   * Daily interest amount of loan
   *
   * @generated from field: string interest_due_amount = 19;
   */
  interestDueAmount = "";

  constructor(data?: PartialMessage<LoanDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoanDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settlement_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 5, name: "instrument", kind: "message", T: PbInstrument },
    { no: 6, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 7, name: "counterparty_nscc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(LoanStatus) },
    { no: 9, name: "settlement_date", kind: "message", T: PbDate },
    { no: 10, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "rate_details", kind: "message", T: PbRateDetails },
    { no: 12, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 14, name: "recalled_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "last_close_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "contract_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "settlement_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "independent_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "interest_due_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanDetails {
    return new LoanDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanDetails {
    return new LoanDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanDetails {
    return new LoanDetails().fromJsonString(jsonString, options);
  }

  static equals(a: LoanDetails | PlainMessage<LoanDetails> | undefined, b: LoanDetails | PlainMessage<LoanDetails> | undefined): boolean {
    return proto3.util.equals(LoanDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.CancelRecallLoanRequest
 */
export class CancelRecallLoanRequest extends Message<CancelRecallLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from field: string instrument = 3;
   */
  instrument = "";

  /**
   * Current open quantity of the loan for validation purposes
   *
   * @generated from field: int64 loan_open_quantity = 4;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Quantity that was recalled for validation purposes
   *
   * @generated from field: int64 recall_quantity = 10;
   */
  recallQuantity = protoInt64.zero;

  /**
   * Id of the recall to cancel
   *
   * @generated from field: string recall_id = 11;
   */
  recallId = "";

  constructor(data?: PartialMessage<CancelRecallLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelRecallLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "recall_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "recall_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelRecallLoanRequest {
    return new CancelRecallLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelRecallLoanRequest {
    return new CancelRecallLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelRecallLoanRequest {
    return new CancelRecallLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelRecallLoanRequest | PlainMessage<CancelRecallLoanRequest> | undefined, b: CancelRecallLoanRequest | PlainMessage<CancelRecallLoanRequest> | undefined): boolean {
    return proto3.util.equals(CancelRecallLoanRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.CancelRecallLoanResponse
 */
export class CancelRecallLoanResponse extends Message<CancelRecallLoanResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 3;
   */
  instrument?: PbInstrument;

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 4;
   */
  loanDisplayId = "";

  /**
   * Current open quantity of the loan
   *
   * @generated from field: int64 loan_open_quantity = 5;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Status of the recall
   *
   * @generated from field: aurora.core.consts.loanrecall.LoanRecallStatus recall_status = 10;
   */
  recallStatus = LoanRecallStatus.UNSPECIFIED;

  /**
   * Id of the recall assigned by Aurora
   *
   * @generated from field: string recall_id = 11;
   */
  recallId = "";

  /**
   * Quantity that was recalled
   *
   * @generated from field: int64 quantity = 12;
   */
  quantity = protoInt64.zero;

  /**
   * Next allowed buy-in execution date
   *
   * @generated from field: aurora.core.common.protos.PbDate allowed_execution_date = 13;
   */
  allowedExecutionDate?: PbDate;

  constructor(data?: PartialMessage<CancelRecallLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelRecallLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "message", T: PbInstrument },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "recall_status", kind: "enum", T: proto3.getEnumType(LoanRecallStatus) },
    { no: 11, name: "recall_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "allowed_execution_date", kind: "message", T: PbDate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelRecallLoanResponse {
    return new CancelRecallLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelRecallLoanResponse {
    return new CancelRecallLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelRecallLoanResponse {
    return new CancelRecallLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelRecallLoanResponse | PlainMessage<CancelRecallLoanResponse> | undefined, b: CancelRecallLoanResponse | PlainMessage<CancelRecallLoanResponse> | undefined): boolean {
    return proto3.util.equals(CancelRecallLoanResponse, a, b);
  }
}

/**
 * Requested `BuyIn` parameters
 *
 * @generated from message aurora.core.apiengine.loansv1.BuyInLoanRequest
 */
export class BuyInLoanRequest extends Message<BuyInLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from field: string instrument = 3;
   */
  instrument = "";

  /**
   * Current open quantity of the loan for validation purposes
   *
   * @generated from field: int64 loan_open_quantity = 4;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Quantity to buy-in
   *
   * @generated from field: int64 buy_in_quantity = 10;
   */
  buyInQuantity = protoInt64.zero;

  /**
   * Average price per share for buy-in
   *
   * @generated from field: string avg_price_per_share = 11;
   */
  avgPricePerShare = "";

  constructor(data?: PartialMessage<BuyInLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.BuyInLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "buy_in_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "avg_price_per_share", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BuyInLoanRequest {
    return new BuyInLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BuyInLoanRequest {
    return new BuyInLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BuyInLoanRequest {
    return new BuyInLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BuyInLoanRequest | PlainMessage<BuyInLoanRequest> | undefined, b: BuyInLoanRequest | PlainMessage<BuyInLoanRequest> | undefined): boolean {
    return proto3.util.equals(BuyInLoanRequest, a, b);
  }
}

/**
 * Returns current status of the `BuyIn` request
 *
 * @generated from message aurora.core.apiengine.loansv1.BuyInLoanResponse
 */
export class BuyInLoanResponse extends Message<BuyInLoanResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Instrument description
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 3;
   */
  instrument?: PbInstrument;

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 4;
   */
  loanDisplayId = "";

  /**
   * Current open quantity of the loan
   *
   * @generated from field: int64 loan_open_quantity = 5;
   */
  loanOpenQuantity = protoInt64.zero;

  /**
   * Status of the buy-in
   *
   * @generated from field: aurora.core.consts.loanbuyin.LoanBuyInStatus buy_in_status = 10;
   */
  buyInStatus = LoanBuyInStatus.UNSPECIFIED;

  /**
   * Quantity of the buy-in
   *
   * @generated from field: int64 quantity = 11;
   */
  quantity = protoInt64.zero;

  /**
   * Average price per share for buy-in
   *
   * @generated from field: string avg_price_per_share = 12;
   */
  avgPricePerShare = "";

  constructor(data?: PartialMessage<BuyInLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.BuyInLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "message", T: PbInstrument },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "loan_open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "buy_in_status", kind: "enum", T: proto3.getEnumType(LoanBuyInStatus) },
    { no: 11, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "avg_price_per_share", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BuyInLoanResponse {
    return new BuyInLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BuyInLoanResponse {
    return new BuyInLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BuyInLoanResponse {
    return new BuyInLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BuyInLoanResponse | PlainMessage<BuyInLoanResponse> | undefined, b: BuyInLoanResponse | PlainMessage<BuyInLoanResponse> | undefined): boolean {
    return proto3.util.equals(BuyInLoanResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.GetRecallsRequest
 */
export class GetRecallsRequest extends Message<GetRecallsRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system) - optional
   *
   * @generated from field: optional string loan_id = 2;
   */
  loanId?: string;

  /**
   * Recall id (persistent recall id inside Aurora system) - optional
   *
   * @generated from field: optional string recall_id = 3;
   */
  recallId?: string;

  /**
   * Instrument (CUSIP or Ticker) - optional
   *
   * @generated from field: optional string instrument = 4;
   */
  instrument?: string;

  constructor(data?: PartialMessage<GetRecallsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.GetRecallsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "recall_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRecallsRequest {
    return new GetRecallsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRecallsRequest {
    return new GetRecallsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRecallsRequest {
    return new GetRecallsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRecallsRequest | PlainMessage<GetRecallsRequest> | undefined, b: GetRecallsRequest | PlainMessage<GetRecallsRequest> | undefined): boolean {
    return proto3.util.equals(GetRecallsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.GetRecallsResponse
 */
export class GetRecallsResponse extends Message<GetRecallsResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Details of the recall(s)
   *
   * @generated from field: repeated aurora.core.apiengine.loansv1.RecallDetails recalls = 2;
   */
  recalls: RecallDetails[] = [];

  /**
   * Total number of recalls
   *
   * @generated from field: int64 total_count = 3;
   */
  totalCount = protoInt64.zero;

  constructor(data?: PartialMessage<GetRecallsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.GetRecallsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recalls", kind: "message", T: RecallDetails, repeated: true },
    { no: 3, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRecallsResponse {
    return new GetRecallsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRecallsResponse {
    return new GetRecallsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRecallsResponse {
    return new GetRecallsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRecallsResponse | PlainMessage<GetRecallsResponse> | undefined, b: GetRecallsResponse | PlainMessage<GetRecallsResponse> | undefined): boolean {
    return proto3.util.equals(GetRecallsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.ReturnLoanRequest
 */
export class ReturnLoanRequest extends Message<ReturnLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Display id (persistent loan id inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Instrument (CUSIP or Ticker)
   *
   * @generated from field: string instrument = 3;
   */
  instrument = "";

  /**
   * What client expects the current shares quantity of the loan to be (latest knowledge check)
   *
   * @generated from field: int64 open_quantity = 4;
   */
  openQuantity = protoInt64.zero;

  /**
   * Amount of shares to return
   *
   * @generated from field: int64 return_quantity = 5;
   */
  returnQuantity = protoInt64.zero;

  constructor(data?: PartialMessage<ReturnLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.ReturnLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "return_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReturnLoanRequest {
    return new ReturnLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReturnLoanRequest {
    return new ReturnLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReturnLoanRequest {
    return new ReturnLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReturnLoanRequest | PlainMessage<ReturnLoanRequest> | undefined, b: ReturnLoanRequest | PlainMessage<ReturnLoanRequest> | undefined): boolean {
    return proto3.util.equals(ReturnLoanRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.ReturnLoanResponse
 */
export class ReturnLoanResponse extends Message<ReturnLoanResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 3;
   */
  loanDisplayId = "";

  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 4;
   */
  instrument?: PbInstrument;

  constructor(data?: PartialMessage<ReturnLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.ReturnLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "message", T: PbInstrument },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReturnLoanResponse {
    return new ReturnLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReturnLoanResponse {
    return new ReturnLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReturnLoanResponse {
    return new ReturnLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReturnLoanResponse | PlainMessage<ReturnLoanResponse> | undefined, b: ReturnLoanResponse | PlainMessage<ReturnLoanResponse> | undefined): boolean {
    return proto3.util.equals(ReturnLoanResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.CancelReturnLoanRequest
 */
export class CancelReturnLoanRequest extends Message<CancelReturnLoanRequest> {
  /**
   * User populated id of this request
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Display id (persistent loan id inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  constructor(data?: PartialMessage<CancelReturnLoanRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelReturnLoanRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelReturnLoanRequest {
    return new CancelReturnLoanRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelReturnLoanRequest {
    return new CancelReturnLoanRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelReturnLoanRequest {
    return new CancelReturnLoanRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelReturnLoanRequest | PlainMessage<CancelReturnLoanRequest> | undefined, b: CancelReturnLoanRequest | PlainMessage<CancelReturnLoanRequest> | undefined): boolean {
    return proto3.util.equals(CancelReturnLoanRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.CancelReturnLoanResponse
 */
export class CancelReturnLoanResponse extends Message<CancelReturnLoanResponse> {
  /**
   * User populated id of the request this response is for
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Loan identifier (persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_id = 2;
   */
  loanId = "";

  /**
   * Display Loan identifier (human readable persistent identifier inside Aurora system)
   *
   * @generated from field: string loan_display_id = 3;
   */
  loanDisplayId = "";

  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 4;
   */
  instrument?: PbInstrument;

  constructor(data?: PartialMessage<CancelReturnLoanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.CancelReturnLoanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "message", T: PbInstrument },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelReturnLoanResponse {
    return new CancelReturnLoanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelReturnLoanResponse {
    return new CancelReturnLoanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelReturnLoanResponse {
    return new CancelReturnLoanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelReturnLoanResponse | PlainMessage<CancelReturnLoanResponse> | undefined, b: CancelReturnLoanResponse | PlainMessage<CancelReturnLoanResponse> | undefined): boolean {
    return proto3.util.equals(CancelReturnLoanResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.QueryLoansHistoryRequest
 */
export class QueryLoansHistoryRequest extends Message<QueryLoansHistoryRequest> {
  /**
   * Filter
   *
   * @generated from field: optional aurora.core.apiengine.loansv1.LoansHistoryQueryFilter filter = 1;
   */
  filter?: LoansHistoryQueryFilter;

  /**
   * List of sorting options
   *
   * @generated from field: repeated aurora.core.apiengine.loansv1.LoansHistoryQuerySort sorts = 2;
   */
  sorts: LoansHistoryQuerySort[] = [];

  /**
   * Page number, 1 being the starting one
   *
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  /**
   * Number of events per page
   *
   * @generated from field: int32 page_limit = 4;
   */
  pageLimit = 0;

  constructor(data?: PartialMessage<QueryLoansHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.QueryLoansHistoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: LoansHistoryQueryFilter, opt: true },
    { no: 2, name: "sorts", kind: "message", T: LoansHistoryQuerySort, repeated: true },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryLoansHistoryRequest {
    return new QueryLoansHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryLoansHistoryRequest {
    return new QueryLoansHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryLoansHistoryRequest {
    return new QueryLoansHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryLoansHistoryRequest | PlainMessage<QueryLoansHistoryRequest> | undefined, b: QueryLoansHistoryRequest | PlainMessage<QueryLoansHistoryRequest> | undefined): boolean {
    return proto3.util.equals(QueryLoansHistoryRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoansHistoryQueryFilter
 */
export class LoansHistoryQueryFilter extends Message<LoansHistoryQueryFilter> {
  /**
   * List of event types
   *
   * @generated from field: repeated aurora.core.consts.loanhistory.LoanHistoryEventType event_types = 1;
   */
  eventTypes: LoanHistoryEventType[] = [];

  /**
   * Start of the timestamp (RFC3339Nano) range filter
   *
   * @generated from field: optional string event_time_start = 2;
   */
  eventTimeStart?: string;

  /**
   * End of the timestamp (RFC3339Nano) range filter
   *
   * @generated from field: optional string event_time_end = 3;
   */
  eventTimeEnd?: string;

  /**
   * List of instruments (ticker or CUSIP)
   *
   * @generated from field: repeated string instruments = 4;
   */
  instruments: string[] = [];

  /**
   * List of counterparties (company ids)
   *
   * @generated from field: repeated string counterparties = 5;
   */
  counterparties: string[] = [];

  /**
   * List of partial or full Loan Display Ids
   *
   * @generated from field: repeated string loans = 6;
   */
  loans: string[] = [];

  /**
   * Side in the loan. Providing value out of the aurora.core.consts.common.Side range of values
   * will be treated as if no filter was set.
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 7;
   */
  side?: Side;

  /**
   * Event initiator kind. Providing value out of the aurora.core.consts.common.Side range of values
   * will be treated as if no filter was set.
   *
   * @generated from field: optional aurora.core.consts.loanhistory.LoanHistoryEventInitiatorKind initiator_kind = 8;
   */
  initiatorKind?: LoanHistoryEventInitiatorKind;

  /**
   * Event initiator's email or external identifier
   *
   * @generated from field: optional string initiator_id = 9;
   */
  initiatorId?: string;

  constructor(data?: PartialMessage<LoansHistoryQueryFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoansHistoryQueryFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_types", kind: "enum", T: proto3.getEnumType(LoanHistoryEventType), repeated: true },
    { no: 2, name: "event_time_start", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "event_time_end", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instruments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "counterparties", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "loans", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 8, name: "initiator_kind", kind: "enum", T: proto3.getEnumType(LoanHistoryEventInitiatorKind), opt: true },
    { no: 9, name: "initiator_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoansHistoryQueryFilter {
    return new LoansHistoryQueryFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoansHistoryQueryFilter {
    return new LoansHistoryQueryFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoansHistoryQueryFilter {
    return new LoansHistoryQueryFilter().fromJsonString(jsonString, options);
  }

  static equals(a: LoansHistoryQueryFilter | PlainMessage<LoansHistoryQueryFilter> | undefined, b: LoansHistoryQueryFilter | PlainMessage<LoansHistoryQueryFilter> | undefined): boolean {
    return proto3.util.equals(LoansHistoryQueryFilter, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoansHistoryQuerySort
 */
export class LoansHistoryQuerySort extends Message<LoansHistoryQuerySort> {
  /**
   * Filed to sort on
   *
   * @generated from field: aurora.core.consts.loanhistory.LoanHistorySortableField field = 1;
   */
  field = LoanHistorySortableField.UNSPECIFIED_LOAN_HISTORY_SORTABLE_FIELD;

  /**
   * Sorting order
   *
   * @generated from field: aurora.core.consts.query.SortOrder order = 2;
   */
  order = SortOrder.UNSPECIFIED_SORT_ORDER;

  constructor(data?: PartialMessage<LoansHistoryQuerySort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoansHistoryQuerySort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(LoanHistorySortableField) },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoansHistoryQuerySort {
    return new LoansHistoryQuerySort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoansHistoryQuerySort {
    return new LoansHistoryQuerySort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoansHistoryQuerySort {
    return new LoansHistoryQuerySort().fromJsonString(jsonString, options);
  }

  static equals(a: LoansHistoryQuerySort | PlainMessage<LoansHistoryQuerySort> | undefined, b: LoansHistoryQuerySort | PlainMessage<LoansHistoryQuerySort> | undefined): boolean {
    return proto3.util.equals(LoansHistoryQuerySort, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.QueryLoansHistoryResponse
 */
export class QueryLoansHistoryResponse extends Message<QueryLoansHistoryResponse> {
  /**
   * List of events
   *
   * @generated from field: repeated aurora.core.apiengine.loansv1.LoanHistoryEvent events = 1;
   */
  events: LoanHistoryEvent[] = [];

  /**
   * Total number of events
   *
   * @generated from field: int64 total_entries = 2;
   */
  totalEntries = protoInt64.zero;

  constructor(data?: PartialMessage<QueryLoansHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.QueryLoansHistoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: LoanHistoryEvent, repeated: true },
    { no: 2, name: "total_entries", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryLoansHistoryResponse {
    return new QueryLoansHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryLoansHistoryResponse {
    return new QueryLoansHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryLoansHistoryResponse {
    return new QueryLoansHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryLoansHistoryResponse | PlainMessage<QueryLoansHistoryResponse> | undefined, b: QueryLoansHistoryResponse | PlainMessage<QueryLoansHistoryResponse> | undefined): boolean {
    return proto3.util.equals(QueryLoansHistoryResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoanHistoryEvent
 */
export class LoanHistoryEvent extends Message<LoanHistoryEvent> {
  /**
   * Event identifier
   *
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  /**
   * Event type
   *
   * @generated from field: aurora.core.consts.loanhistory.LoanHistoryEventType event_type = 2;
   */
  eventType = LoanHistoryEventType.UNSPECIFIED_LOAN_HISTORY_EVENT_TYPE;

  /**
   * Event timestamp
   *
   * @generated from field: string event_time = 3;
   */
  eventTime = "";

  /**
   * Event initiator
   *
   * @generated from field: aurora.core.apiengine.loansv1.LoanHistoryEventInitiator initiator = 4;
   */
  initiator?: LoanHistoryEventInitiator;

  /**
   * Loan state
   *
   * @generated from field: aurora.core.apiengine.loansv1.LoanHistoryState loan_state = 5;
   */
  loanState?: LoanHistoryState;

  constructor(data?: PartialMessage<LoanHistoryEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoanHistoryEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "event_type", kind: "enum", T: proto3.getEnumType(LoanHistoryEventType) },
    { no: 3, name: "event_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "initiator", kind: "message", T: LoanHistoryEventInitiator },
    { no: 5, name: "loan_state", kind: "message", T: LoanHistoryState },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanHistoryEvent {
    return new LoanHistoryEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanHistoryEvent {
    return new LoanHistoryEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanHistoryEvent {
    return new LoanHistoryEvent().fromJsonString(jsonString, options);
  }

  static equals(a: LoanHistoryEvent | PlainMessage<LoanHistoryEvent> | undefined, b: LoanHistoryEvent | PlainMessage<LoanHistoryEvent> | undefined): boolean {
    return proto3.util.equals(LoanHistoryEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoanHistoryState
 */
export class LoanHistoryState extends Message<LoanHistoryState> {
  /**
   * Loan id
   *
   * @generated from field: string loan_id = 1;
   */
  loanId = "";

  /**
   * Loan display id (persistent loan id inside Aurora system)
   *
   * @generated from field: string loan_display_id = 2;
   */
  loanDisplayId = "";

  /**
   * Type of settlement the loan is related to
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 3;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 4;
   */
  instrument?: PbInstrument;

  /**
   * Side (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Counterparty information
   *
   * @generated from field: aurora.core.apiengine.loansv1.LoanParticipant counterparty = 6;
   */
  counterparty?: LoanParticipant;

  /**
   * Total quantity of the loan
   *
   * @generated from field: int64 quantity = 7;
   */
  quantity = protoInt64.zero;

  /**
   * Open quantity of the loan
   *
   * @generated from field: int64 open_quantity = 8;
   */
  openQuantity = protoInt64.zero;

  /**
   * Unit price
   *
   * @generated from field: string unit_price = 9;
   */
  unitPrice = "";

  /**
   * Rate and benchmark type for the loan
   *
   * @generated from field: aurora.core.common.protos.PbRateDetails rate_details = 10;
   */
  rateDetails?: PbRateDetails;

  /**
   * Settlement amount of loan
   *
   * @generated from field: string settlement_amount = 11;
   */
  settlementAmount = "";

  /**
   * Daily interest amount of loan
   *
   * @generated from field: string interest_due_amount = 12;
   */
  interestDueAmount = "";

  /**
   * Contract amount of loan
   *
   * @generated from field: string contract_amount = 13;
   */
  contractAmount = "";

  /**
   * The change in the contract amount
   *
   * @generated from field: string contract_amount_change = 14;
   */
  contractAmountChange = "";

  /**
   * The change in the settlement amount
   *
   * @generated from field: string settlement_amount_change = 15;
   */
  settlementAmountChange = "";

  /**
   * The change in the open quantity
   *
   * @generated from field: int64 open_quantity_change = 16;
   */
  openQuantityChange = protoInt64.zero;

  constructor(data?: PartialMessage<LoanHistoryState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoanHistoryState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "loan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "loan_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 4, name: "instrument", kind: "message", T: PbInstrument },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 6, name: "counterparty", kind: "message", T: LoanParticipant },
    { no: 7, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "rate_details", kind: "message", T: PbRateDetails },
    { no: 11, name: "settlement_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "interest_due_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "contract_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "contract_amount_change", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "settlement_amount_change", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "open_quantity_change", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanHistoryState {
    return new LoanHistoryState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanHistoryState {
    return new LoanHistoryState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanHistoryState {
    return new LoanHistoryState().fromJsonString(jsonString, options);
  }

  static equals(a: LoanHistoryState | PlainMessage<LoanHistoryState> | undefined, b: LoanHistoryState | PlainMessage<LoanHistoryState> | undefined): boolean {
    return proto3.util.equals(LoanHistoryState, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoanParticipant
 */
export class LoanParticipant extends Message<LoanParticipant> {
  /**
   * Company id
   *
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * Company name
   *
   * @generated from field: string company_name = 2;
   */
  companyName = "";

  /**
   * Human readable company id
   *
   * @generated from field: string company_display_id = 3;
   */
  companyDisplayId = "";

  /**
   * NSCC Account ID
   *
   * @generated from field: string company_nscc_id = 4;
   */
  companyNsccId = "";

  constructor(data?: PartialMessage<LoanParticipant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoanParticipant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company_display_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "company_nscc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanParticipant {
    return new LoanParticipant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanParticipant {
    return new LoanParticipant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanParticipant {
    return new LoanParticipant().fromJsonString(jsonString, options);
  }

  static equals(a: LoanParticipant | PlainMessage<LoanParticipant> | undefined, b: LoanParticipant | PlainMessage<LoanParticipant> | undefined): boolean {
    return proto3.util.equals(LoanParticipant, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loansv1.LoanHistoryEventInitiator
 */
export class LoanHistoryEventInitiator extends Message<LoanHistoryEventInitiator> {
  /**
   * Event initiator's kind
   *
   * @generated from field: aurora.core.consts.loanhistory.LoanHistoryEventInitiatorKind kind = 1;
   */
  kind = LoanHistoryEventInitiatorKind.UNSPECIFIED_EVENT_INITIATOR_KIND;

  /**
   * Event initiator's email
   *
   * @generated from field: optional string email = 2;
   */
  email?: string;

  /**
   * Event initiator's external identifier
   *
   * @generated from field: optional string external_identifier = 3;
   */
  externalIdentifier?: string;

  constructor(data?: PartialMessage<LoanHistoryEventInitiator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loansv1.LoanHistoryEventInitiator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "enum", T: proto3.getEnumType(LoanHistoryEventInitiatorKind) },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "external_identifier", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoanHistoryEventInitiator {
    return new LoanHistoryEventInitiator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoanHistoryEventInitiator {
    return new LoanHistoryEventInitiator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoanHistoryEventInitiator {
    return new LoanHistoryEventInitiator().fromJsonString(jsonString, options);
  }

  static equals(a: LoanHistoryEventInitiator | PlainMessage<LoanHistoryEventInitiator> | undefined, b: LoanHistoryEventInitiator | PlainMessage<LoanHistoryEventInitiator> | undefined): boolean {
    return proto3.util.equals(LoanHistoryEventInitiator, a, b);
  }
}

