<template>
  <v-dialog
    v-model="dialog"
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    :max-width="1200"
    origin="center left"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    scrollable
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <h2 class="headline">
            {{
              statusSingular + (isPreEstablished ? ` pre-established` : ` manual`) + ` loan request`
            }}
          </h2>
        </v-card-title>

        <v-card-text class="text--primary">
          <!-- Form summary  -->
          <v-container
            v-if="actionableManualLoans.length"
            v-shortkey="['enter']"
            class="form-message"
            fluid
            @shortkey="submitForm()"
          >
            <div class="message text-body-1">Are you sure?</div>
            <div>
              You are about to <b class="text-capitalize">{{ statusSingular }}</b> these
              {{ actionableManualLoans.length }} <b v-if="isPreEstablished">pre-established</b>
              loan requests.
            </div>
          </v-container>
          <v-container v-else class="form-message" fluid>
            <div>None of the selected loans can be actioned anymore.</div>
          </v-container>

          <v-container class="form-summary d-flex flex-column" fluid>
            <v-row no-gutters>
              <v-col>
                <v-row class="justify-space-between mb-4" no-gutters>
                  <div>
                    <h2 class="font-weight-regular">Loans</h2>
                  </div>
                  <div class="gross-notional text--secondary">
                    Gross Notional: ${{ formatPrice(grossNotional) }}
                  </div>
                </v-row>

                <v-row no-gutters>
                  <v-col class="manual-loans-list">
                    <!-- List of manual loans -->
                    <div v-for="[id, group] in manualLoanGroups" :key="id" class="py-6">
                      <v-row class="mb-1">
                        <v-col>
                          <div class="security text-body-1 text-truncate">
                            {{ group.counterpartyDisplay }}
                          </div>
                        </v-col>
                        <v-col v-if="anyIA" class="col-auto ia">
                          <div class="label text--secondary" title="Independent Amount">
                            Ind Amount
                          </div>
                          <div class="value text-body-2">
                            <rate-output :precision="2" :rate="group.independentAmountRate" />
                          </div>
                        </v-col>
                        <v-col v-if="anyRoundingRule" class="col-auto rr">
                          <div class="label text--secondary" title="Rounding Rule">Rounding</div>
                          <div class="value text-body-2">
                            {{ roundingRuleToShortString(group.roundingRule) }}
                          </div>
                        </v-col>
                      </v-row>

                      <v-row
                        v-for="(item, idx) in group.loans"
                        :key="item.id"
                        class="manual-loans-item pb-2 mb-2 mr-1"
                        :class="{ 'not-actionable': !item.isActionable }"
                        no-gutters
                      >
                        <div
                          v-if="!item.isActionable"
                          class="not-actionable-message d-flex justify-center align-center"
                        >
                          <manual-loan-status-chip :item="item">
                            <template #prepend> Loan is </template>
                          </manual-loan-status-chip>
                        </div>

                        <v-col class="col-auto">
                          <div class="security text-body-1">
                            {{ item.equity.ticker }} ({{ item.equity.cusip }})
                          </div>
                          <div class="d-flex">
                            <div class="price pr-6 text--secondary">
                              Price: ${{ formatPrice(item.equity.lastClosePrice) }}
                            </div>
                            <div class="notional pr-6 text--secondary">
                              Notional: ${{ formatPrice(item.contractAmount) }}
                            </div>
                          </div>
                        </v-col>

                        <v-col class="col-auto ml-auto" cols="5">
                          <v-row no-gutters>
                            <v-col class="quantity pr-6">
                              <div class="label text--secondary">Quantity</div>
                              <div class="value text-body-2">
                                {{ formatNumber(item.quantity) }}
                              </div>
                            </v-col>
                            <v-col class="rate">
                              <div class="label text--secondary">Rate</div>
                              <div class="value text-body-2">
                                <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
                              </div>
                            </v-col>
                            <v-col v-if="nonSftLoansEnabled" class="rr">
                              <div
                                class="label text--secondary text-no-wrap"
                                title="Settlement Type"
                              >
                                Settlement Type
                              </div>
                              <div class="value text-body-2">
                                {{ settlementTypeDisplayText[item.settlementType] }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>

                        <div
                          v-if="manualLoans.length > 1"
                          class="row-number text--secondary text-right"
                        >
                          <small>{{
                            `${group.loansOffset + idx + 1} of ${manualLoans.length}`
                          }}</small>
                        </div>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn
              color="secondary"
              data-test="cancel-btn"
              :disabled="formStatus !== 'idle'"
              @click="closeDialog()"
            >
              {{ $t('dialogs.cancelButton') }}
            </v-btn>
            <div
              class="v-alert v-alert--dense text-center"
              :class="{ error: apiErrors.length, success: showSuccess }"
            >
              <div v-if="apiErrors.length">
                <div>Something went wrong. Please check your request and try again.</div>
                <small>{{ apiErrors.join('\n') }}</small>
              </div>
              <div v-if="showSuccess">
                <div>Success!</div>
                <small
                  >Your manual loan
                  {{ actionableManualLoans.length == 1 ? 'request has' : 'requests have' }} been
                  canceled.</small
                >
              </div>
            </div>
            <v-btn
              color="primary"
              :disabled="!actionableManualLoans.length || formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('dialogs.confirmButton') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import wait from '@/modules/common/services/wait';
import { Api } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import {
  ManualLoanStatus,
  manualLoanStatusCssClass,
} from '@/modules/manual-loan/constants/manual-loans.const';
import { ManualLoanListDisplayItem } from '@/modules/manual-loan/types/manual-loans';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import {
  RoundingRule,
  roundingRuleToShortString,
} from '@/modules/sec-lending/helpers/contract-details';
import { Side } from '@/modules/user-accounts/types/user-accounts';
import { LoginState } from '@/store/store';
import { BadRequestError } from '@/utils/errors';
import { getPriceAsString, getVolumeAsString } from '@/utils/helpers/auction-numbers';
import { ClientConfig } from '@/utils/helpers/rest';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import { Decimal } from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import ManualLoanStatusChip from '@/modules/manual-loan/components/ManualLoanStatusChip.vue';

type ManualLoanGroups = Map<
  string,
  Pick<
    ManualLoanListDisplayItem,
    'counterpartyDisplay' | 'roundingRule' | 'independentAmountRate'
  > & {
    loansOffset: number;
    loans: ManualLoanListDisplayItem[];
  }
>;

@Component({
  components: {
    ManualLoanStatusChip,
  },
  props: {
    side: {
      type: String as PropType<Side>,
      required: true,
    },
    showDialog: Boolean,
    newStatus: String,
    isPreEstablished: Boolean,
    manualLoans: {
      required: true,
      type: Array as PropType<ManualLoanListDisplayItem[]>,
    },
  },
  computed: {
    ...mapState(['loginState', 'clientConfig']),
  },
})
export default class UpdateManualLoanStatusDialog extends Vue {
  protected readonly loginState!: NonNullableAll<LoginState>;
  protected readonly clientConfig!: ClientConfig;

  protected readonly isPreEstablished!: boolean;
  protected readonly showDialog!: boolean;
  protected readonly newStatus!:
    | ManualLoanStatus.Accepted
    | ManualLoanStatus.Rejected
    | ManualLoanStatus.Canceled;
  protected readonly manualLoans!: ManualLoanListDisplayItem[];
  protected readonly side!: Side;

  protected showSuccess = false;
  protected formStatus: DialogFormStatus = 'idle';
  protected apiErrors: string[] = [];
  protected pricePrecision = PRICE_PRECISION;
  protected statusCssClass = manualLoanStatusCssClass;

  protected settlementTypeDisplayText = settlementTypeDisplayText;

  protected get manualLoanGroups(): ManualLoanGroups {
    const manualLoanGroups = this.manualLoans
      .map(
        (manualLoan) =>
          [
            `${manualLoan.counterpartyDisplay}_${manualLoan.roundingRule}_${manualLoan.independentAmountRate}`,
            manualLoan,
          ] as const
      )
      .sort(
        ([idA, manualLoanA], [idB, manualLoanB]) =>
          idA.localeCompare(idB) || +manualLoanB.updatedAt - +manualLoanA.updatedAt
      )
      .reduce<ManualLoanGroups>((groups, [id, manualLoan]) => {
        let group = groups.get(id);
        if (!group) {
          group = {
            counterpartyDisplay: manualLoan.counterpartyDisplay,
            roundingRule: manualLoan.roundingRule,
            independentAmountRate: manualLoan.independentAmountRate,
            loansOffset: 0,
            loans: [],
          };
          groups.set(id, group);
        }
        group.loans.push(manualLoan);
        return groups;
      }, new Map());

    let loansOffset = 0;
    for (const [, manualLoanGroup] of manualLoanGroups) {
      manualLoanGroup.loansOffset = loansOffset;
      loansOffset += manualLoanGroup.loans.length;
    }
    return manualLoanGroups;
  }

  protected get nonSftLoansEnabled(): boolean {
    return (
      !this.isPreEstablished &&
      (this.clientConfig.bilateralLoansEnabled || this.clientConfig.occBilateralLoansEnabled)
    );
  }

  protected get dialog(): boolean {
    return this.showDialog;
  }

  protected set dialog(value: boolean) {
    this.$emit('update:showDialog', value);
  }

  protected get anyRoundingRule(): boolean {
    return this.actionableManualLoans.some((l) => {
      return l.roundingRule !== RoundingRule.NoRounding;
    });
  }

  protected get anyIA(): boolean {
    return this.actionableManualLoans.some((l) => {
      return !l.independentAmountRate.isZero();
    });
  }

  /**
   * Gets the gross notional value for all actionable loans
   */
  protected get grossNotional(): Decimal {
    return this.actionableManualLoans.reduce<Decimal>((prevItem, currItem) => {
      try {
        return new Decimal(prevItem).add(currItem.contractAmount || 0);
      } catch (err) {
        return prevItem;
      }
    }, new Decimal(0));
  }

  protected get statusSingular(): string {
    switch (this.newStatus) {
      case ManualLoanStatus.Accepted:
        return 'accept';
      case ManualLoanStatus.Rejected:
        return 'reject';
      case ManualLoanStatus.Canceled:
        return 'delete';
      default:
        return '';
    }
  }

  /**
   * Manual loans which can be actioned
   */
  protected get actionableManualLoans(): ManualLoanListDisplayItem[] {
    return this.manualLoans.filter((item) => item.isActionable);
  }

  protected closeDialog(): void {
    this.resetForm();
    this.dialog = false;
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }

  protected formatNumber(number: number): string {
    return getVolumeAsString(number);
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }

    // when accepting a manual loan we need to check risk limits
    if (this.newStatus === ManualLoanStatus.Accepted) {
      const largestNotional = this.actionableManualLoans.reduce(
        (max, o) => (o.contractAmount.greaterThan(max) ? o.contractAmount : max),
        new Decimal(0)
      );
      new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
        largestNotional,
        () => {
          this.$emit('toggle-can-cancel', false);
          void this.doSubmitForm();
        }
      );
    } else {
      return await this.doSubmitForm();
    }
  }

  protected async doSubmitForm(): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }

    this.formStatus = 'submitting';
    this.apiErrors = [];

    try {
      const data: Api.ManualLoans.StatusChangeRequest[] = this.actionableManualLoans.map((item) => {
        return {
          requestId: item.id,
          status: this.newStatus,
        };
      });
      await this.$api.manualLoans.batchUpdateManualLoanStatus(data, this.side);

      // show success, and then close form and clear the list selection
      this.showSuccess = true;

      this.formStatus = 'closing';
      await wait(1200);

      this.closeDialog();
      this.$emit('update:manualLoans', []);
    } catch (err) {
      // error, show and allow user to try fix
      if (this.isPartialSuccessResponse(err)) {
        // one or more items failed
        // @TODO We could indicate exactly which ones failed, and why
        // for now just indicate an error happened and how many failed
        const errMsg = `${err.responseData?.failedItems.length} of ${this.actionableManualLoans.length} items failed`;
        this.apiErrors = [errMsg];
      } else {
        this.apiErrors = ['Unknown Error'];
      }
    } finally {
      this.formStatus = 'idle';
    }
  }

  private roundingRuleToShortString(rr: RoundingRule): string {
    return roundingRuleToShortString(rr);
  }

  /**
   * Provides type narrowing for 400 API error response
   */
  private isPartialSuccessResponse(
    err: unknown
  ): err is BadRequestError<Api.ManualLoans.StatusChangeErrorResponse> {
    return err instanceof BadRequestError && err.responseData && 'failedItems' in err.responseData;
  }

  private resetForm(): void {
    this.apiErrors = [];
    this.showSuccess = false;
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .manual-loans-list {
    min-height: 20vh;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;

    .manual-loans-item {
      position: relative;
      gap: 0.5rem;
      border-bottom: solid 1px;

      &.not-actionable > * {
        opacity: 0.35;
        filter: blur(0.7px);
      }

      .not-actionable-message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        filter: none;
        z-index: 1;
      }

      .price {
        min-width: 8rem;
      }

      .quantity .value {
        min-width: 6rem;
      }

      .rate .value {
        min-width: 6rem;
      }

      .ia .value {
        min-width: 5.5rem;
      }

      .rr .value {
        min-width: 4.5rem;
      }

      .row-number {
        min-width: 2.5rem;
      }
    }
  }

  .form-summary {
    min-height: 30rem;
  }
}

/* Theme specific overrides */
.theme--dark {
  .manual-loans-item {
    border-color: rgba(255, 255, 255, 0.4);
  }
}

.theme--light {
  .manual-loans-item {
    border-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
