<template>
  <v-text-field
    ref="price"
    v-model="priceStr"
    :append-icon="appendIcon"
    :class="['auction-number-input', { 'text-danger': priceErr }]"
    color="form-field"
    :disabled="disabled"
    :label="noLabel ? '' : $t('LIMIT')"
    :prefix="symbol"
    type="text"
    @blur="formatInput(0)"
    @focus="handleFocus"
    @keyup.down="handleStep(-1)"
    @keyup.exact="handleKeyup"
    @keyup.up="handleStep(+1)"
  />
</template>

<script lang="ts">
/**
 * PriceInput is a small component for editing and formatting prices
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getPriceAsString, parsePrice } from '@/utils/helpers/auction-numbers';
import i18n from '@/localisation/i18n';
import { OrderTicket } from '@/utils/helpers/rest';

@Component({
  props: {
    disabled: Boolean,
    ticket: Object,
    price: Number,
    symbol: String,
    step: Number,
    noLabel: Boolean,
    precision: Number,
    maxPrice: Number,
    appendIcon: String,
  },
})
export default class PriceInput extends Vue {
  private reUnformattedPrice = /,/gi;
  private reBeautifiedPrice = /[^0-9,.]/gi;

  private ticket!: OrderTicket;
  private price!: number | null;
  private noLabel!: boolean;
  private maxPrice!: number;
  private priceStr!: string;
  private priceErr!: string;

  private step!: number;
  private precision!: number;

  // price changed by parent component; reformat display string
  @Watch('price')
  protected onPrice(newPrice: number): void {
    this.priceStr = getPriceAsString(Number(newPrice), this.precision);
  }

  @Watch('priceErr')
  protected onError(newError: string): void {
    this.$emit('changed-error', newError, this.ticket);
  }

  protected data(): { priceStr: string; priceErr: string } {
    return {
      priceStr: this.price !== null ? getPriceAsString(this.price, this.precision) : '',
      priceErr: '',
    };
  }

  protected handleStep(direction: number): void {
    this.formatInput(direction * this.step);
  }

  protected handleFocus(): void {
    // remove thousand separators from the input
    this.priceStr = this.priceStr.replace(this.reUnformattedPrice, '');
  }

  protected handleKeyup(): void {
    this.priceErr = '';
    const tmpPrice = this.priceStr.replace(this.reBeautifiedPrice, '');
    if (tmpPrice !== this.priceStr) {
      this.priceErr = i18n.tc('invalid.priceLimit');
      return;
    }
    if (this.priceStr === '') {
      this.priceErr = i18n.t('empty.priceLimit') as string;
      return;
    }
  }

  protected formatInput(change: number): void {
    // don't format the price if there is an error, giving the user the change to correct
    if (this.priceErr !== '') {
      return;
    }

    const curPrice = parsePrice(this.priceStr);
    if (curPrice === undefined) {
      this.priceErr = i18n.t('invalid.priceLimit') as string;
      return;
    }

    if (change != 0) {
      const newPrice = this.roundToStepSize(
        curPrice + change < this.step ? this.step : curPrice + change
      );
      this.priceStr = getPriceAsString(newPrice, this.precision);
      this.$emit('changed-price', newPrice, this.ticket);
    } else {
      this.priceStr = getPriceAsString(curPrice, this.precision);
      this.$emit('changed-price', curPrice, this.ticket);
    }
  }

  private roundToStepSize(price: number): number {
    return Math.round(price / this.step) * this.step;
  }
}
</script>
