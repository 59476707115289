import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { ColDef } from 'ag-grid-enterprise';
import { Api } from '@/modules/common/types/api';
import { alignLeft, alignRight, comparator, component, mergeClasses } from './utils';
import { formatCounterparties } from '@/modules/marketplace/helpers/marketplace';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';

type ItemBorrow = Api.Marketplace.TopOfBookResponseItem['borrow'];
type ItemLend = Api.Marketplace.TopOfBookResponseItem['lend'];

export function ticker(): ColDef<Api.Marketplace.TopOfBookResponseItem> {
  return {
    field: 'equity.ticker',
    colId: 'equityTicker',
    headerName: 'Ticker',
    ...alignLeft(),
    flex: 101,
  };
}

export function cusip(): ColDef<Api.Marketplace.TopOfBookResponseItem> {
  return {
    field: 'equity.cusip',
    colId: 'equityCUSIP',
    headerName: 'CUSIP',
    ...mergeClasses([alignLeft(), { cellClass: 'highlighted-border-right' }]),
    flex: 130,
  };
}

export function borrowEligibleTotalQuantity(): ColDef<
  Api.Marketplace.TopOfBookResponseItem,
  ItemBorrow
> {
  return {
    field: 'borrow',
    colId: 'borrowEligibleTotalQuantity',
    headerName: 'Eligible Total Demand',
    valueFormatter: (params) =>
      !params.value ? '–' : formatPrettyNumber(params.value.eligibleTotalQuantity),
    comparator: (a, b) => comparator.number(a?.eligibleTotalQuantity, b?.eligibleTotalQuantity),
    ...alignRight(),
    flex: 205,
  };
}

export function borrowCounterparty(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowCounterparties',
    headerName: 'Counterparty',
    valueFormatter: (params) =>
      !params.value ? '–' : formatCounterparties(params.value.counterparties),
    ...alignRight(),
    flex: 177,
  };
}

export function borrowQuantity(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowQuantity',
    headerName: 'Quantity',
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value.quantity)),
    comparator: (a, b) => comparator.number(a?.quantity, b?.quantity),
    ...alignRight(),
    flex: 134,
  };
}

const rateAdapter = component<{
  item?: ItemBorrow | ItemLend;
}>((props) => () => (!props.item ? h('span', '–') : h(RateOutput, { props: props.item })));

export function borrowRate(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'borrow',
    colId: 'borrowRate',
    headerName: 'Best Bid',
    cellRendererSelector: (params) =>
      rateAdapter({
        item: params.value,
      }),
    comparator: (a, b) => comparator.decimal(a?.rate, b?.rate),
    cellDataType: 'text',
    ...mergeClasses([
      alignRight({ hasPostfix: true }),
      { cellClass: 'borrower-color highlighted-border-right' },
    ]),
    flex: 134,
  };
}

export function lendRate(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'lend',
    colId: 'lendRate',
    headerName: 'Best Ask',
    cellRendererSelector: (params) =>
      rateAdapter({
        item: params.value,
      }),
    comparator: (a, b) => comparator.decimal(a?.rate, b?.rate),
    cellDataType: 'text',
    ...mergeClasses([alignLeft(), { cellClass: 'lender-color' }]),
    flex: 118,
  };
}

export function lendQuantity(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'lend',
    colId: 'lendQuantity',
    headerName: 'Quantity',
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value.quantity)),
    comparator: (a, b) => comparator.number(a?.quantity, b?.quantity),
    ...alignLeft(),
    flex: 104,
  };
}

export function lendCounterparty(): ColDef<Api.Marketplace.TopOfBookResponseItem, ItemBorrow> {
  return {
    field: 'lend',
    colId: 'lendCounterparties',
    headerName: 'Counterparty',
    valueFormatter: (params) =>
      !params.value ? '–' : formatCounterparties(params.value.counterparties),
    ...alignLeft(),
    flex: 179,
  };
}

export function lendEligibleTotalQuantity(): ColDef<
  Api.Marketplace.TopOfBookResponseItem,
  ItemBorrow
> {
  return {
    field: 'lend',
    colId: 'lendEligibleTotalQuantity',
    headerName: 'Eligible Total Supply',
    valueFormatter: (params) =>
      !params.value ? '–' : formatPrettyNumber(params.value.eligibleTotalQuantity),
    comparator: (a, b) => comparator.number(a?.eligibleTotalQuantity, b?.eligibleTotalQuantity),
    ...alignLeft(),
    flex: 166,
  };
}
