<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="850"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <uploader
      v-if="step === 'upload'"
      :execute="(file) => $api.marketplace.uploadOrders({ side, file })"
      :with-dummy-header="false"
      @close-modal="closeDialog()"
      @upload="goToConfirm($event)"
      @upload-method="uploadMethod = $event"
      @upload-with-errors="goToConfirmWithErrors($event)"
    >
      <template #title>
        Upload
        <format-side v-if="side" :side="side">
          {{ side === 'LENDER' ? 'Supply' : 'Demand' }}
        </format-side>
        orders
      </template>
      <template #description>
        <div>Expected columns (* is optional):</div>
        <div>
          {{
            [
              'Ticker or CUSIP',
              'Quantity',
              'Rate',
              'Active*',
              'Time in force*',
              'Counterparty*',
              clientConfig.orderbookMinQuantityEditable ? 'Min. Quantity*' : null,
              'Order Type*',
              clientConfig.orderbookSettlementTypeChoiceEnabled ? 'Sett. Type*' : null,
            ]
              .filter((f) => !!f)
              .join(' | ')
          }}
        </div>
      </template>
    </uploader>

    <upload-confirm-basket
      v-if="step === 'confirm'"
      :basket-response="basketResponse"
      :error-headers="[
        { text: 'Row', value: 'row' },
        { text: 'Field', value: 'field' },
        { text: 'Error', value: 'errMsg' },
      ]"
      :execute="(file) => $api.marketplace.createOrders(file)"
      :headers="
        [
          { text: 'Equity', value: 'equity' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Rate', value: 'rate', align: 'end' },
          { text: 'Active', value: 'routingStatus' },
          { text: 'Time In Force', value: 'timeInForceType' },
          { text: 'Counterparty', value: 'counterparty' },
          { text: 'Min Qty.', value: 'minQuantity' },
          clientConfig.orderbookSettlementTypeChoiceEnabled
            ? { text: 'Sett. Type', value: 'settlementType' }
            : null,
          anyEntriesExceedSoftLimit ? {} : null, // if any entries exceed soft-limit we show warning icons
        ].filter((h) => !!h)
      "
      timeframe="createLoans"
      :upload-method="uploadMethod"
      @back="step = 'upload'"
      @close-modal="closeDialog()"
    >
      <template #title>New <format-side :side="basketResponse.side" /> orders</template>
      <template v-if="loginState.user" #summary>
        You are about to create <strong>{{ basketResponse.entries.length }}</strong> orders
        <span>
          with an <strong>Independent Amount</strong> of
          <strong>
            <rate-output :rate="loginState.user.companyPreferredIndependentAmountRate" trim-zeros />
            IA rate
          </strong>
          and
          <span v-if="hasRoundingRule">
            <strong>Rounding Rule</strong> of
            <strong>
              {{ roundingRuleToShortString(loginState.user.companyPreferredRoundingRule) }}
            </strong>
          </span>
          <strong v-else>No Rounding Rule</strong> applied
        </span>
      </template>
      <template #item="{ item }">
        <tr>
          <td>{{ item.equity.ticker }} [{{ item.equity.cusip }}]</td>
          <td>
            <pretty-number :value="item.quantity" />
          </td>
          <td class="text-end">
            <rate-output :rate="item.rate" />
          </td>
          <td>{{ item.routingStatus === 'ROUTED' ? 'Yes' : 'No' }}</td>
          <td>
            {{ timeInForceAbbr(item.timeInForceType) }}
          </td>
          <td>
            {{
              item.counterparties && item.counterparties.length > 0
                ? item.counterparties.map(formatCompanyBoxId).join(', ')
                : 'All'
            }}
          </td>
          <td>
            <pretty-number :value="item.minQuantity" />
          </td>
          <td v-if="clientConfig.orderbookSettlementTypeChoiceEnabled">
            {{ settlementTypeDisplayText[item.settlementType] }}
          </td>
          <td v-if="anyEntriesExceedSoftLimit">
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon v-if="entryExceedsSoftLimit(item)" color="yellow darken-2">
                    mdi-alert-circle
                  </v-icon>
                </span>
              </template>
              <span>
                The notional value of your order exceeds your soft limit of ${{
                  riskLimitValidator.softLimitValue
                }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template #create-button-text>Create {{ basketResponse.entries.length }} orders</template>
      <template #success-message>Orders successfully created</template>
      <template #error-title>Failed <format-side :side="side" /> orders</template>
      <template #error-item="{ item }">
        <tr>
          <td>{{ item.row }}</td>
          <td>{{ item.field }}</td>
          <td class="text-capitalize">{{ item.errMsg }}</td>
        </tr>
      </template>
    </upload-confirm-basket>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/common/types/api';
import {
  settlementTypeDisplayText,
  timeInForceAbbr,
} from '@/modules/marketplace/helpers/marketplace';
import { OrderSide } from '@/modules/marketplace/types/marketplace';
import { ClientConfig } from '@/utils/helpers/rest';
import { LoginState } from '@/store/store';
import { mapState } from 'vuex';
import Uploader from '@/modules/common/components/Uploader.vue';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import UploadConfirmBasket from '@/modules/common/components/UploadConfirmBasket.vue';
import type { UploadMethod } from '@/modules/common/types/upload';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import {
  RoundingRule,
  roundingRuleToShortString,
} from '@/modules/sec-lending/helpers/contract-details';
import { formatCompanyBoxId } from '@/modules/user-accounts/helpers/user-accounts';
import Decimal from 'decimal.js';

@Component({
  methods: { formatCompanyBoxId, roundingRuleToShortString },
  props: {
    side: {
      type: String as PropType<OrderSide>,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
  components: {
    Uploader,
    UploadParseErrors,
    UploadConfirmBasket,
  },
})
export default class MarketplaceOrdersUploadDialog extends Vue {
  // store
  protected readonly clientConfig!: ClientConfig;
  protected readonly loginState!: LoginState;
  protected riskLimitValidator!: RiskLimitValidator;

  // props
  protected readonly side!: OrderSide;

  protected settlementTypeDisplayText = settlementTypeDisplayText;

  protected uploadMethod: UploadMethod | null = null;
  protected timeInForceAbbr = timeInForceAbbr;

  protected step: 'upload' | 'confirm' = 'upload';
  protected basketResponse!: Api.Marketplace.BasketResponse;
  protected errors: Api.Marketplace.OrderUploadErrorItem[] = [];

  protected get hasRoundingRule(): boolean {
    if (!this.loginState.user) {
      return false;
    }
    return (
      this.loginState.user.companyPreferredRoundingRule !== null &&
      this.loginState.user.companyPreferredRoundingRule !== RoundingRule.NoRounding
    );
  }

  protected get anyEntriesExceedSoftLimit(): boolean {
    return this.basketResponse.entries.some(this.entryExceedsSoftLimit);
  }

  protected mounted(): void {
    if (!this.loginState.user) {
      return;
    }
    this.riskLimitValidator = new RiskLimitValidator(this.$dialog, this.loginState.user);
  }

  protected goToConfirm(response: Api.Marketplace.BasketResponse): void {
    this.basketResponse = response;
    this.step = 'confirm';
  }

  protected goToConfirmWithErrors(
    responseData: Api.Marketplace.BasketResponse & {
      errors?: Api.Marketplace.OrderUploadErrorItem[];
    }
  ): void {
    this.basketResponse = responseData;
    this.errors = responseData.errors ?? [];
    this.step = 'confirm';
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected entryExceedsSoftLimit(entry: Api.Marketplace.OrderUploadItem): boolean {
    return this.riskLimitValidator.isGreaterThanOrderSoftLimit(
      entry.equity.lastClosePrice.mul(new Decimal(entry.quantity))
    );
  }
}
</script>
