<template>
  <snapshot-manager
    :drivers="['sessionStorage']"
    :get-state-mutators="getStateMutators"
    :snapshot="snapshot"
    storage-key="snapshot-loans-explorer"
  >
    <div ref="containerRef" class="d-flex fill-height full-width">
      <div
        ref="leftPanelRef"
        :class="['d-flex flex-column fill-height pa-0 col']"
        :style="{ width: sizes.leftPanelWidth + 'px' }"
      >
        <dashboard-panel
          ref="openPositionsAggRef"
          :is-collapsed.sync="isAggCollapsed"
          :no-collapse="!selectedAgg && !detailLoan"
          :style="{ height: sizes.aggPanelHeight + 'px' }"
          title="Open positions aggregate"
        >
          <open-loans-list-with-aggregate
            :page.sync="aggPage"
            :page-size.sync="aggPageSize"
            :sort.sync="aggSort"
            :tab.sync="selectedAggTab"
            @row-clicked="handleAggRowClick"
          />
        </dashboard-panel>
        <div
          :class="[
            'resize-handle-h',
            { 'd-none': selectedAgg === null || isOpenLoansCollapsed || isAggCollapsed },
          ]"
          @mousedown="startResizeH"
        />
        <dashboard-panel
          v-if="selectedAgg !== null"
          ref="openPositionsRef"
          :is-collapsed.sync="isOpenLoansCollapsed"
          :no-collapse="false"
          :show-close-icon="true"
          :style="{ height: sizes.openLoansPanelHeight + 'px' }"
          :title="openPositionsDashBoardTitle"
          @close="handleOpenLoansClose"
        >
          <open-loans-list
            :counterparty.sync="counterparty"
            :detail-loan.sync="detailLoan"
            :detail-loan-initial-tab.sync="detailLoanInitialTab"
            :equity.sync="equity"
            :is-recalled.sync="isRecalled"
            :is-renegotiating.sync="isRenegotiating"
            :omit-headers="[
              'lenderDisplay',
              'borrowerDisplay',
              'displayId',
              'dtccRefId',
              'independentAmount',
              'yesterdayIndependentAmount',
            ]"
            :page.sync="page"
            :page-size.sync="pageSize"
            :show-all="false"
            :show-table-filters="false"
            :side.sync="selectedAgg.side"
            :sort.sync="sort"
            :tab.sync="selectedOpenLoansTab"
          />
        </dashboard-panel>
      </div>
      <div :class="['resize-handle-w', { 'd-none': !detailLoan }]" @mousedown="startResizeW" />
      <div
        v-if="detailLoan"
        ref="rightPanelRef"
        class="d-flex flex-column fill-height pa-0 flex-1"
        :style="{ width: sizes.rightPanelWidth + 'px' }"
      >
        <dashboard-panel
          no-collapse
          :show-close-icon="true"
          title="Loan Details"
          @close="handleDetailsClose"
        >
          <loan-details-card
            :as-broker="false"
            :initial-tab="detailLoanInitialTab"
            :loan-id="detailLoan.id"
          />
        </dashboard-panel>
      </div>
    </div>
  </snapshot-manager>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansListWithAggregate from '@/modules/open-loans/components/OpenLoansListWithAggregate.vue';
import OpenLoansList from '@/modules/open-loans/components/OpenLoansList.vue';
import LoanDetailsCard from '@/modules/open-loans/components/LoanDetailsCard.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import {
  AggregatedLoanByCounterpartyItem,
  AggregatedLoanBySecurityItem,
  OpenLoan,
} from '@/utils/api/loans';
import { Equity } from '@/modules/common/types/api';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { Watch } from 'vue-property-decorator';
import { SortModelItem } from 'ag-grid-enterprise';

const MAX_ITEMS_PER_PAGE = 1000;

const DEFAULT_SIZES = {
  // height values don't reflect the rendered height (because of "flex-grow")
  aggPanelHeight: 200,
  openLoansPanelHeight: 200,
  leftPanelWidth: 540,
  rightPanelWidth: 540,
};

const MIN_COL_WIDTH = 400;
const MIN_ROW_HEIGHT = 200;

@Component({
  provide() {
    return {
      gridStateKey: 'LoansExplorer',
    };
  },
  components: {
    DashboardPanel,
    OpenLoansListWithAggregate,
    OpenLoansList,
    LoanDetailsCard,
    SnapshotManager,
  },
})
export default class LoansExplorer extends Vue {
  public $refs!: {
    containerRef: HTMLElement;
    leftPanelRef: HTMLElement;
    rightPanelRef: HTMLElement;
    openPositionsAggRef: Vue;
    openPositionsRef: Vue;
  };
  // L1
  protected isAggCollapsed = false;
  protected aggPage = 1;
  protected aggSort: SortModelItem = { colId: 'ticker', sort: 'asc' };
  protected aggPageSize = MAX_ITEMS_PER_PAGE;
  protected selectedAggTab: 'security' | 'counterparty' = 'security';
  protected selectedAgg: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem | null =
    null;

  // L2
  protected isOpenLoansCollapsed = false;
  protected selectedOpenLoansTab: 'all' | 'recalled' | 'rerate' = 'all';
  protected sort: SortModelItem = { colId: 'createdAt', sort: 'desc' };
  protected page = 1;
  protected pageSize = MAX_ITEMS_PER_PAGE;
  protected isRecalled = false;
  protected isRenegotiating = false;
  protected equity: Equity | null = null;
  protected counterparty: null | CompanyInfo = null;

  // L3
  protected detailLoan: OpenLoan | null = null;
  protected detailLoanInitialTab = 'history';
  protected startY = 0;
  protected startX = 0;

  protected sizes = { ...DEFAULT_SIZES };
  // for performance resons (we don't want to JSON.stringify every time the mouse moves)
  // we have a separate object that we manualy update when resizing stops
  // (the snapshot getter observes this var)
  protected storedSizes = { ...DEFAULT_SIZES };

  // detect if the user has moved the window to a different screen
  protected currentScreenX: number = window.screenX;

  protected get openPositionsDashBoardTitle(): string | null {
    if (this.selectedAgg === null) {
      return null;
    } else if ('counterparty' in this.selectedAgg) {
      return `Open Positions > Counterparty > ${this.selectedAgg.counterparty.companyName}`;
    } else {
      return `Open Positions > Security > ${this.selectedAgg.ticker}`;
    }
  }

  protected get snapshot(): Record<string, string> {
    return {
      sizes: JSON.stringify(this.storedSizes),

      payload: JSON.stringify({
        // L1
        isAggCollapsed: this.isAggCollapsed,
        aggSort: this.aggSort,
        aggPage: this.aggSort,
        aggPageSize: this.aggPageSize ? this.aggPageSize.toString() : MAX_ITEMS_PER_PAGE.toString(),
        selectedAggTab: this.selectedAggTab,
        selectedAgg: this.selectedAgg,

        // L2
        isOpenLoansCollapsed: this.isOpenLoansCollapsed,
        sort: this.sort,
        page: this.page,
        pageSize: this.pageSize ? this.pageSize.toString() : MAX_ITEMS_PER_PAGE.toString(),
        isRecalled: this.isRecalled,
        isRenegotiating: this.isRenegotiating,
        selectedOpenLoansTab: this.selectedOpenLoansTab,
        equity: this.equity,
        counterparty: this.counterparty,

        // L3
        detailLoan: this.detailLoan,
        detailLoanInitialTab: this.detailLoanInitialTab,
      }),
    };
  }

  // if we select a new aggregate while open loans panel is collapsed
  // it will re-open the open loans panel
  @Watch('selectedAgg')
  protected handleSelectedAggChange(
    newValue: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem | null,
    oldValue: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem | null
  ): void {
    if (this.isOpenLoansCollapsed === true && newValue !== oldValue && oldValue !== null) {
      this.isOpenLoansCollapsed = false;
    }
  }

  protected mounted(): void {
    // because the snapshot getter is triggered before mounted,
    // it overrides what's currently in sessionStorage with the default values
    // save it again with what we initially got from sessionStorage before mount
    // (i.e retrigger the getter with the correct values)
    this.storedSizes = { ...this.sizes };
    window.addEventListener('resize', this.handleScreenChange);
  }

  protected beforeDestroy(): void {
    window.removeEventListener('resize', this.handleScreenChange);
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      sizes: (value) => {
        const storedSizes = JSON.parse(value);
        this.sizes = storedSizes;
      },
      payload: (value) => {
        const payload = JSON.parse(value);
        for (const key in payload) {
          const toNumberFields = ['page', 'pageSize', 'aggPage', 'aggPageSize'];
          if (toNumberFields.includes(key)) {
            this[key] = parseInt(payload[key], 10);
          } else {
            this[key] = payload[key];
          }
        }
      },
    };
  }

  protected handleAggRowClick({
    data,
  }: {
    data: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem;
  }): void {
    const item = data;
    this.selectedAgg = item;

    if ('counterparty' in item) {
      this.equity = null;
      this.counterparty = item.counterparty;
    } else {
      this.counterparty = null;
      this.equity = { cusip: item.cusip, ticker: item.ticker } as Equity;
    }
  }

  protected handleOpenLoansClose(): void {
    this.selectedAgg = null;
    this.isOpenLoansCollapsed = false;
    if (this.detailLoan === null) {
      this.isAggCollapsed = false;
    }
  }

  protected handleDetailsClose(): void {
    this.detailLoan = null;
    if (this.selectedAgg === null) {
      this.isAggCollapsed = false;
    }
  }

  protected startResizeW(event: MouseEvent): void {
    document.body.style.cursor = 'col-resize';
    this.startX = event.clientX;

    const onMouseMove = (e: MouseEvent) => {
      const deltaX = e.clientX - this.startX;

      // re-assign real width
      this.sizes.leftPanelWidth = this.$refs.leftPanelRef.clientWidth;
      this.sizes.rightPanelWidth = this.$refs.rightPanelRef.clientWidth;

      const shouldStopResizing =
        this.sizes.rightPanelWidth - deltaX >= MIN_COL_WIDTH &&
        this.sizes.leftPanelWidth + deltaX >= MIN_COL_WIDTH;

      if (shouldStopResizing) {
        this.sizes.leftPanelWidth += deltaX;
        this.sizes.rightPanelWidth -= deltaX;
        this.startX = e.clientX;
      }
    };

    const onMouseUp = () => {
      document.body.style.cursor = 'auto';
      this.storedSizes = { ...this.sizes };
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }

  protected startResizeH(event: MouseEvent): void {
    document.body.style.cursor = 'row-resize';
    this.startY = event.clientY;

    // because heights of both panel are re-calculated using flex-grow to auto-fill 50% of the height initially
    // therefor we need to get the calculated height post mount then add or remove from it when
    // resizing happens- otherwise the resizing will add or remove based on the defined height
    // causing a laggish - effect
    let initialHeightUpper = this.$refs.openPositionsAggRef.$el.clientHeight;
    let initialHeightLower = this.$refs.openPositionsRef.$el.clientHeight;

    const onMouseMove = (e: MouseEvent): void => {
      const deltaY = e.clientY - this.startY;

      // re-assign real height
      this.sizes.aggPanelHeight = initialHeightUpper;
      this.sizes.openLoansPanelHeight = initialHeightLower;

      // prevent resizing the divs further than the minimum
      const shouldResize =
        initialHeightUpper + deltaY >= MIN_ROW_HEIGHT &&
        initialHeightLower - deltaY >= MIN_ROW_HEIGHT;

      if (shouldResize) {
        initialHeightUpper += deltaY;
        this.sizes.aggPanelHeight = initialHeightUpper;

        initialHeightLower -= deltaY;
        this.sizes.openLoansPanelHeight = initialHeightLower;
        this.startY = e.clientY;
      }
    };

    const onMouseUp = (): void => {
      document.body.style.cursor = 'auto';
      this.storedSizes = { ...this.sizes };
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }

  protected handleScreenChange(): void {
    if (window.screenX !== this.currentScreenX) {
      // because our users might have multiple monitors
      // we need to reset the panels widths and heights to inital values
      this.sizes = { ...DEFAULT_SIZES };
      this.storedSizes = { ...DEFAULT_SIZES };
    }
  }
}
</script>

<style scoped lang="scss">
.resize-handle-h {
  height: 4px;
  width: calc(100% - 8px);
  margin: 0 auto;
  text-align: center;
  border: 2px solid transparent;
  user-select: none;
  border-radius: 4px 4px;
  cursor: row-resize;
  &:hover {
    background: #ebeced;
    opacity: 0.1;
  }
}
.resize-handle-w {
  user-select: none;
  width: 5px;
  border: 2px solid transparent;
  cursor: col-resize;
  user-select: none;
  height: calc(100% - 16px);
  margin-top: 8px;
  &:hover {
    background: #ebeced;
    opacity: 0.1;
  }
}
</style>
