import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { BrokerOpenLoan } from '@/utils/api/broker';
import { Benchmark } from '@/utils/api/loans';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';

const zero = new Decimal(0);

export interface Agg {
  sum: AggregateSum;
  avg: AggregateAvg;
  noun: AggregateNoun;
  selected: 'avg' | 'sum';
  nounOrQuantity: (name: string) => string;
}

interface AggregateSum {
  // total/avg quantities
  openQuantity: number;
  recalledQuantity: number;
  returnedQuantity: number;
  returnedQuantityToday: number;
  renegotiateQuantity: number; // only loans under renegotiation!
  contractAmount: Decimal;
  settlementAmount: Decimal;
  independentAmount: Decimal;
  yesterdayIndependentAmount: Decimal;
}

interface AggregateAvg extends AggregateSum {
  // weighted avg rates
  rate: Decimal;
  rateModifiers: Benchmark[];

  // weighted avg rates under renegotiation
  renegotiateRate: Decimal | null;
  renegotiateRateModifiers: Benchmark[];
}

interface AggregateNoun {
  sides: Array<'LENDER' | 'BORROWER'>;
  parties: string[];
  lenders: string[];
  borrowers: string[];
  settlementType: string[];
  ticker: string[];
  cusip: string[];
}

const initialSum: AggregateSum = {
  openQuantity: 0,
  recalledQuantity: 0,
  returnedQuantity: 0,
  returnedQuantityToday: 0,
  renegotiateQuantity: 0,
  contractAmount: new Decimal(0),
  settlementAmount: new Decimal(0),
  independentAmount: new Decimal(0),
  yesterdayIndependentAmount: new Decimal(0),
};

const initialAvg: AggregateAvg = {
  ...initialSum,
  rate: zero,
  rateModifiers: [],
  renegotiateRate: null,
  renegotiateRateModifiers: [],
};

const initialNoun: AggregateNoun = {
  sides: [],
  parties: [],
  lenders: [],
  borrowers: [],
  settlementType: [],
  ticker: [],
  cusip: [],
};

// only push if item is not already in array
function pushIfUnique<T>(arr: T[], item: T) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

export function getAggLoans(
  loans: Array<OpenLoanItem | BrokerOpenLoan>,
  selected: 'sum' | 'avg'
): Agg {
  const sum = cloneDeep(initialSum);
  const avg = cloneDeep(initialAvg);
  const noun = cloneDeep(initialNoun);

  let cntOpenQuantity = 0;
  let cntRenegotiateQuantity = 0;
  let sumRates = zero;
  let sumRenegotiateRates = zero;

  loans.forEach((loan: OpenLoanItem | BrokerOpenLoan) => {
    sum.openQuantity += loan.openQuantity;
    sum.recalledQuantity += loan.recalledQuantity;
    sum.returnedQuantity += loan.returnedQuantity;
    sum.returnedQuantityToday += loan.returnedQuantityToday;
    sum.contractAmount = sum.contractAmount.add(loan.contractAmount);
    sum.settlementAmount = sum.settlementAmount.add(loan.settlementAmount);

    if ('independentAmount' in loan) {
      sum.independentAmount = sum.independentAmount.add(loan.independentAmount);
    }
    if ('yesterdayIndependentAmount' in loan) {
      sum.yesterdayIndependentAmount = sum.yesterdayIndependentAmount.add(
        loan.yesterdayIndependentAmount
      );
    }

    cntOpenQuantity++;

    pushIfUnique(noun.settlementType, loan.settlementType);

    // loan can be either BrokerOpenLoan or OpenLoanItem
    // depending on that push the respective nouns
    if ('side' in loan) {
      pushIfUnique(noun.sides, loan.side);
      pushIfUnique(noun.parties, loan.counterpartyDisplay);
    } else {
      pushIfUnique(noun.lenders, loan.lenderDisplay);
      pushIfUnique(noun.borrowers, loan.borrowerDisplay);
    }
    pushIfUnique(noun.ticker, loan.equity.ticker);
    pushIfUnique(noun.cusip, loan.equity.cusip);
    pushIfUnique(avg.rateModifiers, loan.rateModifier);

    sumRates = sumRates.add(loan.rate.mul(loan.openQuantity));

    if (loan.renegotiation !== null) {
      pushIfUnique(avg.renegotiateRateModifiers, loan.renegotiation.rateModifier);
      sumRenegotiateRates = sumRenegotiateRates.add(loan.renegotiation.rate.mul(loan.openQuantity));
      sum.renegotiateQuantity += loan.openQuantity;
      cntRenegotiateQuantity++;
    }
  });

  avg.openQuantity = cntOpenQuantity ? Math.round(sum.openQuantity / cntOpenQuantity) : 0;
  avg.recalledQuantity = cntOpenQuantity ? Math.round(sum.recalledQuantity / cntOpenQuantity) : 0;
  avg.returnedQuantity = cntOpenQuantity ? Math.round(sum.returnedQuantity / cntOpenQuantity) : 0;
  avg.returnedQuantityToday = cntOpenQuantity
    ? Math.round(sum.returnedQuantityToday / cntOpenQuantity)
    : 0;

  avg.renegotiateQuantity = cntRenegotiateQuantity
    ? Math.round(sum.renegotiateQuantity / cntRenegotiateQuantity)
    : 0;
  avg.contractAmount = cntOpenQuantity ? sum.contractAmount.div(cntOpenQuantity) : new Decimal(0);
  avg.settlementAmount = cntOpenQuantity
    ? sum.settlementAmount.div(cntOpenQuantity)
    : new Decimal(0);
  avg.independentAmount = cntOpenQuantity
    ? sum.independentAmount.div(cntOpenQuantity)
    : new Decimal(0);
  avg.yesterdayIndependentAmount = cntOpenQuantity
    ? sum.yesterdayIndependentAmount.div(cntOpenQuantity)
    : new Decimal(0);

  // NOTE: we only display the average if the `rateModifier` is the same for all selected loans
  avg.rate = sum.openQuantity ? sumRates.div(sum.openQuantity) : zero;

  // NOTE: we only display the average if the `renegotiateRateModifier` is the same for all selected loans
  avg.renegotiateRate = sum.renegotiateQuantity
    ? sumRenegotiateRates.div(sum.renegotiateQuantity)
    : null;

  return {
    sum,
    avg,
    noun,
    selected,
    nounOrQuantity: (name: string) => {
      return noun[name].length === 1 ? noun[name][0] : `(${noun[name].length})`;
    },
  };
}
