<template>
  <div>
    <dashboard-panel class="filters-panel" no-collapse title="Statistics">
      <div class="pa-4">
        <v-row>
          <!-- Grouping -->
          <v-col class="col-lg-2 col-4">
            <v-row>
              <v-col class="pb-0">
                <h4>Group By</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="groupBy"
                  dense
                  :items="groupByOptions"
                  label="Main Group"
                  @change="onChangeGroupBy"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-if="groupBy === 'company' || company !== 0"
                  v-model="groupBy2"
                  dense
                  :items="groupBy2Options"
                  label="Subgroup"
                  @change="onChangeGroupBy2"
                />
              </v-col>
            </v-row>
          </v-col>

          <!-- Filters -->
          <v-col class="pl-6">
            <v-row>
              <v-col class="pb-0">
                <h4>Filter</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="from"
                  dense
                  :items="fromOptions"
                  label="From"
                  @change="onChangeTimeFilter('from')"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="to"
                  dense
                  :items="toOptions"
                  label="To"
                  @change="onChangeTimeFilter('to')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="company"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="companyOptions"
                  label="Company"
                  @change="fetchData"
                />
              </v-col>
              <v-col v-if="groupBy === 'company' || company > 0">
                <v-select
                  v-model="side"
                  dense
                  hide-details
                  :items="sideOptions"
                  label="Side"
                  @change="fetchData"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="feeTier"
                  dense
                  hide-details
                  item-text="label"
                  item-value="feeTier"
                  :items="feeTierOptions"
                  label="Fee Tier"
                  @change="fetchData"
                />
              </v-col>
              <v-col cols="3">
                <simple-equity-search
                  v-model="instrument"
                  dense
                  label="security"
                  @change="fetchData"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </dashboard-panel>

    <v-alert v-if="!isDataAvailable" class="mt-4" color="primary" dense outlined type="warning">
      There is no data available with the selected date range/filters combination.
    </v-alert>

    <dashboard-panel v-if="isDataAvailable" class="chart-panel">
      <!-- wrap the chart in a <div> to keep it from crashing -->
      <div class="chart-box">
        <v-chart autoresize class="chart" :option="chartOptions" />
      </div>
    </dashboard-panel>

    <dashboard-panel v-if="isDataAvailable" class="data-panel" title="">
      <v-data-table
        class="d-flex flex flex-column"
        dense
        disable-pagination
        fixed-header
        :headers="tableColumns"
        height="100%"
        hide-default-footer
        :items="rows"
        :no-data-text="$tc('no data')"
        sort-by="date"
        sort-desc
      >
        <template #[`item.date`]="{ item }">
          <span>
            {{ formattedDate(item.date) }}
          </span>
        </template>
        <template #[`item.side`]="{ item }">
          <span>{{ item.side }}</span>
        </template>
      </v-data-table>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { addMonths, add as dateAdd, eachMonthOfInterval, parse as parseDate } from 'date-fns';

import i18n from '@/localisation/i18n';
import axios from 'axios';
import Decimal from 'decimal.js';
import { find, flatten, isNumber, uniq } from 'lodash';

import { DataTableHeader } from 'vuetify';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import { formatDate } from '@/utils/helpers/dates';
import { Api } from '@/modules/broker-admin/types/statistics';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { Equity } from '@/modules/common/types/api';

// new
import VChart, { THEME_KEY } from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import { EChartsOption } from 'echarts';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
use([
  CanvasRenderer,
  LineChart,
  ToolboxComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

interface VSelectOption {
  text: string;
  value: string;
  disabled?: boolean;
}

interface FeeTierOption {
  feeTier: string;
  label: string;
}

const prettyNotional = (value: Decimal | number) => getPriceAsString(value, PRICE_PRECISION);

const feeTierOptions: FeeTierOption[] = [
  { feeTier: '', label: ' - All - ' },
  { feeTier: '<1', label: 'rate < GC +1%' },
  { feeTier: '>=1', label: 'rate >= GC +1%' },
  { feeTier: '>=5', label: 'rate >= GC +5%' },
  { feeTier: '>=10', label: 'rate >= GC +10%' },
];

const sideOptions: VSelectOption[] = [
  { value: '', text: ' - All - ' },
  { value: 'BORROWER', text: 'Borrower' },
  { value: 'LENDER', text: 'Lender' },
];

const toFromDateFormat = 'yyyy-MM';
const toLabelDateFormat = 'dd/MMM';

@Component({
  components: {
    DashboardPanel,
    SimpleEquitySearch,
    VChart,
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
})
export default class BrokerStats extends Vue {
  protected groupBy = 'feeTier';
  protected groupByOptions: VSelectOption[] = [
    { value: 'feeTier', text: 'Fee Tier' },
    { value: 'company', text: 'Company' },
    { value: 'instrument', text: 'Security' },
  ];
  protected groupBy2 = '';
  protected groupBy2Options: VSelectOption[] = [
    { value: '', text: '' },
    { value: 'side', text: 'Side' },
  ];

  protected from = '';
  protected fromOptions: string[] = [];

  protected to = '';
  protected toOptions: string[] = [];

  protected lastChangedTimeFilter: 'to' | 'from' = 'to';

  protected company = '';
  protected companyOptions: Array<{ id: string; name: string }> = [{ id: '', name: ' - All - ' }];

  protected instrument: Equity | null = null;

  protected side = '';
  protected sideOptions: VSelectOption[] = sideOptions;

  protected feeTier = '';
  protected feeTierOptions: FeeTierOption[] = feeTierOptions;

  // series fetched from API
  protected series: Api.StatsSeries[] | null = null;
  protected headers: Array<[string, string]> = [];
  protected rows: Api.StatsTableRow[] = [];

  // dynamic table columns based on which groupBy values are selected
  protected get tableColumns(): DataTableHeader[] {
    return [
      {
        value: 'date',
        text: i18n.t('date') as string,
        class: 'text-no-wrap text-truncate',
        align: 'start' as const,
      },

      // display the side column if we're filtering on the company
      ...(this.company != '' || this.groupBy2 === 'side'
        ? [
            {
              value: 'side',
              text: i18n.t('side') as string,
              class: 'text-no-wrap text-truncate',
              align: 'start' as const,
            },
          ]
        : []),

      // display a column for each group by value
      ...this.headers.map(([headerKey, headerValue]) => ({
        value: 'values.' + headerKey, // each row has a values = {} with a notional per header
        text: this.seriesName(headerValue),
        class: 'text-no-wrap text-truncate',
        align: 'end' as const,
      })),
    ];
  }

  protected get isDataAvailable(): boolean {
    return this.series !== null && this.series.length > 0;
  }

  protected get chartOptions(): EChartsOption {
    return {
      backgroundColor: '#272727',
      title: {
        text: '',
      },
      legend: {
        data: this.series ? this.series.map((series) => this.seriesName(series.name)) : [],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        valueFormatter: (value) => (isNumber(value) ? prettyNotional(value) : `${value}`),
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        data: this.series
          ? uniq(
              flatten(
                this.series.map((series) =>
                  series.data.map((row) => formatDate(row.date, toLabelDateFormat))
                )
              )
            )
          : [],
      },
      yAxis: {
        type: 'value',
        name: 'notional',
        nameLocation: 'middle',
        axisLabel: {
          formatter: function (value, _index) {
            return formatPrettyNumber(value, { useGrouping: true, notation: 'compact' });
          },
        },
        nameTextStyle: { color: '#fff', padding: 30 },
      },
      series: this.series
        ? this.series.map((series) => ({
            type: 'line',
            name: this.seriesName(series.name),
            data: series.data.map((row) => row.notional.toNumber()),
          }))
        : [],
    };
  }

  protected get params(): Record<string, string | number | null> {
    return {
      groupBy:
        this.groupBy && this.groupBy2 ? `${this.groupBy},${this.groupBy2}` : `${this.groupBy}`,
      from: this.from,
      to: this.to,
      company: this.company ? this.company : null,
      instrument: this.instrument ? this.instrument.id : null,
      feeTier: this.feeTier ? this.feeTier : null,
      side: this.side ? this.side : null,
    };
  }

  protected async mounted(): Promise<void> {
    this.$log.debug(`stats mounted`);
    this.fromOptions = eachMonthOfInterval({
      start: dateAdd(new Date(), { months: -12 }),
      end: new Date(),
    })
      .map((d) => formatDate(d, toFromDateFormat))
      .reverse();
    this.toOptions = eachMonthOfInterval({
      start: dateAdd(new Date(), { months: -12 }),
      end: dateAdd(new Date(), { months: 1 }),
    })
      .map((d) => formatDate(d, toFromDateFormat))
      .reverse();
    // use the first option for from and to fields
    this.from = this.fromOptions[1];
    this.to = this.toOptions[0];

    // load the available filters from the backend
    await this.loadFilters();

    // fetch data for current filters
    await this.fetchData();
  }

  protected onChangeTimeFilter(value: 'to' | 'from'): void {
    this.lastChangedTimeFilter = value;
    this.fixTimeFilter();
    void this.fetchData();
  }

  protected onChangeGroupBy(): void {
    this.groupBy2 = '';
    switch (this.groupBy) {
      case 'feeTier':
        this.feeTier = '';
        break;
      case 'instrument':
        this.instrument = null;
        break;
      case 'company':
        this.company = '';
        this.side = '';
        break;
    }

    void this.fetchData();
  }

  protected onChangeGroupBy2(): void {
    switch (this.groupBy2) {
      case 'side':
        this.side = '';
        break;
    }

    void this.fetchData();
  }

  protected async fetchData(): Promise<void> {
    // fetch first so both requests can run in parallel and then we await later
    const series = this.getAllSeries();
    const data = this.getTableData();

    this.series = await series;
    this.rows = (await data).rows;
    this.headers = (await data).headers;
  }

  protected async getAllSeries(): Promise<Api.StatsSeries[] | null> {
    this.$log.debug(`stats/series`, this.params);

    const { data } = await axios.get<{ series: Api.StatsSeries[] | null }>(
      `/api/1/broker-user/stats/series`,
      {
        params: this.params,
      }
    );

    if (!data.series) {
      return null;
    }

    data.series.map((series) => {
      series.data.forEach((row) => {
        // input from server will have notional as a string, we convert it to Decimal
        const rowWithStrNotional: { notional: string | Decimal } = row;
        if (typeof rowWithStrNotional.notional === 'string') {
          row.notional = new Decimal(rowWithStrNotional.notional);
        }

        // input from server will have date as a string, we convert it to Date
        const rowWithStrDate: { date: string | Date } = row;
        if (typeof rowWithStrDate.date === 'string') {
          row.date = new Date(rowWithStrDate.date);
        }
      });
    });

    return data.series;
  }

  protected async getTableData(): Promise<Api.StatsTableData> {
    this.$log.debug(`stats/table`, this.params);

    const { data } = await axios.get<Api.StatsTableData>(`/api/1/broker-user/stats/table`, {
      params: this.params,
    });

    data.rows.map((row) => {
      // input from server will have date as a string, we convert it to Date
      const rowWithStrDate: { date: string | Date } = row;
      if (typeof rowWithStrDate.date === 'string') {
        row.date = new Date(rowWithStrDate.date);
      }

      Object.keys(row.values).forEach((key) => {
        // input from server will have notional as a string, we convert it to Decimal
        //  and then prettify
        row.values[key] = prettyNotional(new Decimal(row.values[key]));
      });
    });

    return data;
  }

  protected async loadFilters(): Promise<void> {
    const { data } = await axios.get<Api.StatsFilters>(`/api/1/broker-user/stats/filters`);

    this.companyOptions = [{ id: '', name: '- All -' }].concat(data.companies);
  }

  protected seriesName(rawName: string): string {
    // series name is `key=value`, but value can also contain `>=<`, so we split it on the `=`
    const [k] = rawName.split('=', 1);
    // and then take the remainder as value
    const v = rawName.substr(k.length + 1);

    switch (k) {
      case 'side':
        return v;
      case 'company':
        return this.displayCompany(v);
      case 'instrument':
        return this.displayInstrument(v);
      default:
        return v;
    }
  }

  protected displayInstrument(ticker: string): string {
    return ticker;
  }

  protected displayCompany(companyId: string): string {
    const company = find(this.companyOptions, (i) => i.id.toString() == companyId);
    return company ? company.name : `company:${companyId}`;
  }

  // ensure that from is never equal or before to by adjusting either one of them
  protected fixTimeFilter(): void {
    const to = parseDate(this.to, toFromDateFormat, new Date());
    const from = parseDate(this.from, toFromDateFormat, new Date());

    if (to.getTime() === from.getTime() || to.getTime() < from.getTime()) {
      switch (this.lastChangedTimeFilter) {
        case 'to':
          this.from = formatDate(addMonths(to, -1), toFromDateFormat);
          break;
        case 'from':
          this.to = formatDate(addMonths(from, 1), toFromDateFormat);
          break;
      }
    }
  }

  protected formattedDate(date: Date): string {
    return formatDate(date, 'yyyy/MM/dd');
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .filters-panel {
    max-height: 14rem;

    // fix for above max-height being more specific
    &.is-collapsed {
      max-height: 2.7rem;
    }
  }

  .chart-panel {
    height: 360px;
    max-height: 360px;

    .panel-content {
      overflow: hidden;
    }

    .chart-box {
      .chart {
        height: 320px;
      }
    }
  }

  .data-panel {
    height: 400px;
  }

  table {
    thead th {
      text-transform: capitalize;
    }
  }
}
</style>
