<template>
  <reports>
    <report
      data-test-id="report-open-positions"
      date-filter-incl-today-from="10:00"
      :sources="[
        {
          url: '/api/1/reports/company-open-loans/export',
          defaultName: 'report_open_positions.csv',
        },
      ]"
      title="Open Positions"
      with-date-filter
    >
      A report showing details of open positions per day.
    </report>
    <report
      data-test-id="report-counterparty-contract-balance"
      date-filter-incl-today-from="00:00"
      :sources="[
        {
          url: '/api/1/reports/company-counterparty-contract-balance-pdf/export',
          defaultName: 'report_counterparty_contract_balance.pdf',
        },
        {
          url: '/api/1/reports/company-counterparty-contract-balance/export',
          defaultName: 'report_counterparty_contract_balance.csv',
        },
      ]"
      title="SFT Counterparty Contract Balance"
      with-date-filter
    >
      A report showing counterparty balances per side for current open positions.
    </report>
    <report
      data-test-id="report-bps-intact"
      :sources="[
        {
          url: '/api/1/reports/company-bps-intact/export',
          defaultName: 'bps_intact.csv',
        },
      ]"
      title="BPS INTACT"
      with-date-filter
      with-pretty-option
    >
      A report with loan activity in BPS INTACT format.
    </report>
    <report
      data-test-id="report-bps-ib"
      date-filter-incl-today-from="10:00"
      :sources="[
        {
          url: '/api/1/reports/company-security-grouped-balance/export',
          defaultName: 'report_security_grouped_balance.csv',
        },
        {
          url: '/api/1/reports/company-security-grouped-balance-pdf/export',
          defaultName: 'report_daily_security_grouped_balance.pdf',
        },
      ]"
      title="Daily Income Analysis"
      with-date-filter
    >
      A report showing security grouped balances.
    </report>
    <report
      data-test-id="report-monthly-income-analysis"
      date-filter-incl-today-from="10:00"
      :sources="[
        {
          url: '/api/1/reports/company-security-grouped-balance-month-to-date/export',
          defaultName: 'report_security_grouped_balance_month_to_date.csv',
        },
        {
          url: '/api/1/reports/company-security-grouped-balance-month-to-date-pdf/export',
          defaultName: 'report_monthly_security_grouped_balance.pdf',
        },
      ]"
      title="Monthly Income Analysis"
      with-date-filter
    >
      A report showing month to date security grouped balances.
    </report>
    <report
      data-test-id="report-estimated-pnl"
      date-filter-incl-today-from="10:00"
      :sources="[
        {
          url: '/api/1/reports/company-open-loans/export?pnl=true',
          defaultName: 'report_estimated_pnl.csv',
        },
      ]"
      title="Estimated PnL Report"
      url="/api/1/reports/company-open-loans/export?pnl=true"
      with-date-filter
    >
      A report approximating total PnL on open positions considering reinvestment opportunities and
      rebate income (loss).
    </report>
    <report
      data-test-id="report-benchmark-rate-history"
      :sources="[
        {
          url: '/api/1/reports/company-benchmark-rates/export',
          defaultName: 'report_benchmark_rate_history.csv',
        },
      ]"
      title="Benchmark Rate History"
    >
      A report showing the benchmark rate used for the company’s PnL calculations overtime;
      defaulted to OBFR unless overridden by a company user.
    </report>
    <report
      data-test-id="report-position-movement-activity"
      date-filter-incl-today-from="00:00"
      :sources="[
        {
          url: '/api/1/reports/company-activity/export',
          defaultName: 'report_position_movement_activity.csv',
        },
      ]"
      title="Position Movement Activity"
      with-date-filter
    >
      A report showing daily activity that impacts the shares outstanding; excludes rerates,
      mark-to-market, etc.
    </report>
    <report
      data-test-id="report-mark-to-market"
      date-filter-incl-today-from="21:00"
      :sources="[
        {
          url: '/api/1/reports/company-mark-to-market/export',
          defaultName: 'report_mark_to_market.csv',
        },
      ]"
      title="Mark-to-Market"
      with-date-filter
    >
      A report showing the effect of marking the position to the current value vs the prior day’s
      value.
    </report>
    <report
      data-test-id="report-monthly-billing"
      date-filter-incl-non-workdays
      date-filter-incl-today-from="10:00"
      :sources="[
        {
          url: '/api/1/reports/company-billing/export',
          defaultName: 'report_billing.csv',
        },
      ]"
      title="Monthly Billing"
      with-date-filter
    >
      A report showing month to date billing.
    </report>
    <report
      data-test-id="report-dividend-activity"
      date-filter-incl-today-from="18:05"
      :sources="[
        {
          url: '/api/1/reports/company-dividend-activity/export',
          defaultName: 'dividend_activity_report.csv',
        },
      ]"
      title="Dividend Activity Report"
      with-date-filter
    >
      A report showing dividend activity.
    </report>
  </reports>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Reports from '@/modules/reports/components/Reports.vue';
import Report from '@/modules/reports/components/Report.vue';

@Component({
  components: {
    Reports,
    Report,
  },
})
export default class ReportsHome extends Vue {}
</script>
