<template>
  <v-autocomplete
    auto-select-first
    :autofocus="autofocus"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    item-value="companyId"
    :items="allItems"
    :label="label"
    :loading="isLoading"
    no-filter
    :placeholder="placeholder || 'search...'"
    return-object
    :value="value"
    @blur="$emit('blur', $event)"
    @change="onChange"
    @input="onItemSelected"
    @update:search-input="search"
  >
    <template #selection="data">
      {{ formatCompanyName(data.item) }}
    </template>
    <template #item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ formatCompanyName(data.item) }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Side } from '@/modules/user-accounts/types/user-accounts';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';
import { Watch } from 'vue-property-decorator';

@Component({
  props: {
    value: Object as PropType<CompanyInfo>,
    multiple: Boolean,
    label: String,
    autofocus: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    dense: Boolean,
    errorMessages: {},
    placeholder: String,
    prependItem: {
      type: Object as PropType<CompanyInfo>,
    },
    // search through borrower traders, lender traders or both
    side: {
      type: String as PropType<Side>,
      default: 'ALL',
    },
    sponsored: {
      type: Boolean,
      default: false,
    },
    includeSponsored: {
      type: Boolean,
      default: false,
    },
    bilateral: {
      type: Boolean,
      default: false,
    },
    excludeCompanyId: {
      type: String,
      required: false,
    },
    excludeCompanyIds: {
      type: Array<string>,
      default: () => [],
    },
  },
})
export default class CounterpartySearch extends Vue {
  protected readonly value?: CompanyInfo | null;
  protected readonly label?: string;
  protected readonly autofocus?: boolean;
  protected readonly disabled?: boolean;
  protected readonly clearable?: boolean;
  protected readonly dense?: boolean;
  protected readonly errorMessages?: string[];
  protected readonly placeholder?: string;
  protected readonly side!: Side;
  protected readonly sponsored!: boolean;
  protected readonly includeSponsored!: boolean;
  protected readonly excludeCompanyId?: string;
  protected readonly excludeCompanyIds?: string[];
  protected readonly multiple!: boolean;
  protected readonly bilateral!: boolean;

  protected prependItem!: CompanyInfo;
  protected isLoading = false;
  protected items: CompanyInfo[] = [];

  protected get allItems(): CompanyInfo[] {
    return this.prependItem ? [this.prependItem, ...this.items] : this.items;
  }

  @Watch('side')
  @Watch('sponsored')
  @Watch('includeSponsored')
  @Watch('excludeCompanyId')
  @Watch('excludeCompanyIds')
  @Watch('bilateral')
  protected onParamsChange(): void {
    // reset the results when one of the flags that influence results change
    this.items = [];
  }

  @Watch('value')
  protected onPropChange(value: string): void {
    // when the parent component updates value, items might not have the value in them
    this.setItemsWithValueAsOnlyItem();

    if (!value) {
      this.items = [];
    }
  }

  protected mounted(): void {
    // items not loaded yet, set the value as the only item
    this.setItemsWithValueAsOnlyItem();
  }

  protected setItemsWithValueAsOnlyItem(): void {
    if (this.value && !this.multiple) {
      // if we are not in multiple mode, value is a single counterparty
      this.items = [this.value as CompanyInfo];
    }
  }

  protected onItemSelected(item: CompanyInfo): void {
    this.$emit('input', item);
  }

  protected onChange(item: CompanyInfo): void {
    this.$emit('change', item);
  }

  protected formatCompanyName(counterparty: CompanyInfo): string {
    return formatCompanyName(counterparty);
  }

  protected async search(query: string): Promise<void> {
    if (!query) {
      // no query, or item in list was selected
      return;
    }

    this.isLoading = true;
    try {
      let { data } = await this.$api.userAccounts.searchCounterparties(
        query,
        this.side,
        this.sponsored,
        this.bilateral,
        this.includeSponsored
      );

      if (this.excludeCompanyId) {
        data = data.filter(
          (counterparty: CompanyInfo) => counterparty.companyId !== this.excludeCompanyId
        );
      }
      if (this.excludeCompanyIds) {
        data = data.filter(
          (counterparty: CompanyInfo) =>
            this.excludeCompanyIds?.indexOf(counterparty.companyId) === -1
        );
      }

      this.items = data;
    } catch (err) {
      this.items = [];
    }
    this.isLoading = false;
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  white-space: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
