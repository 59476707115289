<template>
  <div class="actions d-flex justify-end">
    <marketplace-cancel-btn
      v-if="actions.includes('cancel') && order.status === 'OPEN'"
      data-test="cancel-order"
      icon
      :order-ref="order.orderRef"
      v-on="$listeners"
    />

    <aurora-btn
      v-if="actions.includes('edit') && order.status === 'OPEN'"
      class="icon-action"
      data-test="edit-order"
      :disabled="!hasTraderUserRole"
      icon
      style="margin-top: 5px"
      timeframe="createLoans"
      x-small
      @click="editOrder(order)"
    >
      <v-icon size="24">mdi-pencil</v-icon>
    </aurora-btn>

    <marketplace-toggle-route-btn
      v-if="actions.includes('route') && order.status === 'OPEN'"
      data-test="toggle-route-order"
      icon
      :order-ref="order.orderRef"
      :routing-status="order.routingStatus"
      v-on="$listeners"
    />

    <v-btn
      class="icon-action"
      data-test="view-order"
      icon
      style="margin-top: 4px"
      x-small
      @click="viewOrder(order.orderRef)"
    >
      <v-icon size="24">mdi-eye</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Api } from '@/modules/common/types/api';
import MarketplaceCancelBtn from '@/modules/marketplace/components/MarketplaceCancelBtn.vue';
import MarketplaceToggleRouteBtn from '@/modules/marketplace/components/MarketplaceToggleRouteBtn.vue';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

const allActions = ['view', 'edit', 'route', 'unroute', 'cancel'];

@Component({
  components: {
    MarketplaceToggleRouteBtn,
    MarketplaceCancelBtn,
  },
  computed: {
    ...mapGetters(['hasTraderUserRole']),
  },
  props: {
    order: {
      type: Object as PropType<Api.Marketplace.Order>,
      required: true,
    },
    actions: {
      type: Array as PropType<string[]>,
      default: () => allActions,
    },
    viewOrder: {
      type: Function as PropType<(orderRef: string) => void>,
      required: true,
    },
    editOrder: {
      type: Function as PropType<(order: Api.Marketplace.Order) => void>,
      required: true,
    },
  },
})
export default class MarketplaceOrdersTableActions extends Vue {
  // props
  protected readonly order!: Api.Marketplace.Order;
  protected readonly actions!: string[];
  protected readonly viewOrder!: (orderRef: string) => void;
  protected readonly editOrder!: (order: Api.Marketplace.Order) => void;

  // store state
  protected readonly hasTraderUserRole!: boolean;
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
  width: 100%;
}
</style>
