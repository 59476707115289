<template>
  <v-card class="flex d-flex flex-column">
    <loan-details-dialog
      v-if="detail"
      as-broker
      :initial-tab="detail.isCorpAction ? 'corporateActions' : 'history'"
      :loan-id="detail.loan.id"
      @close-modal="detail = null"
    />

    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="gap-1 d-flex align-center">
          <counterparty-search
            class="counterparties ml-8"
            clearable
            include-sponsored
            placeholder="All Lenders"
            side="LENDER"
            :value="lender"
            @change="$emit('update:lender', $event)"
          />
          <counterparty-search
            class="counterparties ml-8"
            clearable
            include-sponsored
            placeholder="All Borrowers"
            side="BORROWER"
            :value="borrower"
            @change="$emit('update:borrower', $event)"
          />
          <simple-equity-search
            class="ml-8"
            clearable
            label="Security"
            :value="equity"
            @input="$emit('update:equity', $event)"
          />
          <v-select
            class="status-list ml-8"
            clearable
            deletable-chips
            item-text="label"
            item-value="status"
            :items="statusItems"
            multiple
            placeholder="All Loan Statuses"
            small-chips
            :value="statuses"
            @change="$emit('update:statuses', $event)"
          />
          <v-text-field
            class="ml-8"
            clearable
            :label="$t('searchLoanId')"
            prepend-inner-icon="mdi-magnify"
            :value="displayId"
            @input="$emit('update:displayId', $event)"
          />
        </v-col>
      </v-row>
    </v-container>

    <open-loans-table
      class="mt-n4"
      data-test="open-loans-table"
      :omit-headers="omitHeaders"
      :page.sync="localPage"
      :page-size.sync="localPageSize"
      :query-data="queryData"
      :selected-items.sync="selectedItems"
      :show-dropdown-actions="false"
      :show-select="false"
      :sort.sync="localSort"
      @ready="onReady"
      @view-loan="handleViewLoan"
    />
  </v-card>
</template>

<script lang="ts">
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import { BrokerOpenLoan } from '@/utils/api/broker';
import { BrokerOpenLoansRequest, LoanStatus, loanStatuses } from '@/utils/api/loans';
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import { LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';

function stubFn() {
  return;
}

@Component({
  components: {
    LoanStatusChip,
    OpenLoansTable,
    CounterpartySearch,
    SimpleEquitySearch,
    LoanDetailsDialog,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    sort: {
      type: Object as PropType<SortModelItem>,
      required: true,
    },
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    equity: {
      type: null as unknown as PropType<Equity | null>,
      validator: (value: Equity | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    lender: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    borrower: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    statuses: {
      type: null as unknown as PropType<LoanStatus[] | null>,
      validator: (value: LoanStatus[] | null): boolean => {
        return value === null || value instanceof Array;
      },
      required: true,
    },
    displayId: {
      type: null as unknown as PropType<string | null>,
      validator: (value: string | null): boolean => {
        return value === null || true;
      },
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'socketEvents']),
  },
})
export default class BrokerOpenLoansList extends Vue {
  // props
  protected readonly page!: number;
  protected readonly pageSize!: number;
  protected readonly sort!: SortModelItem;
  protected readonly omitHeaders!: string[];
  protected readonly equity!: Equity | null;
  protected readonly lender!: null | CompanyInfo;
  protected readonly borrower!: null | CompanyInfo;
  protected readonly statuses!: null | LoanStatus[];
  protected readonly displayId!: null | string;

  // store state
  protected readonly clientConfig!: ClientConfig;
  protected readonly socketEvents!: SocketEvents;

  protected readonly statusItems = Object.entries(loanStatuses).map(([key, value]) => ({
    status: key,
    label: value.label,
  }));
  protected readonly pricePrecision = PRICE_PRECISION;
  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;

  protected selectedItems: BrokerOpenLoan[] = [];

  protected detail: { loan: BrokerOpenLoan; isCorpAction: boolean } | null = null;

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection: () => void = stubFn;

  protected get localPage(): number {
    return this.page;
  }
  protected set localPage(value: number) {
    this.$emit('update:page', value);
  }

  protected get localPageSize(): number {
    return this.pageSize;
  }
  protected set localPageSize(value: number) {
    this.$emit('update:page-size', value);
  }

  protected get localSort(): SortModelItem {
    return this.sort;
  }

  protected set localSort(value: string) {
    this.$emit('update:sort', value);
  }

  @Watch('equity')
  @Watch('lender')
  @Watch('borrower')
  @Watch('statuses')
  @Watch('displayId')
  protected onChangeFilters(): void {
    // purge the cache to avoid slow UI animations
    this.tableRefresh({ purge: true });
  }

  @Watch('socketEvents.openLoans.brokerLoan')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    try {
      const params: BrokerOpenLoansRequest = {
        filters: {
          lender: this.lender ? this.lender.companyId : null,
          borrower: this.borrower ? this.borrower.companyId : null,
          cusip: this.equity ? this.equity.cusip : null,
          statuses: this.statuses ? this.statuses.join(',') : null,
          displayId: this.displayId ? this.displayId.toUpperCase() : null,
        },
        pagination: {
          page: config.page,
          limit: config.pageSize,
        },
        sort: `${config.sort.sort === 'desc' ? '-' : ''}${config.sort.colId}`,
      };
      const res = await this.$api.openLoans.fetchBrokerOpenLoans(params, config.signal);
      if (!res) return;

      return { rowData: res.items, rowCount: res.total };
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected handleViewLoan(detailLoan: BrokerOpenLoan | null): void {
    if (!detailLoan) return;

    this.detail = { loan: detailLoan, isCorpAction: false };
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}
</style>
