<!-- this is a demo feature not intended to go to PROD  -->
<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (batch actions, filters, tabs) -->
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="gap-1 d-flex align-center" lg="6" md="12" sm="12" xl="6">
          <!-- Displays number of selected items -->
          <template v-if="selectedItems.length">
            <span>
              {{ selectedItems.length ? `${selectedItems.length} of ${totalItems} selected` : '' }}
            </span>
          </template>
        </v-col>

        <v-col class="gap-1 d-flex align-center" lg="6" md="12" sm="12" xl="6">
          <!-- Filters -->
          <counterparty-search
            v-model="selectedCounterparty"
            class="counterparties"
            clearable
            data-test="counterparty-search"
            include-sponsored
            placeholder="All counterparties"
            :side="counterpartySide"
          />
          <simple-equity-search
            v-model="selectedEquity"
            class="simple-equity-search"
            clear-after-select
            clearable
            label="Security"
          />
          <v-select
            v-if="canBorrowAndLend"
            v-model="selectedSide"
            class="side"
            clearable
            :items="sideItems"
            label="Side"
          />
          <v-switch v-model="showAllLoans" class="mt-5x" hide-details label="show all" />
        </v-col>
      </v-row>
    </v-container>
    <ag-table-server
      :column-defs="columnDefs"
      data-test="reported-transactions-table"
      :get-row-id="getRowId"
      :page="page"
      :page-size="pageSize"
      :query-data="queryData"
      :selected-items.sync="selectedItems"
      :sort="sort"
      table-id="ReportedTransactionsList"
      @ready="onReady"
    />
  </v-card>
</template>

<script lang="ts">
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import OpenLoansBatchActions from '@/modules/open-loans/components/OpenLoansBatchActions.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import { FinraTransaction, FinraTransactionsParams } from '@/utils/api/finra-transactions';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { ColDef, LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { AgTableServer } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/finra-transactions';

const MAX_ITEMS_PER_PAGE = 1000;

function stubFn() {
  return;
}

@Component({
  components: {
    CounterpartySearch,
    LoanDetailsDialog,
    OpenLoansBatchActions,
    SimpleEquitySearch,
    AgTableServer,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'socketEvents']),
    ...mapGetters(['canBorrow', 'canBorrowAndLend']),
  },
})
export default class ReportedTransactionList extends Vue {
  // store state
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected socketEvents!: SocketEvents;
  protected canBorrow!: boolean;
  protected canBorrowAndLend!: boolean;

  protected totalItems = 0;
  protected selectedItems: FinraTransaction[] = [];

  // filtering
  protected selectedCounterparty: null | CompanyInfo = null;
  protected selectedEquity: Equity | null = null;

  protected selectedSide: 'LENDER' | 'BORROWER' | null = null;
  protected sideItems = [
    { text: 'Lender', value: 'LENDER' },
    { text: 'Borrower', value: 'BORROWER' },
  ];
  protected showAllLoans = false;

  // table
  protected pageSize = MAX_ITEMS_PER_PAGE;
  protected page = 1;
  protected sort: SortModelItem = { colId: 'transactAt', sort: 'desc' };

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection: () => void = stubFn;

  protected get counterpartySide(): Side {
    return this.canBorrowAndLend ? 'ALL' : this.canBorrow ? 'LENDER' : 'BORROWER';
  }

  protected get columnDefs(): ColDef[] {
    return [
      cols.transactAt(),
      cols.reportedAt(),
      cols.reportType(),
      cols.displayId(),
      cols.checkbox(),
      cols.ticker(),
      cols.cusip(),
      cols.issuer(),
      cols.lei(),
      cols.quantity(),
      cols.rate(),
      cols.marketValue(),
      cols.collateralType(),
      cols.collateralValue(),
      cols.collateralPct(),
      cols.venue(),
      cols.dueDate(),
      cols.counterparty(),
      cols.counterpartyType(),
      cols.ftd(),
      cols.actions(),
    ];
  }

  @Watch('showAllLoans')
  @Watch('selectedSide')
  @Watch('selectedCounterparty')
  @Watch('selectedEquity')
  protected onChangeFilters(): void {
    // purge the cache to avoid slow UI animations
    this.tableRefresh({ purge: true });
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    try {
      const params: FinraTransactionsParams = {
        filters: {
          showAll: this.showAllLoans,
          side: this.selectedSide,
          counterpartyCompanyId: this.selectedCounterparty
            ? this.selectedCounterparty.companyId
            : null,
          cusip: this.selectedEquity ? this.selectedEquity.cusip : null,
        },
        pagination: {
          page: config.page,
          limit: config.pageSize,
        },
        sort: `${config.sort.sort === 'desc' ? '-' : ''}${config.sort.colId}`,
      };

      const res = await this.$api.finraTransactions.fetchFinraTransactions(params, config.signal);
      if (!res) return;

      this.totalItems = res.total;

      return { rowData: res.items, rowCount: res.items.length };
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected getRowId(finraTransaction: FinraTransaction): string {
    return finraTransaction.id;
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.counterparties {
  width: 8rem;
}

.simple-equity-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>
