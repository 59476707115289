<template>
  <!-- template must have 1 direct child, we wrap the contents in a <div>
    with "display: contents", making sure layout rendering is not affected -->
  <div style="display: contents">
    <v-tooltip color="secondary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small>{{ orderSoftLimitIcon(item) }}</v-icon>
        </span>
      </template>
      <span>{{ orderSoftLimitTooltip(item) }}</span>
    </v-tooltip>
    <span>&nbsp;</span>
    <v-tooltip color="secondary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small>{{ orderHardLimitIcon(item) }} </v-icon>
        </span>
      </template>
      <span>{{ orderHardLimitTooltip(item) }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import i18n from '@/localisation/i18n';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import Decimal from 'decimal.js';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';

@Component({
  props: {
    item: {
      type: Object as PropType<UserAccount>,
      required: true,
    },
  },
})
export default class TraderUsersRiskLimitIcons extends Vue {
  // Props
  protected readonly item!: UserAccount;

  protected orderSoftLimitIcon(user: UserAccount): string {
    return user.orderSoftLimit
      ? 'mdi-account'
      : user.defaultOrderSoftLimit
        ? 'mdi-domain'
        : 'mdi-set-none';
  }

  protected orderSoftLimitTooltip(user: UserAccount): string {
    const riskLimit = user.orderSoftLimit
      ? user.orderSoftLimit
      : user.defaultOrderSoftLimit
        ? user.defaultOrderSoftLimit
        : new Decimal(0);
    const riskChoice = user.orderSoftLimit ? 1 : user.defaultOrderSoftLimit ? 2 : 0;

    return i18n.tc('userAccounts.riskLimits.softLimit', riskChoice, {
      name: user.name,
      limit: getPriceAsString(riskLimit, 0),
    });
  }

  protected orderHardLimitIcon(user: UserAccount): string {
    return user.orderHardLimit
      ? 'mdi-account'
      : user.defaultOrderHardLimit
        ? 'mdi-domain'
        : 'mdi-set-none';
  }

  protected orderHardLimitTooltip(user: UserAccount): string {
    const riskLimit = user.orderHardLimit
      ? user.orderHardLimit
      : user.defaultOrderHardLimit
        ? user.defaultOrderHardLimit
        : new Decimal(0);
    const riskChoice = user.orderHardLimit ? 1 : user.defaultOrderHardLimit ? 2 : 0;

    return i18n.tc('userAccounts.riskLimits.hardLimit', riskChoice, {
      name: user.name,
      limit: getPriceAsString(riskLimit, 0),
    });
  }
}
</script>
