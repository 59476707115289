import Decimal from 'decimal.js';
import { BorrowLiquidity } from '@/utils/api/borrower';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Equity } from '@/modules/common/types/api';

export interface NotionalDetails {
  contractAmount: Decimal;
  independentAmount: Decimal;
  settlementAmount: Decimal;
}

export interface ContractDetails extends NotionalDetails {
  yearlyInterest: Decimal;
  dailyInterest: Decimal;
}

export enum RoundingRule {
  NoRounding = 'NO_ROUNDING',
  UpToNearest0Dot01 = 'UP_TO_NEAREST_0_DOT_01',
  UpToNearest0Dot05 = 'UP_TO_NEAREST_0_DOT_05',
  UpToNearest0Dot10 = 'UP_TO_NEAREST_0_DOT_10',
  UpToNearest0Dot25 = 'UP_TO_NEAREST_0_DOT_25',
  UpToNearest1Dot0 = 'UP_TO_NEAREST_1_DOT_0',
}

export function roundingRuleOptions(): Array<{ value: RoundingRule; text: string }> {
  return Object.values(RoundingRule).map((rr: RoundingRule) => {
    return { value: rr, text: roundingRuleToString(rr) };
  });
}

const zero = new Decimal(0);

const dtccInterestDaysInYear = 360;

export function zeroContractDetails(): ContractDetails {
  return {
    contractAmount: zero,
    independentAmount: zero,
    settlementAmount: zero,
    yearlyInterest: zero,
    dailyInterest: zero,
  };
}

export function zeroNotionalDetails(): NotionalDetails {
  return {
    contractAmount: zero,
    independentAmount: zero,
    settlementAmount: zero,
  };
}

/**
 * Calculate the contract details for the specified params based on DTCC formula.
 */
export function calculateContractDetailsForLiquidity(
  quantity: number | null,
  equity: Equity | null,
  liquidity: BorrowLiquidity | null
): ContractDetails {
  if (!equity || !liquidity || !quantity) {
    return zeroContractDetails();
  }

  return calculateContractDetails(
    quantity,
    liquidity.rate,
    equity.lastClosePrice,
    liquidity.independentAmountRate,
    liquidity.roundingRule
  );
}

/**
 * Calculate the contract details for the specified params based on DTCC formula.
 */
export function calculateContractDetails(
  quantity: number | null,
  rate: Decimal,
  lastClosePrice: Decimal | null,
  iaRate: Decimal,
  roundingRule: RoundingRule | null
): ContractDetails {
  roundingRule = roundingRule || RoundingRule.NoRounding;

  if (!quantity || !lastClosePrice || lastClosePrice.isZero()) {
    return zeroContractDetails();
  }

  const iaRateMultiplier = iaRate.div(100).add(1);

  const settlementPrice = applyRoundingRule(lastClosePrice.mul(iaRateMultiplier), roundingRule);
  const settlementAmount = settlementPrice.mul(quantity);
  const contractAmount = lastClosePrice.mul(quantity);
  const independentAmount = settlementAmount.sub(contractAmount);
  const yearlyInterest = settlementAmount.mul(rate.div(100));
  const dailyInterest = yearlyInterest.div(dtccInterestDaysInYear);

  return {
    contractAmount: contractAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    independentAmount: independentAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    settlementAmount: settlementAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    yearlyInterest: yearlyInterest.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    dailyInterest: dailyInterest.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
  };
}

/**
 * Calculate the contract details for the specified params based on DTCC formula.
 */
export function calculateNotional(
  quantity: number | null,
  lastClosePrice: Decimal | null,
  iaRate: Decimal,
  roundingRule: RoundingRule | null
): NotionalDetails {
  roundingRule = roundingRule || RoundingRule.NoRounding;

  if (!quantity || !lastClosePrice || lastClosePrice.isZero()) {
    return zeroNotionalDetails();
  }

  const iaRateMultiplier = iaRate.div(100).add(1);

  const settlementPrice = applyRoundingRule(lastClosePrice.mul(iaRateMultiplier), roundingRule);
  const settlementAmount = settlementPrice.mul(quantity);
  const contractAmount = lastClosePrice.mul(quantity);
  const independentAmount = settlementAmount.sub(contractAmount);

  return {
    contractAmount: contractAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    independentAmount: independentAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
    settlementAmount: settlementAmount.toDecimalPlaces(PRICE_PRECISION, Decimal.ROUND_CEIL),
  };
}

function roundingRuleNearest(roundingRule: RoundingRule): Decimal {
  switch (roundingRule) {
    case RoundingRule.UpToNearest0Dot01:
      return new Decimal(0.01);
    case RoundingRule.UpToNearest0Dot05:
      return new Decimal(0.05);
    case RoundingRule.UpToNearest0Dot10:
      return new Decimal(0.1);
    case RoundingRule.UpToNearest0Dot25:
      return new Decimal(0.25);
    case RoundingRule.UpToNearest1Dot0:
      return new Decimal(1.0);
    default:
      return new Decimal(0.01);
  }
}

export function applyRoundingRule(v: Decimal, roundingRule: RoundingRule): Decimal {
  return v.toNearest(roundingRuleNearest(roundingRule), Decimal.ROUND_CEIL);
}

export function roundingRuleToString(rr: RoundingRule): string {
  switch (rr) {
    case RoundingRule.NoRounding:
      return 'no rounding';
    case RoundingRule.UpToNearest0Dot01:
      return 'up to nearest $0.01';
    case RoundingRule.UpToNearest0Dot05:
      return 'up to nearest $0.05';
    case RoundingRule.UpToNearest0Dot10:
      return 'up to nearest $0.10';
    case RoundingRule.UpToNearest0Dot25:
      return 'up to nearest $0.25';
    case RoundingRule.UpToNearest1Dot0:
      return 'up to nearest $1.00';
    default:
      return 'no rounding';
  }
}

export function roundingRuleToShortString(rr: RoundingRule, withDollarSign?: boolean): string {
  if (withDollarSign === undefined) {
    withDollarSign = true;
  }

  switch (rr) {
    case RoundingRule.NoRounding:
      return 'no';
    case RoundingRule.UpToNearest0Dot01:
      return (withDollarSign ? '$' : '') + '0.01';
    case RoundingRule.UpToNearest0Dot05:
      return (withDollarSign ? '$' : '') + '0.05';
    case RoundingRule.UpToNearest0Dot10:
      return (withDollarSign ? '$' : '') + '0.10';
    case RoundingRule.UpToNearest0Dot25:
      return (withDollarSign ? '$' : '') + '0.25';
    case RoundingRule.UpToNearest1Dot0:
      return (withDollarSign ? '$' : '') + '1.00';
    default:
      return 'no';
  }
}
