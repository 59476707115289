/* eslint-disable vue/one-component-per-file */

import { ColDef } from 'ag-grid-enterprise';
import { Decimal } from 'decimal.js';
import { VChip, VSelect } from 'vuetify/lib';
import { Benchmark } from '@/utils/api/loans';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansTableActions from '@/modules/open-loans/components/OpenLoansTableActions.vue';
import OpenLoansTableTicker from '@/modules/open-loans/components/OpenLoansTableTicker.vue';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import { alignCenter, alignLeft, alignRight, comparator, component } from './utils';
import { h } from 'vue';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { isTerminalLoanStatus } from '@/utils/api/loans';
import { formatSideAdapter, prettyPrice, rateAdapter } from './common';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { Agg } from '@/modules/open-loans/helpers/getAggLoans';
import { BrokerOpenLoan } from '@/utils/api/broker';
export { checkbox, settlementType } from './common';

const statusChipAdapter = component<{
  loan: OpenLoanItem;
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
}>(
  (props) => () =>
    h(LoanStatusChip, {
      props: { loan: props.loan },
      on: { click: props.viewLoan },
    })
);

export function status({
  viewLoan,
}: {
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
}): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'status',
    colId: 'status',
    headerName: 'Status',
    cellRendererSelector: (params) =>
      params.node.rowPinned
        ? undefined
        : statusChipAdapter({ loan: params.data as OpenLoanItem, viewLoan }),
    resizable: false,
    pinned: 'left',
    width: 120,
    ...alignCenter(),
  };
}

export const updatedAt = (): ColDef<OpenLoanItem | Agg> =>
  cols.timestamp({
    field: 'updatedAt',
    headerName: 'Update Time',
  });

export function side(): ColDef<OpenLoanItem | Agg> {
  return {
    ...cols.side(),
    valueFormatter: (params) =>
      params.node?.rowPinned && params.data && 'nounOrQuantity' in params.data
        ? (params.data as Agg).nounOrQuantity('sides')
        : '',
    cellRendererSelector: (params) => {
      if (
        params.node?.rowPinned &&
        params.data &&
        'noun' in params.data &&
        params.data.noun?.sides.length === 1
      ) {
        return formatSideAdapter({
          side: params.data.noun.sides[0],
        });
      }
      if (!params.node?.rowPinned && params.data && 'side' in params.data) {
        return formatSideAdapter({
          side: params.data.side,
        });
      }
    },
  } as ColDef<OpenLoanItem | Agg>;
}

// @TODO: we should stop using "counterpartyDisplay" and use "counterparty" instead
// because it's tightly coupled with the API response
export function counterparty(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'counterpartyDisplay',
    colId: 'counterpartyDisplay',
    headerName: 'Counterparty',
    valueFormatter: (params) =>
      params.node?.rowPinned ? (params.data as Agg).nounOrQuantity('parties') : params.value,
    ...alignLeft(),
  };
}

// @TODO: we should stop using "lenderDisplay" and use "lender" (not in API yet) instead
// because it's tightly coupled with the API response
export function lenderDisplay(): ColDef<BrokerOpenLoan | Agg> {
  return {
    field: 'lenderDisplay',
    colId: 'lenderDisplay',
    headerName: 'Lender',
    valueFormatter: (params) =>
      params.node?.rowPinned ? (params.data as Agg).nounOrQuantity('lenders') : params.value,
    ...alignLeft(),
  };
}

// @TODO: we should stop using "borrowerDisplay" and use "borrower" (not in API yet) instead
// because it's tightly coupled with the API response
export function borrowerDisplay(): ColDef<BrokerOpenLoan | Agg> {
  return {
    field: 'borrowerDisplay',
    colId: 'borrowerDisplay',
    headerName: 'Borrower',
    valueFormatter: (params) =>
      params.node?.rowPinned ? (params.data as Agg).nounOrQuantity('borrowers') : params.value,
    ...alignLeft(),
  };
}

export function settlementTypeOpenLoans(): ColDef<OpenLoanItem | Agg> {
  return {
    ...cols.settlementType(),
    valueFormatter: (params) =>
      params.node?.rowPinned
        ? (params.data as Agg).nounOrQuantity('settlementType')
        : settlementTypeDisplayText[params.value],
  } as ColDef<OpenLoanItem | Agg>;
}

const openLoansTickerAdapter = component<{
  loan: OpenLoanItem;
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
}>(
  (props) => () =>
    h(OpenLoansTableTicker, { props: { data: props.loan }, on: { 'view-loan': props.viewLoan } })
);

export function ticker({
  viewLoan,
}: {
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
}): ColDef<OpenLoanItem | Agg> {
  return {
    ...cols.ticker(),
    valueFormatter: (params) =>
      params.node?.rowPinned ? (params.data as Agg).nounOrQuantity('ticker') : '',
    cellRendererSelector: (params) =>
      params.node.rowPinned
        ? undefined
        : openLoansTickerAdapter({ loan: params.data as OpenLoanItem, viewLoan }),
  } as ColDef<OpenLoanItem | Agg>;
}

export function cusip(): ColDef<OpenLoanItem | Agg> {
  return {
    ...cols.cusip(),
    valueFormatter: (params) =>
      params.node?.rowPinned ? (params.data as Agg).nounOrQuantity('cusip') : params.value,
  } as ColDef<OpenLoanItem | Agg>;
}

export const startDate = (): ColDef<OpenLoanItem | Agg> =>
  cols.date({
    field: 'createdAt',
    headerName: 'Start Date',
  });

export function openQuantity(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'openQuantity',
    colId: 'openQuantity',
    headerName: 'Open',
    valueFormatter: (params) =>
      formatPrettyNumber(
        params.node?.rowPinned && params.data
          ? params.data[(params.data as Agg).selected].openQuantity
          : params.value
      ),
    comparator: (a, b) => comparator.decimal(a?.openQuantity, b?.openQuantity),
    tooltipValueGetter: (params) => {
      if (!params.node?.rowPinned) {
        return `Original: ${formatPrettyNumber((params.data as OpenLoanItem).openQuantity + (params.data as OpenLoanItem).returnedQuantity)}`;
      }
    },
    ...alignRight(),
  };
}

export function returnedQuantityToday(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'returnedQuantityToday',
    colId: 'returnedQuantityToday',
    headerName: "Today's Returned",
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return formatPrettyNumber(params.data[(params.data as Agg).selected].returnedQuantityToday);
      }
      return formatPrettyNumber(params.value);
    },
    ...alignRight(),
  };
}

export function rate(): ColDef<OpenLoanItem | Agg> {
  return {
    ...cols.rate(),
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? params.data && (params.data as Agg).avg.rateModifiers.length === 1
          ? rateAdapter({
              rate: (params.data as Agg).avg.rate,
              rateModifier: (params.data as Agg).avg.rateModifiers[0],
              precision: undefined,
            })
          : undefined
        : rateAdapter({
            rate: (params.data as OpenLoanItem)?.rate,
            rateModifier: (params.data as OpenLoanItem)?.rateModifier,
            precision: undefined,
          }),
  } as ColDef<OpenLoanItem | Agg>;
}

export function contractAmount(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'contractAmount',
    colId: 'contractAmount',
    headerName: 'Contract Amount',
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[(params.data as Agg).selected].contractAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function independentAmountRate(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'independentAmountRate',
    colId: 'independentAmountRate',
    headerName: 'IA Rate',
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? undefined
        : rateAdapter({ rate: params.value, rateModifier: undefined, precision: 2 }),
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export function settlementAmount(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'settlementAmount',
    colId: 'settlementAmount',
    headerName: 'Settlement Amount',
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[(params.data as Agg).selected].settlementAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function independentAmount(): ColDef<BrokerOpenLoan | Agg> {
  return {
    field: 'independentAmount',
    colId: 'independentAmount',
    headerName: 'IA',
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[(params.data as Agg).selected].independentAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function yesterdayIndependentAmount(): ColDef<BrokerOpenLoan | Agg> {
  return {
    field: 'yesterdayIndependentAmount',
    colId: 'yesterdayIndependentAmount',
    headerName: "Yesterday's IA",
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[(params.data as Agg).selected].yesterdayIndependentAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

const rerateAdapter = component<{
  loan: OpenLoanItem;
}>((props) => () => {
  const { loan } = props;
  if (!loan.renegotiation || isTerminalLoanStatus(loan.status)) return;
  return h('div', [
    h(
      VChip,
      { class: 'justify-center text-uppercase mr-2', props: { xSmall: true } },
      loan.renegotiation.side === loan.side ? 'you' : 'contra'
    ),
    h(RateOutput, {
      props: { rate: loan.renegotiation.rate, rateModifier: loan.renegotiation.rateModifier },
    }),
  ]);
});

const rerateAvgRenderer = component<{
  renegotiateRate: Decimal | null;
  renegotiateRateModifiers: Benchmark[];
}>((props) => () => {
  const { renegotiateRate, renegotiateRateModifiers } = props;
  if (renegotiateRate === null) return;
  return h('div', [
    renegotiateRateModifiers.length === 1
      ? h(RateOutput, {
          props: { rate: renegotiateRate, rateModifier: renegotiateRateModifiers[0] },
        })
      : h('span', renegotiateRateModifiers.length),
  ]);
});

export function suggestedRate(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'renegotiation',
    colId: 'renegotiation',
    headerName: 'Suggested rate',
    cellRendererSelector: (params) => {
      if (params.node?.rowPinned && params.data) {
        return rerateAvgRenderer({
          renegotiateRate: (params.data as Agg).avg.renegotiateRate ?? null,
          renegotiateRateModifiers: (params.data as Agg).avg.renegotiateRateModifiers,
        });
      }
      if (!params.node?.rowPinned) {
        return rerateAdapter({
          loan: params.data as OpenLoanItem,
        });
      }
    },
    ...alignRight(),
  };
}

export function recalledQuantity(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'recalledQuantity',
    colId: 'recalledQuantity',
    headerName: 'Recalled',
    valueFormatter: (params) =>
      params.node?.rowPinned
        ? params.data
          ? formatPrettyNumber(params.data[(params.data as Agg).selected].recalledQuantity)
          : ''
        : formatPrettyNumber(params.value),
    ...alignRight(),
  };
}

export const dueDate = (): ColDef<OpenLoanItem | Agg> =>
  cols.date({
    field: 'nextAllowedBuyInExecutionDate',
    headerName: 'Due Date',
  });

export function sponsorship(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'sponsorshipSide',
    colId: 'sponsorshipSide',
    headerName: 'Sponsorship',
    ...alignLeft(),
  };
}

export function termContract(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'termContractDisplayId',
    colId: 'termContractDisplayId',
    headerName: 'Term Contract',
    ...alignLeft(),
  };
}

export const aggSelector = component<{
  sumOrAvg: 'sum' | 'avg';
  showDropdownActions: boolean | undefined;
  changeSumOrAvg: (sumOrAvg: 'sum' | 'avg') => void;
}>(
  (props) => () =>
    h(VSelect, {
      class: 'text-body-2',
      props: {
        dense: true,
        hideDetails: true,
        items: [
          { text: 'Summary', value: 'sum' },
          { text: 'Average', value: 'avg' },
        ],
        value: props.sumOrAvg,
      },
      on: { change: props.changeSumOrAvg },
    })
);

export function displayId(): ColDef<OpenLoanItem | Agg> {
  return {
    field: 'displayId',
    colId: 'displayId',
    headerName: 'Loan ID',
    ...alignLeft(),
  };
}

export function dtccRefId(): ColDef<BrokerOpenLoan | Agg> {
  return {
    field: 'dtccRefId',
    colId: 'dtccRefId',
    headerName: 'DTCC SFT ID',
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  item: OpenLoanItem;
  showDropdownActions: boolean;
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
}>(
  (props) => () =>
    h(OpenLoansTableActions, {
      props: { item: props.item, showDropdownActions: props.showDropdownActions },
      on: { 'view-loan': props.viewLoan },
    })
);

export function actions({
  showDropdownActions,
  viewLoan,
  changeSumOrAvg,
}: {
  showDropdownActions: boolean;
  viewLoan: (loan: OpenLoanItem, activeTab?: 'history' | 'recalls' | 'corporateActions') => void;
  changeSumOrAvg: (sumOrAvg: 'sum' | 'avg') => void;
}): ColDef<OpenLoanItem | Agg> {
  return {
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? aggSelector({
            sumOrAvg: (params.data as Agg).selected,
            showDropdownActions,
            changeSumOrAvg,
          })
        : actionsAdapter({
            item: params.data as OpenLoanItem,
            showDropdownActions,
            viewLoan,
          }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
