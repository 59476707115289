<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    :data-test-id="'my-dialog'"
    max-width="1000"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card v-shortkey="['enter']" class="form-summary" @shortkey="submitForm()">
        <v-card-title class="mb-0">
          <div class="d-flex align-center">
            <span class="headline"> Modify {{ itemsToBeModified.length }} orders </span>
          </div>
          <v-spacer />
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-alert v-if="failedOrderRefs.length" dense type="error">
            {{ itemsToBeModified.length - failedOrderRefs.length }}
            {{ itemsToBeModified.length - failedOrderRefs.length > 1 ? 'actions' : 'action' }}
            succeeded / {{ failedOrderRefs.length }} failed (marked in red below)
          </v-alert>
          <v-card-text v-else>
            <div class="p-4">
              <v-row dense>
                <v-col v-if="!areAllOrdersMarket" cols="2">
                  <numeric-input
                    v-model="newRate"
                    :error-messages="errorMsgs['rate']"
                    label="Rate"
                    :precision="ratePrecision"
                    :step="0.25"
                    suffix="%"
                    type="decimal"
                    @input="validationError = null"
                  />
                </v-col>
                <v-col cols="5">
                  <multiple-chip-counterparty-selector
                    clearable
                    :counterparties="newCounterparties"
                    label="Counterparties"
                    :prepend-item="resetCounterparty"
                    @add="addCounterparty"
                    @remove="removeCounterparty"
                  />
                </v-col>
                <v-col cols="2">
                  <numeric-input
                    v-model="newMinQty"
                    :error-messages="errorMsgs.newMinQty"
                    label="Min. Quantity"
                    :max="smallestRemainingQuantityInBatchSelection"
                    :min="1"
                    :step="100"
                    type="integer"
                    @input="validationError = null"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="newRoutingStatus"
                    clearable
                    item-text="label"
                    item-value="value"
                    :items="routedOptions"
                    label="Activate/Deactivate"
                    @input="validationError = null"
                  />
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-container>
            <v-divider />
            <div class="table-container">
              <ag-table-client
                :column-defs="columnDefs"
                :get-row-id="(item) => item.orderRef"
                no-menu
                no-move
                no-sort
                :page-size="100"
                :row-class-rules="{
                  'error--text': (params) => failedOrderRefs.includes(params.data.orderRef),
                }"
                :row-data="itemsToBeModified"
                :table-id="'batch-modify-all-dialog-table'"
              />
            </div>
          </v-container>

          <v-row
            v-if="showSuccess || showError || validationError || showUnchangedFieldsDisclaimer"
          >
            <v-col class="pa-0 px-1 pt-4 col-8 offset-2">
              <div
                class="v-alert v-alert--dense text--primary text-body-2 text-center"
                :class="{
                  success: showSuccess,
                  error: showError || validationError || showUnchangedFieldsDisclaimer,
                }"
              >
                <div v-if="showSuccess">Modify all successfully initiated.</div>
                <div v-if="showError">{{ errorMsgs.apiErrors.join('\n') }}</div>
                <div v-if="validationError">{{ validationError }}</div>
                <div v-if="showUnchangedFieldsDisclaimer && formStatus === 'idle'">
                  Fields marked in red already have the target value and won't be affected.
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex px-8 py-4">
          <div v-if="failedOrderRefs.length" class="d-flex flex-grow-1 justify-end align-end">
            <v-btn color="primary" data-test="close-btn" @click="closeDialog()">Back</v-btn>
          </div>
          <div v-else class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">Back</v-btn>
            <v-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              Confirm
            </v-btn>
          </div>
        </v-card-actions>
        <v-progress-linear v-if="formStatus !== 'idle'" color="primary" indeterminate />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import wait from '@/modules/common/services/wait';
import { Api } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { RoutingStatus } from '@/modules/marketplace/types/marketplace';
import { formatCompanyBoxId } from '@/modules/user-accounts/helpers/user-accounts';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { integer, maxValue, minValue } from 'vuelidate/lib/validators';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace-batch-modify-all-dialog';
import { ColDef } from 'ag-grid-enterprise';
import MultipleChipCounterpartySelector from '@/modules/user-accounts/components/MultipleChipCounterpartySelector.vue';
import isEqual from 'lodash/isEqual';
import { OrdersWithEqualFieldsMap } from '@/modules/common/components/ag-table/columns/marketplace-batch-modify-all-dialog';
import { UUID_EMPTY } from '@/constants';

interface FormErrors {
  apiErrors: string[];
  newMinQty: string[];
}

@Component({
  props: {
    items: Array as PropType<Api.Marketplace.Order[]>,
    execute: Function,
  },
  components: {
    MultipleChipCounterpartySelector,
    AgTableClient,
  },
  mixins: [validationMixin],
  validations: function (this: MarketplaceBatchModifyAllDialog) {
    return {
      newMinQty: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(this.smallestRemainingQuantityInBatchSelection),
      },
    };
  },
})
export default class MarketplaceBatchModifyAllDialog extends mixins(validationMixin) {
  protected routedOptions: Array<{ label: string; value: RoutingStatus }> = [
    { label: 'Activate', value: 'ROUTED' },
    { label: 'Deactivate', value: 'UNROUTED' },
  ];

  protected newRate: Decimal | null = null;

  // We want to `lock in` the items list once we are in the modify flow in case something changes it in the parent
  // component, so we disconnect it from the incoming prop. See mounted().
  protected readonly items!: Api.Marketplace.Order[];
  protected itemsToBeModified: Api.Marketplace.Order[] = [];

  protected readonly successMessage!: string;
  protected ratePrecision = RATE_PRECISION;
  protected newMinQty: number | null = null;
  protected newRoutingStatus: RoutingStatus | null = null;
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected formStatus: DialogFormStatus = 'idle';
  protected validationError: string | null = null;
  protected failedOrderRefs: string[] = [];
  protected newCounterparties: CompanyInfo[] = [];
  protected resetCounterparty: CompanyInfo = {
    companyName: 'Clear/Remove Counterparty',
    companyId: UUID_EMPTY,
    displayBoxId: 'CLEAR',
  };
  protected formatCompanyBoxId = formatCompanyBoxId;

  protected get showUnchangedFieldsDisclaimer(): boolean {
    return this.ordersWithEqualFields.size > 0;
  }

  protected get ordersWithEqualFields(): OrdersWithEqualFieldsMap {
    return new Map(
      this.itemsToBeModified
        .map(
          (item) =>
            [
              item,
              {
                routingStatus:
                  this.newRoutingStatus !== null && item.routingStatus === this.newRoutingStatus,
                counterparties: this.areCounterpartiesEqual(item),
                minQuantity: this.isMinQuantityEqual(item),
                rate: this.newRate !== null && item.rate !== null && item.rate.equals(this.newRate),
              },
            ] as const
        )
        .filter(
          ([_, { routingStatus, counterparties, minQuantity, rate }]) =>
            routingStatus || counterparties || minQuantity || rate
        )
    );
  }

  // there is no need to submit items that have the same values as the target fields
  protected get itemsToSubmit(): Api.Marketplace.Order[] {
    return this.itemsToBeModified.filter((item) => {
      return (
        Object.values(this.ordersWithEqualFields.get(item) ?? {}).filter(
          (isFieldEqual) => isFieldEqual
        ).length < this.touchedCount
      );
    });
  }

  protected get touchedCount(): number {
    const fields = [
      this.newCounterparties.length > 0,
      this.newMinQty !== null,
      this.newRate,
      this.newRoutingStatus,
    ];
    return fields.filter((field) => !!field).length;
  }

  protected get smallestRemainingQuantityInBatchSelection(): number {
    const batchRemainingQty = this.itemsToBeModified.map((item) => item.quantity - item.filled);
    return Math.min(...batchRemainingQty);
  }

  protected get areAllOrdersMarket(): boolean {
    return this.itemsToBeModified.every((item) => item.orderType === 'MARKET');
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      newMinQty: [],
    };

    if (this.$v.newMinQty.$dirty) {
      if (!this.$v.newMinQty.minValue) {
        errors.newMinQty.push('must be greater than or equal to 0.');
      }
    }

    if (this.$v.newMinQty.$dirty) {
      if (!this.$v.newMinQty.maxValue) {
        errors.newMinQty.push(
          `must be smaller than or equal to the smallest remaining quantity in the batch (${this.smallestRemainingQuantityInBatchSelection}).`
        );
      }
    }
    return errors;
  }

  protected get columnDefs(): Array<ColDef<Api.Marketplace.Order>> {
    const config = this as unknown as {
      ordersWithEqualFields: OrdersWithEqualFieldsMap;
    };
    return [
      cols.status(config),
      cols.side(),
      cols.ticker(),
      cols.cusip(),
      cols.counterparty(config),
      cols.executed(),
      cols.quantity(),
      cols.minQuantity(config),
      cols.rate(config),
    ];
  }

  protected mounted(): void {
    this.itemsToBeModified = [...this.items];
  }

  protected addCounterparty(counterparty: CompanyInfo): void {
    this.validationError = null;

    if (counterparty.companyId === UUID_EMPTY) {
      // ignore previous added counterparties and only add resetCounterparty
      this.newCounterparties = [this.resetCounterparty];
    } else if (this.newCounterparties[0] === this.resetCounterparty) {
      // ignore resetCounterparty and only add the new counterparty
      this.newCounterparties = [counterparty];
    } else {
      this.newCounterparties.push(counterparty);
    }
  }

  protected removeCounterparty(counterparty: CompanyInfo): void {
    this.validationError = null;

    this.newCounterparties = this.newCounterparties.filter(
      (cp) => cp.companyId !== counterparty.companyId
    );
  }

  protected areCounterpartiesEqual(item: Api.Marketplace.Order): boolean {
    if (this.newCounterparties.length === 0) {
      return false;
    }
    if (this.newCounterparties[0] === this.resetCounterparty && item.counterparties.length === 0) {
      return true;
    }
    return isEqual(
      this.newCounterparties.map((cp) => cp.companyId).sort(),
      item.counterparties.map((cp) => cp.companyId).sort()
    );
  }

  protected isMinQuantityEqual(item: Api.Marketplace.Order): boolean {
    if (
      (this.newMinQty === 0 && item.minQuantity === 1) ||
      (this.newMinQty === 1 && item.minQuantity === 0)
    ) {
      return true;
    }
    return this.newMinQty === item.minQuantity;
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const payload: Api.Marketplace.BatchModifyPayload = {
        orderRefs: this.itemsToSubmit.map((item) => item.orderRef),
      };

      if (this.newCounterparties.length) {
        payload.counterparties = this.newCounterparties.map((cp) => cp.companyId);
      }
      if (this.newMinQty !== null) {
        payload.minQuantity = this.newMinQty;
      }
      if (this.newRate) {
        payload.rate = this.newRate;
      }
      if (this.newRoutingStatus) {
        payload.routingStatus = this.newRoutingStatus;
      }

      const res = await this.$api.marketplace.batchUpdate(payload);

      if (res.failedOrderRefs) {
        this.failedOrderRefs = res.failedOrderRefs;
        this.formStatus = 'idle';
      } else {
        this.showSuccess = true;
        this.formStatus = 'closing';
        await wait(1200);
        this.$emit('success');
        this.closeDialog();
      }
    } catch (err) {
      this.apiErrors = [`${err}`];
      this.formStatus = 'idle';
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.validationError = '';
    this.$v.$touch();

    if (this.touchedCount === 0) {
      this.validationError = 'Please fill in at least one of the fields';
      return false;
    }

    if (this.itemsToSubmit.length === 0) {
      this.validationError =
        'All items have the same values as the target fields. No changes to submit.';
      return false;
    }

    return !this.$v.$anyError;
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  display: grid;
  min-height: 30vh;
}

.highlight,
.summary strong {
  color: red;
  font-weight: bold;
}
</style>
