<template>
  <!-- template must have 1 direct child, we wrap the contents in a <div>
    with "display: contents", making sure layout rendering is not affected -->
  <div style="display: contents">
    <v-tooltip color="primary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn class="ma-1" color="primary" icon x-small @click="$emit('edit-user', item)">
            <v-icon> mdi-account-edit </v-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t('adminEditUser') }}</span>
    </v-tooltip>

    <v-tooltip v-if="item.disabledAt === null" color="primary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn class="ma-1" color="primary" icon x-small @click="$emit('disable-user', item)">
            <v-icon> mdi-account-off </v-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t('adminDisableUser') }}</span>
    </v-tooltip>
    <v-tooltip v-else color="primary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn class="ma-1" color="primary" icon x-small @click="$emit('enable-user', item)">
            <v-icon> mdi-account-check </v-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t('adminEnableUser') }}</span>
    </v-tooltip>

    <v-tooltip color="primary" top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-btn class="ma-1" color="primary" icon x-small @click="$emit('delete-user', item)">
            <v-icon> mdi-account-remove </v-icon>
          </v-btn>
        </span>
      </template>
      <span>{{ $t('adminDeleteUser') }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';

@Component({
  components: {},
  props: {
    item: {
      type: Object as PropType<UserAccount>,
      required: true,
    },
  },
  methods: {},
  computed: {},
})
export default class TraderUsersActions extends Vue {
  // Props
  protected readonly item!: UserAccount;
}
</script>
