// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanconsts.proto (package aurora.core.consts, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * possible states in the lifecycle of a loan
 *
 * @generated from enum aurora.core.consts.LoanStatus
 */
export enum LoanStatus {
  /**
   * @generated from enum value: NEW = 0;
   */
  NEW = 0,

  /**
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: REJECTED = 2;
   */
  REJECTED = 2,

  /**
   * @generated from enum value: DROPPED = 3;
   */
  DROPPED = 3,

  /**
   * @generated from enum value: CANCELED = 4;
   */
  CANCELED = 4,

  /**
   * @generated from enum value: OPEN = 5;
   */
  OPEN = 5,

  /**
   * @generated from enum value: CLOSED = 6;
   */
  CLOSED = 6,

  /**
   * @generated from enum value: TERMINATED = 7;
   */
  TERMINATED = 7,

  /**
   * @generated from enum value: UNDER_CORP_ACTION = 8;
   */
  UNDER_CORP_ACTION = 8,

  /**
   * @generated from enum value: CANCEL_ROLL_PENDING = -4;
   */
  CANCEL_ROLL_PENDING = -4,

  /**
   * @generated from enum value: CANCEL_RETURN_PENDING = -3;
   */
  CANCEL_RETURN_PENDING = -3,

  /**
   * @generated from enum value: CANCEL_NEW_LOAN_PENDING = -2;
   */
  CANCEL_NEW_LOAN_PENDING = -2,

  /**
   * @generated from enum value: ERROR = -1;
   */
  ERROR = -1,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanStatus)
proto3.util.setEnumType(LoanStatus, "aurora.core.consts.LoanStatus", [
  { no: 0, name: "NEW" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "REJECTED" },
  { no: 3, name: "DROPPED" },
  { no: 4, name: "CANCELED" },
  { no: 5, name: "OPEN" },
  { no: 6, name: "CLOSED" },
  { no: 7, name: "TERMINATED" },
  { no: 8, name: "UNDER_CORP_ACTION" },
  { no: -4, name: "CANCEL_ROLL_PENDING" },
  { no: -3, name: "CANCEL_RETURN_PENDING" },
  { no: -2, name: "CANCEL_NEW_LOAN_PENDING" },
  { no: -1, name: "ERROR" },
]);

/**
 * @generated from enum aurora.core.consts.RoundingRule
 */
export enum RoundingRule {
  /**
   * Unknown will be set when a value is specified that is not defined in the schema
   * and should be treated as an error.
   * NoRounding is the correct value for when there's no rounding desired.
   *
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * No rounding is applied (other than the usual rounding to $0.0001)
   *
   * @generated from enum value: NO_ROUNDING = 1;
   */
  NO_ROUNDING = 1,

  /**
   * rounding (always UP) to the nearest $0.01
   *
   * @generated from enum value: UP_TO_NEAREST_0_DOT_01 = 2;
   */
  UP_TO_NEAREST_0_DOT_01 = 2,

  /**
   * rounding (always UP) to the nearest $0.05
   *
   * @generated from enum value: UP_TO_NEAREST_0_DOT_05 = 3;
   */
  UP_TO_NEAREST_0_DOT_05 = 3,

  /**
   * rounding (always UP) to the nearest $0.10
   *
   * @generated from enum value: UP_TO_NEAREST_0_DOT_10 = 4;
   */
  UP_TO_NEAREST_0_DOT_10 = 4,

  /**
   * rounding (always UP) to the nearest $0.25
   *
   * @generated from enum value: UP_TO_NEAREST_0_DOT_25 = 5;
   */
  UP_TO_NEAREST_0_DOT_25 = 5,

  /**
   * rounding (always UP) to the nearest $1.00
   *
   * @generated from enum value: UP_TO_NEAREST_1_DOT_0 = 6;
   */
  UP_TO_NEAREST_1_DOT_0 = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(RoundingRule)
proto3.util.setEnumType(RoundingRule, "aurora.core.consts.RoundingRule", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "NO_ROUNDING" },
  { no: 2, name: "UP_TO_NEAREST_0_DOT_01" },
  { no: 3, name: "UP_TO_NEAREST_0_DOT_05" },
  { no: 4, name: "UP_TO_NEAREST_0_DOT_10" },
  { no: 5, name: "UP_TO_NEAREST_0_DOT_25" },
  { no: 6, name: "UP_TO_NEAREST_1_DOT_0" },
]);

