<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="800"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="step !== 'confirmation' && closeDialog()"
    @keydown.esc="handleEscKey"
    @shortkey.native="handleEscKey"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-btn class="close-icon" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-row
          v-if="fetchStatus === 'fetching'"
          class="justify-center pt-16 flex-column align-center"
        >
          <fulfilling-bouncing-circle-spinner
            :animation-duration="1500"
            color="#4caf50"
            :size="50"
          />
          <h3 class="mt-2">Fetching contract details...</h3>
        </v-row>

        <v-row
          v-else-if="fetchStatus === 'failed'"
          class="justify-center pt-16 flex-column align-center"
        >
          <v-alert dense type="error">
            {{ fetchError }}
          </v-alert>
        </v-row>

        <template v-else-if="fetchStatus === 'completed'">
          <v-card-title>
            <span class="headline">
              {{ displayId === null ? 'New' : 'Edit' }}
              <format-side :side="contract.side" /> Contract
            </span>
          </v-card-title>

          <term-loans-form
            v-if="step === 'form'"
            :contract="contract"
            @close-modal="closeDialog"
            @next="step = 'equities'"
          />

          <term-loans-define-equities
            v-if="step === 'equities'"
            :contract.sync="contract"
            @back="step = 'form'"
            @next="step = 'confirmation'"
          />

          <term-loans-edit-contract-confirmation
            v-if="step === 'confirmation'"
            :contract="contract"
            @back="step = 'equities'"
            @close-modal="closeDialog"
          />
        </template>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { setSeconds } from 'date-fns';
import Decimal from 'decimal.js';
import TermLoansForm from '@/modules/termloans/components/TermLoansForm.vue';
import TermLoansDefineEquities from '@/modules/termloans/components/TermLoansDefineEquities.vue';
import TermLoansEditContractConfirmation from '@/modules/termloans/components/TermLoansEditContractConfirmation.vue';
import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { Benchmark } from '@/utils/api/loans';

@Component({
  props: {
    newContractSide: String as PropType<'BORROWER' | 'LENDER'>,
    displayId: String as PropType<string | null>,
  },
  components: {
    TermLoansForm,
    TermLoansDefineEquities,
    TermLoansEditContractConfirmation,
  },
})
export default class TermLoansEditContractDialog extends Vue {
  // props
  protected readonly displayId!: string | null;
  protected newContractSide!: 'BORROWER' | 'LENDER';

  protected contract: NullableAll<Api.TermLoans.ContractSummary> = {
    displayId: null,
    status: null,
    side: this.newContractSide,
    counterparty: null,
    isCreator: true,
    startDate: setSeconds(new Date(), 0),
    endDate: null,
    notionalValue: null,
    currentValue: new Decimal(0),
    rate: null,
    independentAmountRate: null,
    roundingRule: null,
    rateModifier: Benchmark.NoBenchmark,
    threshold: null,
    maxEquityPercent: null,
    equities: [],
    createdAt: null,
    availableActions: {},
    hasPendingLoans: false,
  };

  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected fetchError = '';
  protected step: 'form' | 'equities' | 'confirmation' = 'form';
  protected apiErrors: string[] = [];

  protected get showError(): boolean {
    return this.apiErrors.length > 0;
  }

  protected created(): void {
    if (this.displayId === null) {
      this.contract.side = this.newContractSide;
    }
  }

  protected async mounted(): Promise<void> {
    if (this.displayId === null) {
      this.fetchStatus = 'completed';
      return;
    }
    try {
      this.contract = await this.$api.termLoans.fetchDetails(this.displayId);
      this.fetchStatus = 'completed';
    } catch (err) {
      this.fetchError = new ApiError(i18nServerMessage(err as Error)).message;
      this.fetchStatus = 'failed';
    }
  }

  protected handleEscKey(): void {
    if (this.step === 'confirmation') {
      this.step = 'form';
    } else {
      this.closeDialog();
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  min-height: 15rem;
}

.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
</style>
