<template>
  <ag-table-server
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :page="page"
    :page-size="pageSize"
    :query-data="queryData"
    :sort="sort"
    table-id="OpenLoansTableAggregate"
    v-on="$listeners"
    @ready="(e) => $emit('ready', e)"
  />
</template>

<script lang="ts">
import OpenLoansTableAggregateActions from '@/modules/open-loans/components/OpenLoansTableAggregateActions.vue';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { AggregatedLoanByCounterpartyItem, AggregatedLoanBySecurityItem } from '@/utils/api/loans';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { AgTableServer } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/open-loans-agg';
import { ColDef, LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';

@Component({
  components: {
    AgTableServer,
    OpenLoansTableAggregateActions,
  },
  props: {
    groupingType: {
      type: String as PropType<'counterparty' | 'security'>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    sort: {
      type: Object as PropType<SortModelItem>,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    queryData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasOpsUserRole']),
  },
})
export default class OpenLoansTableAggregate extends Vue {
  protected readonly groupingType!: 'counterparty' | 'security';
  protected readonly page!: number;
  protected sort!: SortModelItem;
  protected readonly pageSize!: number;
  protected readonly queryData!: (config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }) => Promise<LoadSuccessParams | undefined>;

  // store state
  protected readonly hasOpsUserRole!: boolean;

  protected securityColumnDefs = [
    cols.side(),
    cols.ticker(),
    cols.cusip(),
    cols.loansCount(),
    cols.counterpartyCount(),
    cols.totalOpenQty(),
    cols.avgRate(),
    cols.totalValue(),
    cols.totalRecallQty(),
    cols.actions({
      openDialog: this.openDialog,
    }),
  ];

  protected counterpartyColumnDefs = [
    cols.side(),
    cols.counterpartyDisplay(),
    cols.loansCount(),
    cols.securityCount(),
    cols.totalOpenQty(),
    cols.avgRate(),
    cols.totalValue(),
    cols.totalRecallQty(),
    cols.actions({
      openDialog: this.openDialog,
    }),
  ];

  public get columnDefs(): ColDef[] {
    return this.groupingType === 'counterparty'
      ? this.counterpartyColumnDefs
      : this.securityColumnDefs;
  }

  protected mounted(): void {
    if (this.groupingType !== 'security' && this.groupingType !== 'counterparty') {
      throw new Error('The property groupingType must be either "counterparty" or "security"');
    }
  }

  protected getRowId(
    aggregatedLoanByItem: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ): string {
    if ('counterparty' in aggregatedLoanByItem) {
      return aggregatedLoanByItem.counterparty.displayBoxId as string;
    } else {
      return aggregatedLoanByItem.cusip;
    }
  }

  protected openDialog(
    action: MultipleLoanAction,
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ): void {
    if ('ticker' in item) {
      this.$emit('open-dialog', {
        action,
        equity: { ticker: item.ticker, cusip: item.cusip },
      });
    } else {
      this.$emit('open-dialog', {
        action,
        counterparty: item.counterparty,
      });
    }
  }
}
</script>

<style lang="scss" scoped></style>
